import './bootstrap';

import { createApp } from "vue";
import router from "./router";
import store from "./store/store";
import authentication from "./plugins/authentication/authentication";
import validation from "./plugins/validation/validation";
import helper from "./plugins/helper/helper";
import icons from "./plugins/icons/icons";
import App from './components/App.vue';
import toast from "./plugins/toast/toast";
import authorization from "./plugins/authorization/authorization";
import {VMoney} from 'v-money';

const app = createApp(App)
    .use(helper)
    .use(authentication)
    .use(authorization)
    .use(router)
    .use(store)
    .use(validation)
    .use(icons)
    .use(toast);

app.mount('#app');

app.directive('money', VMoney);
