<template>
    <Multiselect
        v-model="project"
        trackBy="name"
        :searchable="true"
        :filter-results="false"
        :closeOnSelect="true"
        :object="true"
        :minChars="2"
        :delay="316"
        ref="projectSelect"
        placeholder="Selecteer een project"
        label="name"
        valueProp="id"
        @change="answer => $emit('project', answer)"
        :options="fetchProjects"
    >
        <template v-slot:option="{ option }">
            <div class="mb-0">
                <span class="client-border" :style="'border-color:'+option.customer.color"></span>
                <span class="font-weight-bold fw-bold">{{ option.customer.code }}</span> - {{ option.name }}
            </div>
        </template>
        <template v-slot:singlelabel="{ value }">
            <div class="multiselect-single-label">
                <div class="multiselect-single-label-text">
                    <span class="client-border" :style="'border-color:'+value.customer.color"></span> {{ value.name }}
                </div>
            </div>
        </template>
    </Multiselect>
</template>

<script lang="ts">
import Multiselect from '@vueform/multiselect';
import Project from "../../store/Models/Project";
import {defineComponent} from "vue";
import {isNil} from 'lodash';

export default defineComponent({
    components: {Multiselect},
    props: [
        'selected',
        'onlyForMe',
        'writable',
        'not_archived',
    ],
    emits: [
        'project'
    ],
    data() {
        return {
            project: null,
        }
    },
    watch: {
        selected() {
            this.project = this.selected;
        }
    },
    methods: {
        async fetchProjects(query: any) {
            let url = 'projects';

            if (isNil(query) || query === '') {
                return [];
            }

            if (query !== null) {
                url += '?search=' + query;
            }

            let params = {
                include: 'customer',
            };

            if (this.onlyForMe) {
                params['filter[with_deadlines_for_me]'] = true;
            }

            if (this.writable) {
                params['filter[writable]'] = true;
            }

            if (this.not_archived) {
                params['filter[not_archived]'] = true;
            }

            const response = await window.api.get(url, {
                params: params
            });

            await Project.insertOrUpdate({
                data: response.data.data
            });

            return response.data.data;
        },
    },
    created() {
        if ((!isNil(this.selected) && isNil(this.project))) {
            Project.api().get('projects/' + this.selected + '?include=customer,budgets.deadlines')
                .then((result) => {
                    result.response.data.data.formatSelected = result.response.data.data.name;
                    this.project = result.response.data.data;
                })
        }
    }
})
</script>

<style scoped>

</style>
