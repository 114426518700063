import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";

export default class Permission extends Model {
    static entity = 'permissions';

    static primaryKey = 'label';

    static fields() {
        return {
            label: this.string(null),
            description: this.string(null),
            group: this.string(null),
        }
    }

    label!: string;
    description!: string;
    group!: string;

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'name',
    }
}
