import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";
import moment from "moment";

export default class FreeDay extends Model {
    static entity = 'free_days';

    static fields() {
        return {
            id: this.attr(null),
            description: this.string(null).nullable(),
            date: this.string(null).nullable(),
            is_yearly: this.boolean(null),

            created_at: this.string(false),
            updated_at: this.string(false),
        }
    }

    id!: number;
    description!: string;
    date!: string;
    is_yearly!: boolean;
    created_at!: string;
    updated_at!: string;

    static mutators () {
        return {
            date (date: string) {
                return moment(date).format('Y-MM-DD')
            }
        }
    }

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'description',
    }
}
