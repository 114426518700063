<template>
    <div class="modal fade" id="mutateBalance" tabindex="-1" aria-labelledby="mutateBalanceLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="mutateBalanceLabel">Balans mutatie</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div v-if="user !== null" class="modal-body">
                    <p>Je bent de balans aan het muteren voor {{ user.name }} voor het jaar {{ year }}.</p>

                    <div class="mb-3">
                        <TimeInput v-model="form.minutes" @answer="answer => form.minutes = answer" v-validate:[form.errors]="'minutes'"></TimeInput>
                    </div>

                    <div class="mb-3">
                        <label for="description" class="form-label">Omschrijving</label>
                        <textarea class="form-control" v-model="form.description" v-validate:[form.errors]="'description'"></textarea>
                        <error field="description" :bag="form.errors"></error>
                    </div>
                </div>
                <div v-if="user !== null" class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="form.working">Annuleren</button>
                    <button type="button" class="btn btn-primary" @click="save" :disabled="form.working">Opslaan</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import User from "../../store/Models/User";
import {Item} from "@vuex-orm/core";
import TimeInput from "../TimeInput.vue";
import FurloughBalanceMutation from "../../store/Models/FurloughBalanceMutation";

export default defineComponent({
    components: {TimeInput},
    props: {
        user_id: Number,
        year: Number,
    },
    data() {
        return {
            form: {
                description: null,
                minutes: 0,

                errors: null,
                working: false,
            },
        }
    },
    emits: [
        'saved',
    ],
    computed: {
        user(): Item<User> {
            return User.find((this.user_id as number));
        },
    },
    methods: {
        save() {
            FurloughBalanceMutation.api().post('furlough-balance-mutations', {
                ...this.form,
                ...{
                    year: this.year,
                    user_id: this.user_id,
                }
            })
                .then(() => {
                    this.$toast('success', 'De mutatie is succesvol opgeslagen.');
                    this.$emit('saved');
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.form.errors = e.response.data.errors;
                    }
                })
                .finally(() => {
                    this.form.working = false;
                });
        }
    },
    created() {
        this.$bus.on('clearBalanceMutationForm', () => {
            this.form.description = null;
            this.form.minutes = 0;
        });
    }
});
</script>

<style scoped>

</style>
