<template>
    <div v-if="project !== null">
        <div class="row">
            <div class="col-md-7">
                <div class="card-table card mb-md-0">
                    <table class="table table-border-bottom-0 table-sm mb-0">
                        <tbody>
                        <tr>
                            <td>Titel</td>
                            <td data-bs-dismiss="modal">
                                <router-link :to="{name: 'projects.edit', params: {project: project.id}}" v-html="project?.name"></router-link> <StatusLabel :status="project.status"></StatusLabel>
                            </td>
                        </tr>
                        <tr>
                            <td>Klant</td>
                            <td data-bs-dismiss="modal">
                                <router-link :to="{name: 'customers.show', params: {customer: project.customer_id}}" v-html="project?.customer?.name"></router-link>
                                <template v-if="project.sub_customer_id">
                                     > <router-link :to="{name: 'customers.show', params: {customer: project.sub_customer_id}}" v-html="project?.sub_customer?.name"></router-link>
                                </template>
                            </td>
                        </tr>
                        <tr>
                            <td>Omschrijving</td>
                            <td>
                                <div v-if="project.description && project.description.length > 1">
                                    <span v-if="showMore.description" style="word-wrap: anywhere; white-space: pre-wrap;">{{ project.description }}</span>
                                    <span v-else style="word-wrap: anywhere; white-space: pre-wrap;">{{ project.description.substring(0, 170) }}</span>

                                    <br />
                                    <button v-if="project.description.length > 170" @click="showMore.description = !showMore.description" class="btn btn-sm btn-default text-nowrap">
                                        Toon {{ showMore.description ? 'minder' : 'meer' }}
                                    </button>
                                </div>
                                <span v-else class="text-muted">-</span>
                            </td>
                        </tr>
                        <tr v-if="$gate.allows('viewCustomerNotes', 'project')">
                            <td>Afspraken klant</td>
                            <td>
                                <div v-if="project.appointment_notes">
                                    <span v-if="showMore.appointment_notes" style="word-wrap: anywhere">{{ project.appointment_notes }}</span>
                                    <span v-else style="word-wrap: anywhere">{{ project.appointment_notes.substring(0, 170) }}</span>

                                    <br />
                                    <button v-if="project.appointment_notes.length > 170" @click="showMore.appointment_notes = !showMore.appointment_notes" class="btn btn-sm btn-default text-nowrap">
                                        Toon {{ showMore.appointment_notes ? 'minder' : 'meer' }}
                                    </button>
                                </div>
                                <span v-else class="text-muted">-</span>
                            </td>
                        </tr>
                        <tr>
                            <td>URL's</td>
                            <td>
                                <a v-if="project.document_url" :href="project.document_url">Documentatie</a>
                                /
                                <a v-if="project.ticket_url" :href="project.ticket_url">Tickets</a>
                                <br v-if="project.website_url || project.repo_url" />
                                <a v-if="project.website_url" :href="project.website_url">Website</a>
                                /
                                <a v-if="project.repo_url" :href="project.repo_url">Repo</a>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-nowrap">Managers</td>
                            <td>
                                <img v-if="project.project_manager" :src="project.project_manager?.gravatar" class="gravatar-nano me-1" :style="'border: 1px solid ' + project.project_manager?.color">
                                {{ project.project_manager?.name }}
                                &nbsp; & &nbsp;
                                <img v-if="project.account_manager" :src="project.account_manager?.gravatar" class="gravatar-nano me-1" :style="'border: 1px solid ' + project.account_manager?.color">
                                {{ project.account_manager?.name }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-5">
                <div class="card mb-0 h-100 card-table">
                    <table class="table table-border-bottom-0 table-sm table-align-middle mb-0 h-100">
                        <tbody>
                        <tr>
                            <td width="172">Budget</td>
                            <td>
                                {{ $timeFormatHoursMinutes(project.budget_minutes) }}
                            </td>
                            <td rowspan="6" style="border-left: 1px solid #ddd;" class="budget-chart border-bottom-0">
                                <PieChart v-if="chartsRendered" :data="mainChartData"></PieChart>
                            </td>
                        </tr>
                        <tr>
                            <td>Gewerkt</td>
                            <td :class="{'text-success': project.worked_minutes < project.budget_minutes, 'text-warning fw-bold': project.worked_minutes === project.budget_minutes, 'text-danger fw-bold': project.worked_minutes > project.budget_minutes}">
                                {{ $timeFormatHoursMinutes(project.worked_minutes) }}
                                <span v-if="project.budgets.filter(b => !b.is_open).length > 0 && !project.is_billable" class="fw-normal">
                                    ({{ ((project.worked_minutes / project.budget_minutes) * 100).toFixed(1) }}%)
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>Facturabel</td>
                            <td :class="{'text-success': project.billable_worked_minutes < project.budget_minutes, 'text-warning fw-bold': project.billable_worked_minutes === project.budget_minutes, 'text-danger fw-bold': project.billable_worked_minutes > project.budget_minutes}">
                                {{ $timeFormatHoursMinutes(project.billable_worked_minutes) }}
                                <span v-if="project.budgets.filter(b => !b.is_open).length > 0 && project.is_billable" class="fw-normal">
                                    ({{ ((project.billable_worked_minutes / project.budget_minutes) * 100).toFixed(1) }}%)
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>Niet facturabel</td>
                            <td>
                                {{ $timeFormatHoursMinutes(project.non_billable_worked_minutes) }}
                            </td>
                        </tr>
                        <tr>
                            <td>Gefactureerde uren</td>
                            <td>
                                {{ $timeFormatHoursMinutes(project.invoiced_minutes) }}
                            </td>
                        </tr>
                        <tr>
                            <td>Nog te factureren</td>
                            <td>
                                {{ $timeFormatHoursMinutes(project.billable_worked_minutes - project.invoiced_minutes) }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-lg-3 mb-3 my-lg-1">
                <div class="card mb-0">
                    <div class="card-header py-2">
                        <strong>Urenverdeling</strong>
                    </div>
                    <table v-if="hoursPerUser !== null" class="table table-border-bottom-0 text-left mb-0">
                        <tbody>
                            <tr v-for="user of hoursPerUser" :key="user.id">
                                <td class="d-flex align-items-center ps-3">
                                    <img :src="user.gravatar" class="gravatar-nano me-2" :style="'border: 1px solid ' + user.color">
                                    {{ user.name }}
                                </td>
                                <td class="text-right pe-3">
                                    {{ $timeFormatHoursMinutes(user.minutes) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-lg-9 my-lg-1">
                <div class="row">
                    <div class="col-12">
                        <div class="card mb-0">
                            <table class="table table-border-bottom-0 mb-0">
                                <thead>
                                    <tr>
                                        <th class="bg-light-grey" style="width: 600px;">Budget</th>
                                        <th class="bg-light-grey">Status</th>
                                        <th class="bg-light-grey">Besteedbaar</th>
                                        <th class="bg-light-grey">Gewerkt</th>
                                        <th class="bg-light-grey">Facturabel</th>
                                        <th class="bg-light-grey">Niet facturabel</th>
                                        <th class="bg-light-grey"><span class="pe-2">Gefactureerd</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="budget of project.budgets" :key="budget.id">
                                        <td style="line-height: 1.35em;">
                                            {{ budget.description }}
                                        </td>
                                        <td>
                                            <div class="d-flex h-100 align-items-center"><StatusLabel :status="budget.status"></StatusLabel></div>
                                        </td>
                                        <td>
                                            <div class="d-flex h-100 align-items-center">
                                                {{ budget.is_open ? 'Open': $timeFormatHoursMinutes(budget.workable_minutes) }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex h-100 align-items-center">
                                                {{ $timeFormatHoursMinutes(budget.worked_minutes) }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex h-100 align-items-center" :class="{'text-danger': budget.billable_worked_minutes > budget.workable_minutes && !budget.is_open}">
                                                {{ $timeFormatHoursMinutes(budget.billable_worked_minutes) }}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex h-100 align-items-center" :class="{'text-danger': budget.non_billable_worked_minutes > budget.workable_minutes && !budget.is_open}">
                                                {{ $timeFormatHoursMinutes(budget.non_billable_worked_minutes) }}
                                            </div>
                                        </td>
                                        <td>
                                            {{ $timeFormatHoursMinutes(budget.invoiced_minutes) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!small" class="mt-3">
            <div class="card">
                <div class="card-header">
                    <ul class="nav nav-tabs card-header-tabs ms-n1" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="hours-tab" data-bs-toggle="tab" data-bs-target="#hours" type="button" role="tab" aria-controls="hours" aria-selected="true">Uren</button>
                        </li>
                        <li v-if="$gate.allows('view-any', 'invoice')" class="nav-item" role="presentation">
                            <button class="nav-link" id="invoices-tab" data-bs-toggle="tab" data-bs-target="#invoices" type="button" role="tab" aria-controls="invoices" aria-selected="false">Facturen</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="file-uploads-tab" data-bs-toggle="tab" data-bs-target="#file-uploads" type="button" role="tab" aria-controls="file-uploads" aria-selected="false">File uploads</button>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="hours" role="tabpanel" aria-labelledby="hours-tab">
                            <TimeList :project="project"></TimeList>
                        </div>
                        <div v-if="$gate.allows('view-any', 'invoice')" class="tab-pane fade" id="invoices" role="tabpanel" aria-labelledby="invoices-tab">
                            <InvoiceList :project="project"></InvoiceList>
                        </div>
                        <div class="tab-pane fade" id="file-uploads" role="tabpanel" aria-labelledby="file-uploads-tab">
                            <FileUploads :project="project"></FileUploads>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {computed, reactive, ref, watch} from "vue";
import Project from "../../store/Models/Project";
import {Item} from "@vuex-orm/core";
import {isNil} from "lodash";
import PieChart from "../Charts/PieChart.vue";
import StatusLabel from "../StatusLabel.vue";
import Budget from "../../store/Models/Budget";
import TimeList from "./TimeList.vue";
import InvoiceList from "./InvoiceList.vue";
import User from "../../store/Models/User";
import FileUploads from "../../views/Project/FileUploads.vue";

const hoursPerUser = ref<any>(null);
const chartsRendered = ref<boolean>(false);
const showMore = reactive({
    description: false,
    appointment_notes: false,
});

const props = withDefaults(defineProps<{
    project_id: number,
    small?: boolean,
}>(), {
    small: false,
});

const project = computed<Item<Project>>(() => {
    return Project.query()
        .with('customer')
        .with('sub_customer')
        .with('budgets')
        .with('project_manager')
        .with('account_manager')
        .where('id', props.project_id)
        .first()
});

const mainChartData = computed(() => {
    const available = (project.value?.workable_minutes as number - (project.value.is_billable ? project.value?.billable_worked_minutes as number : project.value?.worked_minutes));

    return {
        labels: [
            'Gewerkt facturabel',
            'Beschikbaar',
        ],
        datasets: [{
            data: [
                project.value.is_billable ? project.value.billable_worked_minutes : project.value.worked_minutes,
                project.value.budgets.filter(b => !b.is_open).length > 0 ? available : null,
            ],
            backgroundColor: [
                '#1385C8', available > 0 ? '#59b9fa' : '#e02c2c'
            ]
        }]
    }
});

const hoursPerUserChart = computed<any>(() => {
    return {
        labels: hoursPerUser.value.map((i: any) => i.name),
        datasets: [{
            data: hoursPerUser.value.map((i: any) => i.minutes),
            backgroundColor: hoursPerUser.value.map((i: any) => i.color),
        }]
    }
});

const getPieChartForBudget = (budget: Budget): any => {
    const available = budget.workable_minutes - (project.value.is_billable ? budget.billable_worked_minutes : budget.worked_minutes ?? 0);

    return {
        labels: [
            'Gewerkt',
            'Beschikbaar',
        ],
        datasets: [{
            data: [
                project.value.is_billable ? budget.billable_worked_minutes : budget.worked_minutes,
                budget.is_open ? null : available,
            ],
            backgroundColor: [
                '#1385C8', available > 0 || budget.is_open ? '#9dd4f6' : '#e02c2c'
            ]
        }],
    }
}

watch(() => props.project_id, (value: number) => {
    if (isNil(value)) {
        return;
    }

    Project.api().get('projects/' + value, {
        params: {
            include: 'customer,subCustomer,budgets,projectManager,accountManager',
            additional: 'hours_per_user',
        }
    })
        .then(result => {
            hoursPerUser.value = result.response.data.hours_per_user;

            User.insertOrUpdate({data: result.response.data.data.project_manager});
            User.insertOrUpdate({data: result.response.data.data.account_manager});
        });
}, {immediate: true});

if (props.hasOwnProperty('small') && props.small) {
    window.bus.on('modalShown', () => {
        chartsRendered.value = true;
    });
} else {
    chartsRendered.value = true;
}
</script>

<style scoped>
canvas {
    margin-left: auto;
    margin-right: auto;
}

.budget-chart, .hours-per-user-chart {
    max-width: 140px;
    max-height: 140px;
}

.budget-chart-sm {
    margin-top: -1px;
    margin-bottom: 1px;
    max-height: 24px;
    max-width: 24px;
}

.truncate {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>
