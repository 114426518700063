import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";

export default class Customer extends Model {
    static entity = 'customers';

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            code: this.string(''),
            contact: this.string(''),
            email: this.string(''),
            color: this.string(false),
            phone: this.string(false),

            size: this.string(null).nullable(),
            employees: this.string(null).nullable(),
            branche: this.string(null).nullable(),
            address: this.string(null).nullable(),
            zip: this.string(null).nullable(),
            city: this.string(null).nullable(),
            country: this.string(null).nullable(),
            website: this.string(null).nullable(),
            founded_at: this.string(null).nullable(),
            lead: this.string(null).nullable(),
            customer_since: this.string(null).nullable(),
            account_manager_id: this.attr(null).nullable(),

            created_at: this.string(false),
        }
    }

    id!: number;
    name!: string;
    code!: string;
    contact!: string;
    email!: string;
    color!: string;
    phone!: string;
    created_at!: string;

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'name',
    }
}
