import User from "../../store/Models/User";
// @ts-ignore
const policies = import.meta.glob('../../policies/*.ts') as any;
import {snakeCase, isNil, camelCase} from "lodash";
import pluralize from "pluralize";
import Model from "../../store/Model";

export default class Gate {
    #user: User | null = null;
    #policies: any = {};
    #abilities: any = {};

    gatesChecked: any = {};
    gateCheckParams: any = {};

    constructor(user: User | null = null) {
        if (user !== null) {
            this.#user = user;

            window.bus.emit('gateLoaded');
        }

        // Import policies
        for (const policy in policies) {
            policies[policy]().then((module: any) => {
                const policyName = (policy as any).split('/').pop();
                const name = snakeCase(policyName.replace('Policy.ts', ''));
                this.#policies[name] = new (module.default);
            });
        }

        this.registerAbilities();

        window.bus.on('reloadGateChecks', () => {
            this.reloadGateChecks();
        });
    }

    registerAbilities(): void {
        this.#abilities['admin'] = user => {
            if (user.hasPermissionTo('admin')) {
                return true;
            }
        }

        this.#abilities['hours'] = user => {
            if (user.hasPermissionTo('view_hours')) {
                return true;
            }
        }

        this.#abilities['view_deadlines'] = user => {
            if (user.hasPermissionTo('view_deadlines')) {
                return true;
            }
        }

        this.#abilities['view_scrumboard'] = user => {
            if (user.hasPermissionTo('view_scrumboard')) {
                return true;
            }
        }

        this.#abilities['view_scrumboard_for_others'] = user => {
            if (user.hasPermissionTo('view_scrumboard_for_others')) {
                return true;
            }
        }

        this.#abilities['manage-sso'] = user => {
            if (user.hasPermissionTo('manage_sso')) {
                return true;
            }
        }
    }

    before(user: User, ability: string, args: any): boolean | void {
        //
    }

    after(user: User, ability: string, result: any, args: any): boolean | void {
        if (user !== null && ability === 'super_admin'){
            return true;
        }
    }

    allows(ability: string, args: any = []) {
        const user = this.#resolveUser();

        if (isNil(user)) {
            return false;
        }

        let result = this.before(user, ability, args);

        if (isNil(result)) {
            result = this.#callAuthCallback(user, ability, args);
        }

        const afterResult = this.after(user, ability, result, args);

        result = result ?? afterResult;

        // Add the result to the gatesChecked object and return the object key
        let mappedArgs;

        if (typeof args === "string") {
            mappedArgs = args;
        } else if (args instanceof Model) {
            mappedArgs = args.$id;
        } else if (Array.isArray(args)) {
            mappedArgs = args.map((a: any) => {
                return a instanceof Model ? (a as any).id : a.toString();
            });
        } else {
            mappedArgs = args.toString();
        }

        const key = user.id + ability + mappedArgs;

        this.gatesChecked[snakeCase(key)] = result;
        this.gateCheckParams[snakeCase(key)] = {
            ability: ability,
            args: args,
        };

        return this.gatesChecked[snakeCase(key)];
    }

    denies(ability: string, args: any = []) {
        return !this.allows(ability, args);
    }

    #callAuthCallback(user: User, ability: string, args: any) {
        let type = typeof args === "string" ? pluralize.singular(args) : args;

        if (typeof args === 'object') {
            if (args === null) {
                type = null;
            } else if (args instanceof Model) {
                type = args.getSingularEntity();
            } else if (args.hasOwnProperty('model')) {
                type = args.model.getSingularEntity();
            } else if (args.hasOwnProperty('model_name')) {
                type = args.model_name;
            }
        }

        type = snakeCase(type);

        const method = Gate.#formatAbilityToMethod(ability);

        if (user && this.#policies.hasOwnProperty(type) && typeof this.#policies[type][method] === 'function') {
            return this.#policies[type][method](user, typeof args === 'object' ? args : null);
        }

        if (this.#abilities[ability] && user) {
            return this.#abilities[ability](user, ...args);
        }
    }

    static #formatAbilityToMethod(ability: string) {
        return camelCase(ability);
    }

    #resolveUser(): User | null {
        return this.#user;
    }

    setUser(user: User) {
        this.#user = user;
    }

    abilities(): Object {
        return this.#abilities;
    }

    policies(): Object {
        return this.#policies;
    }

    reloadGateChecks(): void {
        for (const params of this.gateCheckParams) {
            this.allows(params.ability, params.args);
        }
    }
}
