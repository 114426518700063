import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";
import Customer from "./Customer";
import User from "./User";
import Budget from "./Budget";
import ProjectFile from "./ProjectFile";

export default class Project extends Model {
    static entity = 'projects';

    static fields() {
        return {
            id: this.attr(null),
            customer_id: this.attr(null),
            sub_customer_id: this.attr(null),
            creator_id: this.attr(null),
            project_manager_id: this.attr(null),
            account_manager_id: this.attr(null),
            name: this.string(''),
            description: this.string(''),
            appointment_notes: this.string(null).nullable(),
            status: this.string(''),
            is_billable: this.boolean(''),
            is_continuous: this.boolean(''),
            is_active: this.boolean(''),
            is_locked: this.boolean(''),
            document_url: this.string('').nullable(),
            ticket_url: this.string('').nullable(),
            website_url: this.string('').nullable(),
            repo_url: this.string('').nullable(),

            budget_minutes: this.number(null).nullable(),
            workable_minutes: this.number(null).nullable(),
            worked_minutes: this.number(null).nullable(),
            billable_worked_minutes: this.number(null).nullable(),
            non_billable_worked_minutes: this.number(null).nullable(),
            invoiced_minutes: this.number(null).nullable(),
            all_deadlines_finished: this.boolean(null).nullable(),

            last_activity_at: this.string(false).nullable(),
            created_at: this.string(false),

            customer: this.belongsTo(Customer, 'customer_id'),
            sub_customer: this.belongsTo(Customer, 'sub_customer_id'),
            creator: this.belongsTo(User, 'creator_id'),
            project_manager: this.belongsTo(User, 'project_manager_id'),
            account_manager: this.belongsTo(User, 'account_manager_id'),
            budgets: this.hasMany(Budget, 'project_id'),
            files: this.hasMany(ProjectFile, 'project_id'),
        }
    }

    declare id: number;
    declare customer_id: number;
    declare sub_customer_id: number;
    declare creator_id: number;
    declare project_manager_id: number;
    declare account_manager_id: number;
    declare name: string;
    declare description: string;
    declare appointment_notes: string | null;
    declare status: string;
    declare is_billable: boolean;
    declare is_continuous: boolean;
    declare is_active: boolean;
    declare is_locked: boolean;
    declare document_url: string;
    declare ticket_url: string;
    declare website_url: string;
    declare repo_url: string;
    declare created_at: string;

    declare customer: Customer;
    declare sub_customer: Customer;
    declare creator: User;
    declare project_manager: User;
    declare account_manager: User;
    declare budgets: Array<Budget>;
    declare files: Array<ProjectFile>;

    declare budget_minutes: number;
    declare workable_minutes: number;
    declare worked_minutes: number;
    declare billable_worked_minutes: number;
    declare non_billable_worked_minutes: number;
    declare invoiced_minutes: number;
    declare all_deadlines_finished: boolean;
    declare last_activity_at: string;

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'name',
    }
}
