<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">Projecten</li>
                </ol>
            </nav>

            <div id="buttons" class="ms-auto text-md-right align-self-center">
                <router-link :to="{name: 'projects.create'}" v-if="$gate.allows('create', 'project')" class="btn btn-sm btn-primary">
                    Nieuw project
                    <icon class="ms-1" icon="plus"></icon>
                </router-link>
            </div>
        </div>

        <ProjectList :header="false"></ProjectList>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ProjectList from "../../components/Project/ProjectList.vue";

export default defineComponent({
    components: {ProjectList}
});
</script>

<style scoped>

</style>
