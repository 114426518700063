import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";
import Invoice from "./Invoice";
import Budget from "./Budget";

export default class InvoiceRow extends Model {
    static entity = 'invoice_rows';

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'name',
    }

    static fields() {
        return {
            id: this.attr(null),
            invoice_id: this.attr(null),
            budget_id: this.attr(null),
            description: this.string('').nullable(),
            amount: this.attr(null),
            minutes: this.number(0),

            created_at: this.string(false),
            updated_at: this.string(false),

            invoice: this.belongsTo(Invoice, 'invoice_id'),
            budget: this.belongsTo(Budget, 'budget_id'),
        }
    }

    declare id: number;
    declare invoice_id: number;
    declare budget_id: number;
    declare description: string;
    declare amount: any;
    declare minutes: number;
    declare created_at: string;
    declare updated_at: string;
    declare invoice: Invoice;
    declare budget: Budget;



}
