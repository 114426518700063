import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";
import Project from "./Project";

export default class ProjectFile extends Model {
    static entity = 'project_file';

    static fields() {
        return {
            id: this.attr(null),
            project_id: this.attr(null),
            name: this.string(null),
            path: this.string(null),

            project: this.belongsTo(Project, 'project_id'),
        }
    }

    declare id: number;
    declare project_id: number;
    declare name: string;
    declare path: string;

    declare project: Project;

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'name',
    }
}
