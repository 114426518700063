import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";
import User from "./User";
import Budget from "./Budget";
import Deadline from "./Deadline";
import Project from "./Project";
import Invoice from "./Invoice";

export default class Time extends Model {
    static entity = 'times';

    static fields() {
        return {
            id: this.attr(null),
            invoice_id: this.attr(null),
            project_id: this.attr(null),
            budget_id: this.attr(null),
            deadline_id: this.attr(null),
            user_id: this.attr(null),
            date: this.string(''),
            minutes: this.number(0),
            description: this.string(''),
            is_billable: this.boolean(''),

            invoice: this.belongsTo(Invoice, 'invoice_id'),
            project: this.belongsTo(Project, 'project_id'),
            budget: this.belongsTo(Budget, 'budget_id'),
            deadline: this.belongsTo(Deadline, 'deadline_id'),
            user: this.belongsTo(User, 'user_id'),
        }
    }

    id!: number;
    invoice_id!: number;
    project_id!: number;
    budget_id!: number;
    deadline_id!: number;
    date!: string;
    minutes!: number;
    description!: string;
    is_billable!: boolean;

    invoice!: Invoice;
    project!: Project;
    budget!: Budget;
    deadline!: Deadline;
    user!: User;

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'name',
    }
}
