<template>
    <div>
        <div v-if="budgetExceeded && form.project_id !== null" class="alert alert-warning fade show" role="alert">
            Dit budget is al over de maximale hoeveelheid uren heen.
        </div>
        <div class="card">
            <div class="card-header" style="border-top: 0;">
                <DateButtons @date="date => form.date = date._i" :date="form.date"></DateButtons>
            </div>
            <div class="card-body card-body-time">
                <form @submit.prevent="addTime()" class="d-flex flex-column flex-md-row my-n2 m-md-n2">
                    <div class="py-2 p-md-2 pr-md-3" style="flex: 1.8;">
                        <ProjectSelect
                            id="project"
                            v-if="renderProjectSelect"
                            @project="project => form.project_id = project?.id"
                            :onlyForMe="true"
                            :writable="true"
                            :not_archived="true"
                            @change="fetchProject(form.project_id)"
                            v-validate:[form.errors]="'project_id'"
                        ></ProjectSelect>
                        <error field="project_id" :bag="form.errors"></error>
                    </div>
                    <div class="py-2 p-md-2 pr-md-3" style="flex: 1.5">
                        <Multiselect
                            trackBy="description"
                            v-model="form.deadline_id"
                            placeholder="Selecteer een deadline"
                            label="description"
                            valueProp="id"
                            v-validate:[form.errors]="'deadline_id'"
                            @change="answer => setDeadline(answer)"
                            :options="deadlines"
                            :disabled="disableDeadline"
                        >
                        </Multiselect>
                        <error field="deadline_id" :bag="form.errors"></error>
                    </div>
                    <div class="py-2 py-md-2 pr-md-2" style="width: 60px">
                        <TimeInput
                            id="time"
                            v-model="form.minutes"
                            @answer="setMinutesValue"
                        />
                    </div>
                    <div class="py-2 p-md-2" style="flex: 2">
                        <input
                            id="description"
                            name="description"
                            type="text"
                            class="form-control"
                            placeholder="Omschrijving"
                            v-model="form.description"
                            v-validate:[form.errors]="'description'"
                        >
                        <error field="description" :bag="form.errors"></error>
                    </div>
                    <div class="py-2 p-md-2 d-flex" style="width: 50px">
                        <div class="align-self-center">
                            <input type="checkbox" class="checkbox-margin" id="customCheck" name="billable" v-model="form.is_not_billable">
                            <label for="customCheck">
                            <span class="d-none d-md-inline-block" data-tooltip="Niet Facturabel">
                                NF
                            </span>
                                <span class="d-inline-block d-md-none">
                                Niet Facturabel
                            </span>
                            </label>
                        </div>
                    </div>
                    <div class="py-2 p-md-2">
                        <button :disabled="form.working" type="submit" class="btn btn-md btn-success">Magic! <i class="fas fa-magic ml-1"></i></button>
                    </div>
                </form>

                <div class="card-body card-body-dashboard-charts pb-1">
                    <div class="row mx-n2">

                        <div class="col-6 col-md px-2 mb-3" v-for="(chart, key) of charts" :key="key">
                            <div class="dashboard-chart" :class="$timeToColor(this.user, chart.date, chart.total_time + chart.furlough)">
                                <small class="text-capitalize" style="display: inline-block; margin-bottom: 16px; line-height:1.3em;" v-if="$moment(chart.date).isSame(new Date(), 'day')">Vandaag</small>
                                <small class="text-capitalize" style="display: inline-block; margin-bottom: 16px; line-height:1.3em;" v-else-if="$moment(chart.date).isSame($moment().subtract(1, 'day'), 'day')">Gisteren</small>
                                <small class="text-capitalize" style="display: inline-block; margin-bottom: 16px; line-height:1.3em;" v-else>{{chart.date_format}}</small>

                                <PieChart :data="chart.data" width="100" height="100"></PieChart>

                                <div style="margin-top: 16px;">
                                    <template v-if="chart.furlough < 480">
                                        <span :style="'color:'+$timeToColor(this.user, chart.date, chart.total_time)" v-if="key < 4">
                                            {{$timeFormatHoursMinutes(chart.total_time)}} <sup v-if="chart.furlough > 0" style="color: #ebb141">{{$timeFormatHoursMinutes(chart.furlough)}}</sup>
                                        </span>
                                            <span v-else>
                                            {{$timeFormatHoursMinutes(chart.total_time)}} <sup v-if="chart.furlough > 0" style="color: #ebb141">{{$timeFormatHoursMinutes(chart.furlough)}}</sup>
                                        </span>
                                    </template>
                                    <span v-else>
                                        0:00 <sup style="color: #ebb141">{{$timeFormatHoursMinutes(chart.furlough)}}</sup>
                                    </span>
                                </div>
                                <small style="display: inline-block; margin-top: 4px; line-height:1.3em;">
                                <span class="text-muted text-small" v-if="chart.checkin !== null">
                                    {{ chart.checkin.created_at.substring(11, 16) }}
                                    <span v-if="chart.checkin.checkout !== null">
                                        - {{ chart.checkin.checkout.substring(11, 16) }}
                                    </span>
                                    <icon class="ms-1 opacity-50" icon="rocket"></icon>
                                    <span class="" v-if="chart.checkin.checkout !== null">
                                        ({{ $timeFormatHoursMinutes($moment(chart.checkin.checkout).diff($moment(chart.checkin.created_at), 'minutes')) }})
                                    </span>
                                </span>
                                </small>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="card-body table-responsive">
                    <table class="table table-sm table-hover table-align-middle mb-0">
                        <thead>
                        <tr>
                            <th>Tijd</th>
                            <th>Project</th>
                            <th>Omschrijving</th>
                            <th>Datum</th>
                            <th style="width: 85px"></th>
                        </tr>
                        </thead>
                        <tbody v-if="times.length > 0">
                        <tr v-for="(time, index) of times" :key="time.id" class="hover-show" :class="{'text-muted': !time.billable}" style="height: 36px">
                            <td>{{ $timeFormatHoursMinutes(time.minutes) }}</td>
                            <td class="text-nowrap">
                                <router-link :to="{name: 'projects.show', params: {project: time.project_id}}">
                                    <span class="client-border" :style="'border-color:'+time.project.customer.color"></span>[{{ time.project.customer.code }}] {{ time.project.name }}
                                </router-link>
                            </td>
                            <td>
                                <div class="text-clamp" :title="time.description">
                                    {{ time.description }} <span class="badge bg-secondary" v-if="time.is_not_billable">#niet-facturabel</span>
                                </div>
                            </td>
                            <td class="text-nowrap" :title="time.created_at">
                                <span v-if="$moment(time.date).isSame($moment(), 'day')">Vandaag</span>
                                <span v-else-if="$moment(time.date).isSame($moment().subtract(1, 'days'), 'day')">Gisteren</span>
                                <span class="text-capitalize"  v-else>{{ $moment(time.date).format('dddd D MMMM') }}</span>
                            </td>
                            <td class="text-nowrap" style="text-align: right">
                                <button class="hover-hidden btn btn-sm btn-primary me-2" type="button" @click="editTime(time)"><icon icon="pen-to-square"></icon></button>
                                <button class="hover-hidden btn btn-sm btn-danger" type="button" @click="deleteTime(time.id, index)"><icon icon="trash"></icon></button>
                            </td>
                            <EditTimeModal v-if="editTimeModalId === time.id" @saved="editedTime()" :time="time"></EditTimeModal>
                        </tr>
                        </tbody>
                        <tbody v-else><tr><td colspan="5">Nog geen uren geregistreerd</td></tr></tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="dropdown dashboard-settings-cogs">
            <button class="btn btn-secondary dropdown-toggle rounded-pill" type="button" id="dashboardDropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
                <icon icon="cogs"></icon>
            </button>
            <div class="dropdown-menu" aria-labelledby="dashboardDropdownMenu">
                <div class="card-body">
                    <label class="form-label">Aantal uren</label>
                    <select class="form-select" id="page-size" @change="setPageSize($event)">
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import DateButtons from "../components/Dashboard/DateButtons.vue";
import ProjectSelect from "../components/Project/ProjectSelect.vue";
import Time from "../store/Models/Time";
import TimeInput from "../components/TimeInput.vue";
import Project from "../store/Models/Project";
import Multiselect from '@vueform/multiselect';
import EditTimeModal from "../components/Dashboard/EditTimeModal.vue";
import PieChart from "../components/Charts/PieChart.vue";
import {find, isNil} from "lodash";

export default defineComponent({
    components: {PieChart, ProjectSelect, DateButtons, TimeInput, Multiselect, EditTimeModal},
    data: () => ({
        form: {
            date: window.moment().format('Y-MM-DD'),
            project_id: null,
            budget_id: null,
            deadline_id: null,
            minutes: 0,
            description: null,
            is_not_billable: false,

            errors: null,
            working: false,
        } as any,
        renderProjectSelect: true,
        disableDeadline: false,
        deadlines: [] as any,
        budgets: [] as any,
        charts: [],
        times: [],
        editTimeModal: null as any,
        editTimeModalId: 0 as number,
        budgetExceeded: false,
        user: window.me,
    }),
    methods: {
        setMinutesValue(value: number) {
            this.form.minutes = value;
            document.getElementById('description').focus();
        },
        addTime() {
            this.form.working = true;
            this.form.errors = null;

            Time.api().post('times', this.form)
                .then(() => {
                    this.fetchTimes();
                    this.fetchCharts();

                    this.form.description = null;
                    this.form.minutes = null;
                    this.form.deadline_id = null;
                    this.form.budget_id = null;
                    this.form.project_id = null;

                    this.renderProjectSelect = false;
                    this.$nextTick(() => {
                        this.renderProjectSelect = true;
                    });
                })
                .catch((e: any) => {
                    if (e.response.status === 422) {
                        this.form.errors = e.response.data.errors;
                    }
                })
                .finally(() => {
                    this.form.working = false;
                    this.$nextTick(() => {
                        this.selectProject();
                    })
                });
        },
        fetchProject(id: number) {
            if (isNil(id)) {
                this.deadlines = [];
                this.form.project_id = null
                return;
            }
            this.form.deadline_id = null;
            this.deadlines = [];
            this.budgetExceeded = false;

            Project.api().get('projects/' + id, {
                params: {
                    include: 'budgets.deadlines',
                    deadlines_for_me: true,
                }
            })
                .then((result: any) => {
                    result.response.data.data.budgets.map((budget: any) => {
                        if (budget.status === 'active' || budget.status === 'blocked') {
                            for (const deadline of budget.deadlines) {
                                this.deadlines.push(deadline);
                            }
                            this.budgets.push(budget)
                        }
                    });

                    if (this.deadlines.length === 1) {
                        this.disableDeadline = true;
                        this.setDeadline(this.deadlines[0].id);
                    } else {
                        this.form.deadline_id = null;
                        this.disableDeadline = false;
                    }

                    if (this.budgets.length === 1) {
                        if (!this.budgets[0].is_open && this.budgets[0].workable_minutes < this.budgets[0].worked_minutes) {
                            this.budgetExceeded = true;
                        }
                    }
                })
        },
        setDeadline(answer: any) {
            const deadline = find(this.deadlines, {id: answer});

            if (deadline) {
                this.form.deadline_id = deadline.id;
                this.form.budget_id = deadline.budget_id;

                for (let budget of this.budgets) {
                    if (budget.id === deadline.budget_id) {
                        this.budgetExceeded = !budget.is_open && budget.workable_minutes < budget.worked_minutes;
                    }
                }
            }

            document.getElementById('time').focus();
        },
        fetchTimes() {
            const params = {
                include: 'project,project.customer',
                'filter[user_id]': window.me?.id,
            };

            if (localStorage.getItem('dashboardPageSize')) {
                params['page[size]'] = localStorage.getItem('dashboardPageSize');
            }

            Time.api().get('times', {
                params: params,
            })
                .then((result: any) => {
                    this.times = result.response.data.data
                })
        },
        deleteTime(id: number, index: number) {
            Time.api().delete('times/' + id)
                .then(() => {
                    this.times.splice(index, 1)
                })
        },
        editTime(time: any) {
            this.editTimeModalId = time.id;

            this.$nextTick(() => {
                this.editTimeModal = new window.bootstrap.Modal(document.getElementById('editTime_' + time.id));
                this.editTimeModal.show();
            });
        },
        editedTime() {
            this.editTimeModal.hide();

            this.editTimeModalId = 0;

            this.fetchTimes();
            this.fetchCharts();
        },
        fetchCharts() {
            window.api.get('dashboard')
                .then(response => {
                    this.charts = response.data;
                })
        },
        setPageSize(event) {
            localStorage.setItem('dashboardPageSize', event.target.value);
            this.fetchTimes();
        },
        selectProject() {
            document.getElementById('project').focus();
        }
    },
    mounted() {
        (document.getElementById('page-size') as any).value = localStorage.getItem('dashboardPageSize') || '25';
        this.selectProject();
    },
    created() {
        this.fetchTimes();
        this.fetchCharts();
    }
});
</script>

<style scoped lang="scss">
.checkbox-margin{
    margin-right: 4px;
}

.dashboard-settings-cogs {
    position: fixed;
    bottom: 2rem;
    right: 2rem;

    .dropdown-toggle::after {
        display: none !important;
    }
}

.budget-exceeded {
    color: orangered;
    font-size: 12px;
}

.text-small {
    font-size: 11px;
}
</style>
