<template>
    <div>
        <div class="breadcrumbs d-flex align-items-center">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">Agenda</li>
                </ol>
            </nav>

            <div class="ms-auto d-flex flex-wrap align-items-center">
                <template v-if="enable_multiple_calendars">
                    <AgendaUserSelect v-if="is_adding" :isAdding="true" :selected-users="user_ids" @answer="addUser"></AgendaUserSelect>
                    <button v-else class="btn btn-sm btn-primary rounded-pill me-2" @click="is_adding = true">
                        <icon icon="plus"></icon>
                    </button>
                </template>
                <template v-else>
                    <AgendaUserSelect :selected-users="user_ids" :isAdding="false" :selected="user_ids[0]" @answer="addUser"></AgendaUserSelect>
                </template>

                <div v-if="users.length > 0 && enable_multiple_calendars" class="d-flex flex-wrap">
                    <div v-for="user of users" :key="user.id" class="d-flex align-items-center ms-2">
                        <span class="badge rounded-pill d-flex align-items-center me-2" :style="'background-color: '+user.color">
                            <img class="ms-n1 gravatar-nano" :src="user.gravatar" />
                            <span class="ms-2" style="margin-bottom:3px;font-weight:400;font-size:1rem;">{{ user.name.indexOf(' ') !== -1 ? user.name.substring(0, user.name.indexOf(' ')) : user.name }}</span>
                            <button class="btn btn-link text-white p-0 ms-2 me-1" @click="user_ids.splice(user_ids.indexOf(user.id), 1)">
                                <icon icon="times"></icon>
                            </button>
                        </span>
                    </div>
                </div>

                <div class="me-4 ms-2" style="height: 24px; width: 2px; background-color: #ccc"></div>

                <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="enable_multiple_calendars" id="enable_multiple_calendars">
                    <label class="form-check-label" for="enable_multiple_calendars">
                        <icon icon="object-ungroup"></icon>
                    </label>
                </div>
            </div>
        </div>

        <div class="row">
            <div v-for="user of users" :key="user.id" class="col">
                <UserCalendar :user="user"></UserCalendar>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import User from "../../store/Models/User";
import UserCalendar from "./UserCalendar.vue";
import AgendaUserSelect from "../../components/AvatarUserSelect.vue";
import {isNil} from "lodash";

export default defineComponent({
    components: {AgendaUserSelect, UserCalendar},
    data() {
        return {
            is_adding: false,
            enable_multiple_calendars: false,
            user_ids: [] as Array<number>,
            users: [] as Array<User>,
        }
    },
    watch: {
        enable_multiple_calendars(val) {
            const mainContainer = (document.querySelector('main') as HTMLElement);

            if (!val) {
                mainContainer.setAttribute('style', '');

                if (this.user_ids.length > 0) {
                    this.user_ids.splice(1, this.user_ids.length - 1);
                }
            } else {
                mainContainer.setAttribute('style', 'max-width:95%!important');
            }

            this.$nextTick(() => {
                this.rerenderCalendars();
            });
        },
        user_ids: {
            handler(items) {
                this.rerenderCalendars();

                if (this.enable_multiple_calendars) {
                    if (items.length > 1) {
                        this.$router.replace({name: 'calendar', query: {users: items.toString()}});
                    } else {
                        this.$router.replace({name: 'calendar', query: {user: items.toString()}});
                    }
                } else {
                    this.$router.replace({name: 'calendar', query: {user: items.toString()}});
                }

                User.api().get('calendar/users?filter[id]='+items.join(',')+'&include=deadlines.budget.project.customer')
                    .then(response => {
                        this.users = response.response.data.data;

                        this.$nextTick(() => {
                            this.rerenderCalendars();
                        });
                    });
            },
            deep: true,
        }
    },
    methods: {
        addUser(user: User) {
            if (!this.enable_multiple_calendars) {
                this.user_ids = [];
            }

            if (this.user_ids.indexOf(user.id) === -1) {
                this.user_ids.push(user.id);
            }

            this.is_adding = false;

            this.$nextTick(() => {
                this.rerenderCalendars();
            });
        },
        rerenderCalendars() {
            this.$bus.emit('reloadCalendar');
        },
    },
    created() {
        // Check if there are users in the URL
        if (!isNil(this.$route.query.users)) {

            // If there are users we should toggle the multi view
            this.enable_multiple_calendars = true;

            // If there are users we should add them
            const userIds = (this.$route.query.users as string).split(',');

            User.api().get('users', {
                params: {
                    'filter[id]': this.$route.query.users,
                }
            })
                .then(() => {
                    const users = User.findIn(userIds);
                    for (const user of users) {
                        this.addUser(user);
                    }
                });

        } else if (!isNil(this.$route.query.user)) {

            // add user from url to calendar
            const userId = this.$route.query.user;
            User.api().get('users/'+userId).then(() => {
                const user = User.find(userId);
                this.addUser(user);
            });

        } else {

            // If there are no users we should add the current user
            this.user_ids.push(
                this.$me().id,
            );

        }
    }
});
</script>

<style scoped>

</style>
