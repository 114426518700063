<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <router-link :to="{name: 'absences.index'}" class="breadcrumb-item">
                        Verzuim
                    </router-link>
                    <li class="breadcrumb-item active" aria-current="page">Create</li>
                </ol>
            </nav>

            <div class="ms-auto">
                <router-link :to="{name: 'absences.index'}" class="btn btn-danger me-3" :class="{disabled: form.working}">
                    Cancel
                </router-link>
                <button class="btn btn-success" :disabled="form.working" @click="save">
                    Save
                    <icon icon="floppy-disk" class="ms-2"></icon>
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-7">
                <div class="card">
                    <div class="card-body">
                        <div class="mb-3">
                            <label class="form-label" for="user_id">Gebruiker</label>
                            <UserSelect class="me-2"
                                        id="user_id"
                                        @answer="u => form.user_id = u.id"
                                        v-validate:[form.errors]="'user_id'"
                            ></UserSelect>
                            <error field="user_id" :bag="form.errors"></error>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Omschrijving</label>
                            <textarea class="form-control" v-model="form.description" v-validate:[form.errors]="'description'"></textarea>
                            <error field="user_id" :bag="form.errors"></error>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <div class="mb-3">
                                    <label class="form-label" for="start">Start</label>
                                    <input class="form-control" id="start" type="datetime-local" v-model="form.start" v-validate:[form.errors]="'start'" />
                                    <error field="start" :bag="form.errors"></error>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="mb-3">
                                    <label class="form-label" for="end">Eind</label>
                                    <input class="form-control" id="end" type="datetime-local" v-model="form.end" v-validate:[form.errors]="'end'" />
                                    <error field="end" :bag="form.errors"></error>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import UserSelect from "../../components/UserSelect.vue";
import Absence from "../../store/Models/Absence";

export default defineComponent({
    components: {UserSelect},
    data() {
        return {
            form: {
                user_id: null,
                description: null,
                start: null,
                end: null,

                working: false,
                errors: null,
            }
        }
    },
    methods: {
        save() {
            this.form.working = true;
            this.form.errors = null;

            Absence.api().post('absences', this.form)
                .then(() => {
                    this.$toast('success', 'Verzuim is succesvol toegevoegd.');

                    this.$router.push({
                        name: 'absences.index',
                    });
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.form.errors = e.response.data.errors;
                    }
                })
                .finally(() => {
                    this.form.working = false;
                });
        }
    }
});
</script>

<style scoped>

</style>
