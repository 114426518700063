<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">Verzuim</li>
                </ol>
            </nav>

            <div class="ms-auto">
                <router-link :to="{name: 'absences.create'}" class="btn btn-primary">
                    Nieuw
                    <icon icon="plus" class="ms-2"></icon>
                </router-link>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <table class="table">
                    <thead>
                    <tr>
                        <th>Gebruiker</th>
                        <th>Start</th>
                        <th>Eind</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="absence of absences" :key="absence.id">
                        <td>
                            <img :src="absence.user.gravatar" class="gravatar-nano" :style="'border: 1px solid' + absence.user.color">
                            <span class="ms-3">{{ absence.user.name }}</span>
                        </td>
                        <td>
                            {{ $moment(absence.start).format('Y-MM-DD HH:mm') }}
                        </td>
                        <td>
                            {{ absence.end ? $moment(absence.end).format('Y-MM-DD HH:mm') : 'N/A' }}
                        </td>
                        <td>
                            <div class="d-flex">
                                <button class="btn btn-danger btn-sm" @click="deleteAbsence(absence.id)">
                                    <icon icon="trash"></icon>
                                </button>
                                <router-link :to="{name: 'absences.edit', params: {absence: absence.id}}" class="btn btn-primary btn-sm ms-2">
                                    <icon icon="pen-to-square"></icon>
                                </router-link>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Absence from "../../store/Models/Absence";

export default defineComponent({
    computed: {
        absences(): Array<Absence> {
            return Absence.query()
                .with('user')
                .get();
        }
    },
    methods: {
        deleteAbsence(id: number) {
            Absence.api().delete('absences/'+id, {
                delete: id,
            })
                .then(() => {
                    this.$toast('success', 'Verzuim is succesvol verwijderd.');
                });
        }
    },
    created() {
        Absence.api().get('absences?include=user');
    }
});
</script>

<style scoped>

</style>
