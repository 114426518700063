<template>
    <div class="card-body" style="background: #efefef; margin: -1px; padding: 0;">
        <div class="row">
            <div class="col-lg-5 col-md-8 mx-md-auto">
                <div class="card">
                    <div class="card-header">
                        <strong>Inloggen</strong>
                    </div>
                    <div class="card-body">
                        <form v-if="twoFactorForm.show === false" @submit.prevent="login">
                            <div class="form-group row">
                                <label for="email" class="col-12 col-form-label pt-0">
                                    E-mailadres
                                </label>

                                <div class="col-12">
                                    <input id="email" type="email" class="form-control" required autocomplete="email" autofocus v-model="form.email" v-validate:[form.errors]="'email'">
                                    <error field="email" :bag="form.errors"></error>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="password" class="col-12 col-form-label">Wachtwoord</label>

                                <div class="col-12">
                                    <input id="password" type="password" class="form-control" required autocomplete="current-password" v-model="form.password" v-validate:[form.errors]="'password'">
                                    <error field="password" :bag="form.errors"></error>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-12">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="remember" v-model="form.remember">
                                        <label class="form-check-label" for="remember">
                                            Blijf ingelogd
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row mb-0">
                                <div class="col-12">
                                    <button type="submit" class="btn btn-primary" :disabled="form.working">Inloggen</button>
                                    <router-link :to="{name: 'auth.forgot'}" class="btn btn-link" :class="{disabled: form.working}" :disabled="form.working">Wachtwoord vergeten</router-link>
                                </div>
                            </div>
                        </form>

                        <form v-if="twoFactorForm.show === true" @submit.prevent="sendTwoFactorCode()">
                            <div v-if="!useRecoveryCode" class="form-group">
                                <label>Code</label>
                                <input type="text" class="form-control" v-model="twoFactorForm.code" v-validate:[twoFactorForm.errors]="'code'">
                                <error field="code" :bag="twoFactorForm.errors"></error>
                            </div>

                            <div v-if="useRecoveryCode" class="form-group">
                                <label>Recovery Code</label>
                                <input type="text" class="form-control" v-model="twoFactorForm.recovery_code" v-validate:[twoFactorForm.errors]="'recovery_code'">
                                <error field="recovery_code" :bag="twoFactorForm.errors"></error>
                            </div>

                            <div class="form-group mb-0">
                                <div class="col-12">
                                    <button type="submit" class="btn btn-primary" :disabled="form.working">Inloggen</button>
                                    <button v-if="!useRecoveryCode" type="button" class="btn btn-link" :disabled="form.working" @click="useRecoveryCode = true">Recovery code gebruiken?</button>
                                    <button v-else class="btn btn-link" type="button" :disabled="form.working" @click="useRecoveryCode = false">Normale TOTP code gebruiken?</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    data() {
        return {
            form: {
                email: null,
                password: null,
                remember: false,

                errors: null,
                working: false,
            },

            useRecoveryCode: false,

            twoFactorForm: {
                show: false,

                code: null,
                recovery_code: null,

                errors: null,
                working: false,
            }
        }
    },
    watch: {
        useRecoveryCode() {
            this.twoFactorForm.code = null;
            this.twoFactorForm.recovery_code = null;
        }
    },
    methods: {
        login() {
            this.form.errors = null;
            this.form.working = true;

            this.$authenticate(this.form).then(response => {
                if (!response.data.two_factor) {
                    this.$setLoggedIn();

                    if (this.$route.query.redirect) {
                        window.location.href = (this.$route.query.redirect as string);
                    } else {
                        this.$router.push({
                            name: 'dashboard',
                        });
                    }

                    return;
                }

                this.twoFactorForm.show = true;
            })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.form.errors = e.response.data.errors;
                    }
                })
                .finally(() => {
                    this.form.working = false
                });
        },
        sendTwoFactorCode() {
            this.twoFactorForm.errors = null;
            this.twoFactorForm.working = true;

            window.axios.post('/two-factor-challenge', this.twoFactorForm)
                .then(() => {
                    this.$setLoggedIn();

                    if (this.$route.query.redirect) {
                        window.location.href = (this.$route.query.redirect as string);
                    } else {
                        this.$router.push({
                            name: 'dashboard',
                        });
                    }
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.twoFactorForm.errors = e.response.data.errors;
                    }
                })
                .finally(() => {
                    this.twoFactorForm.working = false
                });
        },
        // todo; recovery code
    },
    mounted() {
        this.$isLoggedIn(false).then((isLoggedIn: boolean) => {
            if (isLoggedIn) {
                if (this.$route.query.redirect) {
                    window.location.href = (this.$route.query.redirect as string);
                } else {
                    this.$router.push({
                        name: 'dashboard',
                    });
                }
            }
        });
    }
});
</script>

<style scoped>

</style>
