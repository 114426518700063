// @ts-nocheck
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faArrowLeft, faArrowRight,
    faArrowUpRightFromSquare,
    faBars, faCheck, faCheckCircle, faCircleNotch,
    faCogs, faDownLeftAndUpRightToCenter,
    faEnvelope, faEye, faEyeSlash, faFileSignature,
    faFloppyDisk,
    faGrip,
    faHouse,
    faNetworkWired, faObjectUngroup, faPaperPlane,
    faPenToSquare, faPlus,
    faPlusCircle, faQuestionCircle, faRightFromBracket,
    faRocket,
    faTimes,
    faTrash,
    faTrashArrowUp,
    faXmark, faChevronUp, faChevronDown, faTriangleExclamation, faDownload, faBuildingShield, faFileArrowUp, faArrowsLeftRight
} from '@fortawesome/free-solid-svg-icons';

export default {
    install: (app: any) => {
        library.add(faArrowLeft);
        library.add(faArrowRight);
        library.add(faArrowsLeftRight);
        library.add(faArrowUpRightFromSquare);
        library.add(faBars);
        library.add(faBuildingShield);
        library.add(faCheck);
        library.add(faCheckCircle);
        library.add(faChevronDown);
        library.add(faChevronUp);
        library.add(faCircleNotch);
        library.add(faCogs);
        library.add(faDownLeftAndUpRightToCenter);
        library.add(faDownload);
        library.add(faEnvelope);
        library.add(faEye);
        library.add(faEyeSlash);
        library.add(faFileArrowUp);
        library.add(faFileSignature);
        library.add(faFloppyDisk);
        library.add(faGrip);
        library.add(faHouse);
        library.add(faNetworkWired);
        library.add(faObjectUngroup);
        library.add(faPaperPlane);
        library.add(faPenToSquare);
        library.add(faPlus);
        library.add(faPlusCircle);
        library.add(faQuestionCircle);
        library.add(faRightFromBracket);
        library.add(faRocket);
        library.add(faTimes);
        library.add(faTrash);
        library.add(faTrashArrowUp);
        library.add(faTriangleExclamation);
        library.add(faXmark);

        app.component('icon', FontAwesomeIcon);
    }
};
