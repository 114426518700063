<template>
    <Multiselect
        v-model="customer"
        trackBy="name"
        :searchable="true"
        :closeOnSelect="true"
        :object="true"
        :minChars="2"
        :delay="316"
        placeholder="Selecteer een klant"
        label="name"
        valueProp="id"
        @change="setAnswer"
        :options="fetchCustomers"
    >
    </Multiselect>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import Customer from "../store/Models/Customer";

export default {
    components: {Multiselect},
    props: {
        selected: Number|String,
    },
    data() {
        return {
            customer: this.selected??null,
        }
    },
    watch: {
        selected() {
            this.customer = this.selected;
        }
    },
    methods: {
        async fetchCustomers(query) {
            let url = 'customers';

            if (query !== null) {
                url += '?filter[search]='+query;
            }

            const response = await window.api.get(url);

            await Customer.insertOrUpdate({
                data: response.data.data
            });

            return response.data.data;
        },
        setAnswer(value) {
            this.$emit('answer', value);
        }
    },
}
</script>

<style scoped>

</style>
