<template>
    <div>
        <template v-if="project !== null">
            <div class="breadcrumbs d-flex">
                <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                    <ol class="breadcrumb">
                        <router-link class="breadcrumb-item" :to="{name: 'projects.index'}">Projecten</router-link>
                        <router-link class="breadcrumb-item" :to="{name: 'projects.show', params: {project: project.id}}">{{ project?.name }}</router-link>
                        <li class="breadcrumb-item active" aria-current="page">Bewerken</li>
                    </ol>
                </nav>
            </div>

            <div class="card">
                <div class="card-header">
                    <ul class="nav nav-tabs card-header-tabs ms-n1">
                        <template v-if="$gate.allows('update', project)">
                            <li class="nav-item" role="presentation">
                                <router-link :to="{name: 'projects.show', params: {project: project.id}}" class="nav-link">
                                    Details
                                </router-link>
                            </li>
                        </template>
                        <li class="nav-item bg-light-grey" role="presentation">
                            <a class="nav-link active fw-bold" aria-selected="true">Bewerken <icon class="ms-1" icon="pen-to-square"></icon></a>
                        </li>
                        <template v-if="$gate.allows('viewAny', 'budget')">
                            <li class="nav-item" role="presentation">
                                <router-link :to="{name: 'projects.budgets', params: {project: project.id}}" class="nav-link">
                                    Budgetbeheer <icon class="ms-1" icon="file-signature"></icon>
                                </router-link>
                            </li>
                        </template>
                        <div id="buttons" class="ms-auto me-2 text-md-right align-self-center">
                            <button v-if="$gate.allows('delete', project)" class="btn btn-sm btn-danger" @click="deleteProject" :class="{disabled: form.working}" :disabled="form.working">
                                Verwijder
                                <icon class="ms-1" icon="trash"></icon>
                            </button>
                            <button v-if="$gate.allows('update', project)" class="btn btn-sm btn-success" type="submit" form="create-form" :disabled="form.working">
                                Opslaan
                                <icon class="ms-1" icon="floppy-disk"></icon>
                            </button>
                        </div>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content">
                        <div class="tab-pane fade show active">
                            <form @submit.prevent="save" id="create-form">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h4>Algemeen</h4>
                                        <div class="form-group">
                                            <label class="form-label">Titel</label>
                                            <input class="form-control" type="text" v-model="form.name" v-validate:[form.errors]="'name'" />
                                            <error field="name" :bag="form.errors"></error>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Omschrijving</label>
                                            <textarea class="form-control" v-model="form.description" v-validate:[form.errors]="'description'"></textarea>
                                            <error field="description" :bag="form.errors"></error>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Afspraak klant notities</label>
                                            <textarea class="form-control" v-model="form.appointment_notes" v-validate:[form.errors]="'appointment_notes'"></textarea>
                                            <error field="appointment_notes" :bag="form.errors"></error>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Documentatie URL</label>
                                            <input class="form-control" type="text" v-model="form.document_url" v-validate:[form.errors]="'document_url'" />
                                            <error field="document_url" :bag="form.errors"></error>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Ticket URL</label>
                                            <input class="form-control" type="text" v-model="form.ticket_url" v-validate:[form.errors]="'ticket_url'" />
                                            <error field="ticket_url" :bag="form.errors"></error>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Website URL</label>
                                            <input class="form-control" type="text" v-model="form.website_url" v-validate:[form.errors]="'website_url'" />
                                            <error field="website_url" :bag="form.errors"></error>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Repo URL</label>
                                            <input class="form-control" type="text" v-model="form.repo_url" v-validate:[form.errors]="'repo_url'" />
                                            <error field="repo_url" :bag="form.errors"></error>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <h4>Instellingen</h4>

                                        <div class="form-group">
                                            <label class="form-label">Klant</label>
                                            <CustomerSelect :selected="customer" @answer="setCustomer" v-validate:[form.errors]="'customer_id'"></CustomerSelect>
                                            <error field="customer_id" :bag="form.errors"></error>
                                        </div>

                                        <div class="form-group">
                                            <label class="form-label">Subklant</label>
                                            <CustomerSelect :selected="sub_customer" @answer="setSubCustomer" v-validate:[form.errors]="'sub_customer_id'"></CustomerSelect>
                                            <error field="sub_customer_id" :bag="form.errors"></error>
                                        </div>

                                        <div class="form-group">
                                            <label class="form-label">Project manager</label>
                                            <UserSelect :role-id="3" :selected="form.project_manager_id" @answer="setProjectManager" v-validate:[form.errors]="'project_manager_id'"></UserSelect>
                                            <error field="project_manager_id" :bag="form.errors"></error>
                                        </div>

                                        <div class="form-group">
                                            <label class="form-label">Account manager</label>
                                            <UserSelect :role-id="6" :selected="form.account_manager_id" @answer="setAccountManager" v-validate:[form.errors]="'account_manager_id'"></UserSelect>
                                            <error field="account_manager_id" :bag="form.errors"></error>
                                        </div>

                                        <div class="form-group">
                                            <label class="form-label">Status</label>
                                            <select class="form-select" v-model="form.status" v-validate:[form.errors]="'status'">
                                                <option value="active">Actief</option>
                                                <option value="done">Afgerond</option>
                                                <option value="invoiced">Gefactureerd</option>
                                                <option value="on_hold">On hold</option>
                                                <option value="inactive">Inactief</option>
                                                <option value="archived">Gearchiveerd</option>
                                            </select>
                                            <error field="status" :bag="form.errors"></error>
                                        </div>

                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" id="is_billable" v-model="form.is_billable" v-validate:[form.errors]="'is_billable'">
                                            <label class="form-check-label" for="is_billable">Facturabel</label>
                                        </div>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" id="is_continuous" v-model="form.is_continuous" v-validate:[form.errors]="'is_continuous'">
                                            <label class="form-check-label" for="is_continuous">Doorlopend</label>
                                        </div>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" id="is_active" v-model="form.is_active" v-validate:[form.errors]="'is_active'">
                                            <label class="form-check-label" for="is_active">Actief</label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import UserSelect from "../../components/UserSelect.vue";
import CustomerSelect from "../../components/CustomerSelect.vue";
import Project from "../../store/Models/Project";
import {Item} from "@vuex-orm/core";
import Customer from "../../store/Models/Customer";
import User from "../../store/Models/User";

export default defineComponent({
    components: {UserSelect, CustomerSelect},
    data() {
        return {
            form: {
                name: null,
                description: null,
                appointment_notes: null,
                customer_id: null,
                sub_customer_id: null,
                project_manager_id: null,
                account_manager_id: null,
                is_billable: true,
                is_continuous: true,
                is_active: true,
                status: 'active',
                document_url: null,
                ticket_url: null,
                website_url: null,
                repo_url: null,

                working: false,
                errors: null,
            } as any
        }
    },
    computed: {
        project(): Item<Project> {
            return Project.find(
                parseInt((this.$route.params.project as string))
            );
        },
        customer(): Item<Customer> {
            return Customer.find(this.form.customer_id)
        },
        sub_customer(): Item<Customer> {
            return Customer.find(this.form.sub_customer_id)
        }
    },
    watch: {
        project: {
            handler(project) {
                if (project === null) {
                    project = {};
                }

                for (const item in this.form) {
                    if (['errors', 'working'].includes(item)) {
                        continue;
                    }

                    this.form[item] = project[item] ?? '';
                }

                if (!window._.isNil(project.customer_id)) {
                    Customer.api().get('customers/'+project?.customer_id);
                }

                if (!window._.isNil(project.sub_customer_id)) {
                    Customer.api().get('customers/'+project?.sub_customer_id);
                }

                if (!window._.isNil(project.manager_id)) {
                    User.api().get('users/'+project?.manager_id);
                }
            },
            immediate: true,
        }
    },
    methods: {
        setCustomer(val: Customer) {
            this.form.customer_id = val?.id;
        },
        setSubCustomer(val: Customer) {
            this.form.sub_customer_id = val?.id;
        },
        setProjectManager(val: User) {
            this.form.project_manager_id = val?.id;
        },
        setAccountManager(val: User) {
            this.form.account_manager_id = val?.id;
        },
        save() {
            this.form.working = true;
            this.form.errors = null;

            Project.api().put('projects/'+this.$route.params.project, this.form)
                .then(response => {
                    this.$toast('success', 'Je wijzigingen zijn succesvol opgeslagen!');

                    this.$router.push({
                        name: 'projects.show',
                        params: {
                            project: response.response.data.data.id,
                        }
                    });
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.form.errors = e.response.data.errors;
                    }
                })
                .finally(() => {
                    this.form.working = false;
                })
        },
        deleteProject() {
            this.form.working = true;
            this.form.errors = null;

            Project.api().delete('projects/'+this.$route.params.project)
                .then(() => {
                    this.$toast('success', 'Het project is succesvol verwijderd.');

                    this.$router.push({
                        name: 'projects.index',
                    });
                })
                .finally(() => {
                    this.form.working = false;
                });
        }
    },
    created() {
        Project.api().get('projects/' + parseInt((this.$route.params.project as string)));
    }
});
</script>

<style scoped>

</style>
