<template>
    <div>
        <div class="breadcrumbs d-flex align-items-center">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">SSO</li>
                </ol>
            </nav>
        </div>

        <div class="row">
            <div :class="{'col-12': selectedSite === null, 'col-8': selectedSite !== null}">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h5>Sites</h5>

                        <div class="ms-auto">
                            <button class="btn btn-primary" @click="openCreateSiteModal()">
                                Nieuwe site
                            </button>
                        </div>
                    </div>
                    <div v-if="sitePaginator.result !== null" class="card-body">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Naam</th>
                                    <th>URL</th>
                                    <th>Status</th>
                                    <th style="width: 1px"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="site of sitePaginator.result.data" :key="site.id">
                                    <td>{{ site.name }}</td>
                                    <td>
                                        <a :href="site.url" target="_blank">{{ site.url }}</a>
                                    </td>
                                    <td>
                                        <StatusLabel :status="site.type"></StatusLabel>
                                    </td>
                                    <td class="d-flex">
                                        <button class="btn btn-danger" @click="deleteSite(site.id)">
                                            <icon icon="trash"></icon>
                                        </button>
                                        <button class="btn btn-primary ms-2" @click="selectedSite = site">
                                            <icon icon="arrow-up-right-from-square"></icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <Pagination :data="sitePaginator.result" @pagination-change-page="fetchSites" :limit="2" align="center" class="mt-3" />
                    </div>
                </div>
            </div>
            <div v-if="selectedSite !== null" class="col-4">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h5>{{ selectedSite.name }}</h5>

                        <button class="btn btn-primary ms-auto" @click="selectedSite = null">
                            <icon icon="times"></icon>
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <label for="name">Naam</label>
                            <input id="name" class="form-control" v-model="editSiteForm.name" />
                            <error :bag="editSiteForm.errors" field="name"></error>
                        </div>

                        <div class="form-group">
                            <label for="url">URL</label>
                            <input id="url" class="form-control" type="url" v-model="editSiteForm.url" />
                            <error :bag="editSiteForm.errors" field="url"></error>
                        </div>

                        <div class="form-group">
                            <label for="status">Status</label>
                            <select id="status" class="form-select" v-model="editSiteForm.type">
                                <option value="active">Actief</option>
                                <option value="inactive">Inactief</option>
                            </select>
                            <error :bag="editSiteForm.errors" field="type"></error>
                        </div>

                        <button class="btn btn-primary w-100" @click="updateSite()" :disabled="editSiteForm.working">Opslaan</button>
                    </div>

                    <hr />

                    <div class="card-body">
                        <div class="d-flex">
                            <input class="form-control" v-model="createTokenForm.name" />

                            <button class="btn btn-primary" @click="storeToken()" :disabled="createTokenForm.working">
                                <icon icon="plus"></icon>
                            </button>
                        </div>

                        <hr />

                        <table>
                            <tr v-for="token of selectedSite.tokens" :key="token.id">
                                <td>
                                    {{ token.name }}
                                </td>
                                <td style="width: 1px">
                                    <button class="btn btn-danger ms-2" @click="deleteToken(token.id)">
                                        <icon icon="trash"></icon>
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="createSiteModal" tabindex="-1" role="dialog" aria-labelledby="createSiteLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="createSiteLabel">Nieuwe site</h5>
                        <button type="button" class="btn btn-close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="name">Naam</label>
                            <input id="name" class="form-control" v-model="createSiteForm.name" />
                            <error :bag="createSiteForm.errors" field="name"></error>
                        </div>

                        <div class="form-group">
                            <label for="url">URL</label>
                            <input id="url" class="form-control" type="url" v-model="createSiteForm.url" />
                            <error :bag="createSiteForm.errors" field="url"></error>
                        </div>

                        <div class="form-group">
                            <label for="status">Status</label>
                            <select id="status" class="form-select" v-model="createSiteForm.type">
                                <option value="active">Actief</option>
                                <option value="inactive">Inactief</option>
                            </select>
                            <error :bag="createSiteForm.errors" field="type"></error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" :disabled="createSiteForm.working">Sluiten</button>
                        <button type="button" class="btn btn-primary" @click="storeSite" :disabled="createSiteForm.working">Opslaan</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {onMounted, reactive, ref, watch} from "vue";
import Site from "../../store/Models/Site";
import Pagination from 'laravel-vue-pagination';
import StatusLabel from "../../components/StatusLabel.vue";
import {findIndex} from "lodash";

let modal = null as any;

onMounted(() => {
    modal = new window.bootstrap.Modal(document.getElementById('createSiteModal'));
});

const selectedSite = ref<Site | null>(null);

const sitePaginator = reactive({
    result: null as any,
});

const createSiteForm = reactive({
    name: null,
    url: null,
    type: 'active',

    working: false,
    errors: null,
});

const editSiteForm = reactive({
    name: null,
    url: null,
    type: null,

    working: false,
    errors: null,
}) as any;

const createTokenForm = reactive({
    name: null,

    working: false,
    errors: null,
}) as any;

watch(selectedSite, (site: Site | null) => {
    createTokenForm.name = null;
    createTokenForm.working = false;
    createTokenForm.errors = null;

    if (site === null) {
        editSiteForm.name = null;
        editSiteForm.url = null;
        editSiteForm.type = null;
        editSiteForm.working = false;
        editSiteForm.errors = null;
        return;
    }

    editSiteForm.name = site.name;
    editSiteForm.url = site.url;
    editSiteForm.type = site.type;
    editSiteForm.working = false;
    editSiteForm.errors = null;
});

const openCreateSiteModal = () => {
    modal.show();
}

const fetchSites = (page: number = 1) => {
    Site.api().get('sites', {
        params: {
            'page[number]': page,
            include: 'tokens'
        }
    })
        .then(result => {
            sitePaginator.result = result.response.data;
        });
}

const storeSite = () => {
    createSiteForm.errors = null;
    createSiteForm.working = true;

    Site.api().post('sites', createSiteForm)
        .then(result => {
           sitePaginator.result.data.push(result.response.data.data);

            modal.hide();

            createSiteForm.name = null;
            createSiteForm.url = null;
            createSiteForm.type = 'active';
        })
        .catch(e => {
            if (e.response.status === 422) {
                createSiteForm.errors = e.response.data.errors;
            }
        })
        .finally(() => {
            createSiteForm.working = false;
        });
}

const storeToken = () => {
    if (selectedSite.value === null) {
        return;
    }

    createTokenForm.errors = null;
    createTokenForm.working = true;

    window.api.post('sites/' + selectedSite.value.id + '/tokens', createTokenForm)
        .then(response => {
            if (selectedSite.value === null) {
                return;
            }

            selectedSite.value.tokens.unshift(response.data.data);
        })
        .catch(e => {
            if (e.response.status === 422) {
                createTokenForm.errors = e.response.data.errors;
            }
        })
        .finally(() => {
            createTokenForm.working = false;
        })
}

const updateSite = () => {
    if (selectedSite.value === null) {
        return;
    }

    editSiteForm.errors = null;
    editSiteForm.working = true;

    Site.api().put('sites/' + selectedSite.value.id, editSiteForm)
        .then(result => {
            if (selectedSite.value === null) {
                return;
            }

            sitePaginator.result.data.splice(
                findIndex(sitePaginator.result.data, ['id', selectedSite.value.id]),
                1,
                result.response.data.data
            );

            selectedSite.value = {
                ...selectedSite.value,
                ...result.response.data.data
            };
        })
        .catch(e => {
            if (e.response.status === 422) {
                editSiteForm.errors = e.response.data.errors;
            }
        })
        .finally(() => {
            editSiteForm.working = false;
        });
}

const deleteSite = (id: number) => {
    selectedSite.value = null;

    Site.api().delete('sites/' + id, {
        delete: id,
    })
        .then(() => {
            fetchSites(sitePaginator.result.meta.current_page);
        });
}

const deleteToken = (id: number) => {
    if (selectedSite.value === null) {
        return;
    }

    window.api.delete('tokens/' + id)
        .then(() => {
            if (selectedSite.value === null) {
                return;
            }

            selectedSite.value.tokens.splice(
                findIndex(selectedSite.value.tokens, ['id', id]),
                1,
            );
        });
}

fetchSites(1);
</script>

<style scoped>

</style>
