import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";
import Budget from "./Budget";
import User from "./User";
import Team from "./Team";

export default class Deadline extends Model {
    static entity = 'deadlines';

    static fields() {
        return {
            id: this.attr(null),
            budget_id: this.attr(null),
            user_id: this.attr(null),
            team_id: this.attr(null),
            date: this.string(''),
            workable_minutes: this.number(0),
            description: this.string(''),
            note: this.attr(null),
            is_finished: this.boolean(0),

            scrum_status: this.string('new'),
            scrum_position: this.number(1),
            scrum_is_hidden: this.boolean(0),

            created_at: this.string(false),
            updated_at: this.string(false),

            budget: this.belongsTo(Budget, 'budget_id'),
            user: this.belongsTo(User, 'user_id'),
            team: this.belongsTo(Team, 'team_id'),
        }
    }

    id!: number;
    budget_id!: number;
    user_id!: number;
    team_id!: number;
    date!: string;
    workable_minutes!: number;
    description!: string;
    note!: string;
    is_finished!: boolean;
    scrum_status!: string;
    scrum_position!: number;
    scrum_is_hidden!: boolean;
    created_at!: string;
    updated_at!: string;

    budget!: Budget;
    user!: User;
    team!: Team;

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'description',
    }
}
