<template>
    <div>
        <table class="table table-sm table-hover">
            <thead>
            <tr>
                <th>Datum</th>
                <th>Tijd</th>
                <th>Gebruiker</th>
                <th>Omschrijving</th>
                <th>Deadline</th>
                <th>Budget</th>
                <th>Factuur</th>
                <th style="width: 85px"></th>
            </tr>
            </thead>
            <tbody v-if="times.result !== null">
            <tr v-for="(time, index) of times.result.data" :key="time" :class="((!isNil(times.result.data[index-1]) && $moment(times.result.data[index-1].date).format('M') != $moment(time.date).format('M')) ? 'table-break' : '')">
                <td class="text-nowrap">{{ $moment(time.date).format('DD-MM-yyyy') }}</td>
                <td>{{ $timeFormatHoursMinutes(time.minutes) }}</td>
                <td>
                    <div v-if="time.user" class="d-flex align-items-center">
                        <img :src="time.user.gravatar" class="gravatar-nano me-2" :style="'border: 1px solid ' + time.user.color">
                        {{ time.user.name }}
                    </div>
                </td>
                <td>{{ time.description }}  <span class="badge bg-secondary" v-if="time.is_not_billable">#niet-facturabel</span></td>
                <td>{{ time.deadline.description }}</td>
                <td>{{ time.budget.description }}</td>
                <td>
                    <span v-if="time.invoice">
                        #{{ time.invoice.id }}
                    </span>
                </td>
                <td class="text-nowrap" style="text-align: right">
                    <div v-if="$gate.allows('updateForOthers', 'time') || time.user_id === me.id">
                        <button class="btn btn-sm btn-primary me-2" type="button" @click="editTime(time.id)"><icon icon="pen-to-square"></icon></button>
                        <button class="btn btn-sm btn-danger" type="button" @click="deleteTime(time.id, index)"><icon icon="trash"></icon></button>
                    </div>
                </td>
                <EditTimeModal v-if="editTimeModalId === time.id" @saved="editedTime()" :time="time"></EditTimeModal>
            </tr>
            </tbody>
        </table>

        <Pagination v-if="times.result !== null" :data="times.result" @pagination-change-page="fetchTimes" :limit="2" align="center" class="mt-3" />
    </div>
</template>

<script lang="ts" setup>
import Project from "../../store/Models/Project";
import {nextTick, reactive, ref, watch} from "vue";
import Time from "../../store/Models/Time";
import Pagination from 'laravel-vue-pagination';
import EditTimeModal from "../Dashboard/EditTimeModal.vue";
import User from "../../store/Models/User";
import {Modal} from "bootstrap";
import {isNil} from "lodash";

const times = reactive({
    result: null as any,
});

const editTimeModalId = ref<number>();
const editTimeModal = ref<Modal>();
const me = ref<User>(window.me);

const props = defineProps<{
    project: Project,
}>();

const fetchTimes = (page: number = 1) => {
    if (times.result !== null && page === times.result.meta.current_page) {
        return;
    }

    Time.api().get('projects/' + props.project.id + '/times', {
        params: {
            'page[number]': page,
            include: 'budget,deadline,user,invoice',
        }
    })
        .then(result => {
            times.result = result.response.data;
        });
}

const editTime = (id: number) => {
    editTimeModalId.value = id;

    nextTick(() => {
        editTimeModal.value = new window.bootstrap.Modal(document.getElementById('editTime_' + id));
        editTimeModal.value.show();
    });
}

const editedTime = () => {
    editTimeModal.value.hide();

    editTimeModalId.value = 0;

    times.result = null;
    fetchTimes();
}

const deleteTime = (id: number, index: number) => {
    Time.api().delete('times/' + id)
        .then(() => {
            times.result.data.splice(index, 1)
        })
}

watch(() => props.project.id, () => {
    fetchTimes(1);
}, {immediate: true});

</script>

<style scoped>

</style>
