<template>
    <div>
        <div class="card customer-projects">
            <FurloughNavigation />
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Gebruiker</th>
                            <th>Omschrijving</th>
                            <th>Start</th>
                            <th>Einde</th>
                            <th>Tijd</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="request of requests" :key="request.id">
                        <td>
                            <div v-if="request.user" class="d-flex align-self-center">
                                <img :src="request.user.gravatar" :style="'border: 1px solid' + request.user.color" class="gravatar-mini me-3" />
                                <span>{{ request.user.name }}</span>
                            </div>
                            <span v-else>Geen gebruiker...</span>
                        </td>
                        <td>
                            {{ request.description }}
                        </td>
                        <td>
                            {{ $moment(request.start).format('DD-MM-YYYY HH:mm') }}
                        </td>
                        <td>
                            {{ $moment(request.end).format('DD-MM-YYYY HH:mm') }}
                        </td>
                        <td>
                            {{ $timeFormatHoursMinutes(request.minutes) }}
                        </td>
                        <td>
                            <div class="d-flex">
                                <button class="btn btn-danger btn-sm me-2" @click="deny(request.id)">
                                    <icon icon="times"></icon>
                                </button>
                                <button class="btn btn-success btn-sm" @click="accept(request.id)">
                                    <icon icon="check"></icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FurloughNavigation from "../../components/FurloughNavigation.vue";
import User from "../../store/Models/User";
import Furlough from "../../store/Models/Furlough";

export default defineComponent({
    components: {FurloughNavigation},
    computed: {
        users(): Array<User> {
            return User.query()
                .get();
        },
        requests(): Array<Furlough> {
            return Furlough.query()
                .with('user')
                .where('has_been_accepted', null)
                .get();
        }
    },
    methods: {
        accept(id: number) {
            Furlough.api().put('furloughs/'+id, {
                has_been_accepted: true,
            })
                .then(() => {
                    this.$toast('success', 'Verlof aanvraag succesvol goedgekeurd.');
                    this.$bus.emit('reloadFurloughBalance');
                });
        },
        deny(id: number) {
            Furlough.api().put('furloughs/'+id, {
                has_been_accepted: false,
            })
                .then(() => {
                    this.$toast('success', 'Verlof aanvraag succesvol afgekeurd.');
                });
        },
        fetchData() {
            User.api().get('users');
            Furlough.api().get('furloughs?include=user&filter[has_been_accepted]=');
        },
    },
    created() {
        this.fetchData();
    }
});
</script>

<style scoped>

</style>
