<template>
    <Multiselect
        v-model="user"
        trackBy="name"
        :searchable="true"
        :closeOnSelect="true"
        :object="!numericSelect"
        :minChars="2"
        placeholder="Selecteer gebruiker"
        label="name"
        valueProp="id"
        @change="setAnswer"
        :options="users"
        :canClear="canClear"
    >
        <template v-slot:singlelabel="{ value }">
            <div class="multiselect-single-label">
                <div class="multiselect-single-label-text">
                    <img :src="value.gravatar" :style="'border: 1px solid' + value.color " class="gravatar-nano me-2"> {{ value.name }}
                </div>
            </div>
        </template>

        <template v-slot:option="{ option }">
            <div class="mb-0">
                <img :src="option.gravatar" :style="'border: 1px solid' + option.color " class="gravatar-nano me-2"> {{ option.name }}
            </div>
        </template>
    </Multiselect>
</template>

<script lang="ts" setup>
import Multiselect from '@vueform/multiselect';
import User from "../store/Models/User";
import {computed, ref, watch} from "vue";
import Project from "../store/Models/Project";

const props = defineProps<{
    selected?: number | string | User,
    numericSelect?: boolean,
    canClear?: boolean,
    roleId?: number,
}>();

const emit = defineEmits<{
    (e: 'answer', value: string): void
}>();

const user = ref<User | null>(null);
const users = computed(() => {

    // get users based on role
    if (props.roleId) {
        return User.query().where(
            (user: { roles: any[]; }) => user.roles.some(role => role.id === props.roleId)
        ).orderBy('deferred').orderBy('name').get();
    }

    return User.query().orderBy('deferred').orderBy('name').get();
});

const selectUser = (selected, tries = 1) => {
    if (window.user_select_users_loaded !== true) {
        return setTimeout(() => {
            selectUser(selected, ++tries);
        }, 100 * tries);
    }

    if (typeof selected === 'number') {
        user.value = User.find(selected);
    } else {
        user.value = selected as unknown as User;
    }
}

const setAnswer = (value) => {
    emit('answer', value);
}

watch(() => props.selected, selected => {
    if (selected === null) {
        return;
    }

    selectUser(selected);
}, {immediate: true});

if (
    !window.hasOwnProperty('user_select_users_loading')
    && window.user_select_users_loading !== true
    && window.user_select_users_loaded !== true
) {
    window.user_select_users_loading = true;
    User.api().get('users?include=roles')
        .then(() => {
            window.user_select_users_loaded = true;
            window.user_select_users_loading = false;
        });
}
</script>
