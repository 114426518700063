import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";

export default class Site extends Model {
    static entity = 'sites';

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'description',
    }

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            url: this.string(''),
            type: this.string(''),

            tokens: this.attr([]),

            created_at: this.string(false),
            updated_at: this.string(false),
        }
    }

    declare id: number;
    declare name: string;
    declare url: string;
    declare type: string;

    declare tokens: any[];
    declare created_at: string;
    declare updated_at: string;
}
