<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <router-link class="breadcrumb-item" :to="{name: 'projects.index'}">Projecten</router-link>
                    <router-link v-if="project !== null" class="breadcrumb-item" aria-current="page" :to="{name: 'projects.show', params: {project: project.id}}">{{ project?.name }}</router-link>
                    <li class="breadcrumb-item active" aria-current="page">Nieuwe factuur</li>
                </ol>
            </nav>

            <div v-if="project !== null" id="buttons" class="ms-auto text-md-right align-self-center">
                <router-link :to="{name: 'projects.show'}" v-if="$gate.allows('view', project)" class="btn btn-primary" :disabled="form.working">
                    Terug
                    <icon class="ms-1" icon="times"></icon>
                </router-link>
                <button v-if="project.budgets.length > 0" class="btn btn-success" @click="saveInvoice" :disabled="form.working">
                    Save
                    <icon icon="floppy-disk" class="ms-1"></icon>
                </button>
            </div>
        </div>

        <div class="card">
            <div v-if="project?.budgets.length > 0" class="card-body">
                <div class="form-group">
                    <label for="description">Omschrijving</label>
                    <input class="form-control" id="description" v-model="form.description" />
                    <error field="description" :bag="form.errors"></error>
                </div>
                <div class="form-group">
                    <label for="date">Datum</label>
                    <input class="form-control" type="date" id="date" v-model="form.date" />
                    <error field="date" :bag="form.errors"></error>
                </div>
                <div class="form-group">
                    <label for="hourly_rate">Uurtarief</label>
                    <input class="form-control" type="number" id="hourly_rate" v-model="form.hourly_rate" />
                    <error field="hourly_rate" :bag="form.errors"></error>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="mark_project_as_invoiced" v-model="form.mark_project_as_invoiced" />
                    <label class="form-check-label" for="mark_project_as_invoiced">
                        Zet project op gefactureerd
                    </label>
                    <error field="mark_project_as_invoiced" :bag="form.errors"></error>
                </div>

                <div v-if="project.files.length > 0">
                    <hr />
                    <p class="fw-bold">Used files: </p>
                    <ul v-for="(file, index) of project.files" class="mb-0" :key="index">
                        <li>
                            {{ file.name }}
                        </li>
                    </ul>
                </div>
                <hr />

                <div class="d-flex">
                    <h5>Regels</h5>

                    <button class="btn btn-primary ms-auto" @click="addRow">Toevoegen</button>
                </div>

                <table v-if="project !== null" class="table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Budget</th>
                            <th data-bs-toggle="tooltip-worked-minutes" data-bs-placement="top" title="Dit zijn alleen de facturabele gewerkte uren.">Gewerkt</th>
                            <th>Omschrijving</th>
                            <th>Uren</th>
                            <th>Bedrag</th>
                            <th style="width: 1px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, index) of form.rows" :key="row.id">
                            <td>
                                <select
                                    v-model="row.type"
                                    class="form-select"
                                    @change="() => row.type === 'budget' ? row.description = null : row.budget_id = null">
                                    <option value="budget">Budget</option>
                                    <option value="manual">Handmatig</option>
                                </select>
                            </td>
                            <td>
                                <span v-if="row.type === 'budget' && row.budget_id !== null">
                                    <template v-if="getBudget(row).is_open">
                                        Open<br />
                                        <span @click="row.minutes = getBudget(row).budget_minutes" class="cursor-pointer">({{ $timeFormatHoursMinutes(getBudget(row).budget_minutes) }})</span>
                                    </template>
                                    <template v-else>{{ $timeFormatHoursMinutes(getBudget(row).budget_minutes) }}</template>
                                </span>
                            </td>
                            <td>
                                <span v-if="row.type === 'budget' && row.budget_id !== null">
                                    <span @click="rowTimeChanged(row, row.minutes = getBudget(row).billable_worked_minutes)" class="cursor-pointer">{{ $timeFormatHoursMinutes(getBudget(row).billable_worked_minutes) }}</span>
                                    <span v-if="getBudget(row).invoiced_minutes > 0"><br />Al gefactureerd: {{ $timeFormatHoursMinutes(getBudget(row).invoiced_minutes) }}</span>
                                </span>
                            </td>
                            <td>
                                <template v-if="row.type === 'budget'">
                                    <select v-model="row.budget_id" class="form-select" @change="budgetSelected(index)">
                                        <option v-for="budget of project.budgets" :key="budget.id" :value="budget.id">
                                            {{ budget.description }}
                                        </option>
                                    </select>
                                    <error :field="'rows.'+index+'.budget_id'" :bag="form.errors"></error>
                                </template>
                                <template v-else>
                                    <input class="form-control" v-model="row.description" />
                                    <error :field="'rows.'+index+'.description'" :bag="form.errors"></error>
                                </template>
                            </td>
                            <td>
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <TimeInput v-model="row.minutes"
                                                   :additional="index"
                                                   :nullable="true"
                                                   @answer="(i, val) => rowTimeChanged(row, val)"
                                                   v-validate:[form.errors]="'budgets.'+index+'.minutes'" />
                                        <error :field="'rows.'+index+'.minutes'" :bag="form.errors"></error>
                                    </div>
                                    <div v-if="row.type === 'budget' && row.budget_id !== null && !getBudget(row).is_open" class="ms-auto flex-shrink-1" data-bs-toggle="tooltip" data-bs-placement="top" title="De gewerkte uren verschillen ten opzichte van de budget uren.">
                                        <icon class="ms-1 text-warning ms-2" icon="triangle-exclamation"></icon>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <input v-model.lazy="row.amount"
                                       class="form-control"
                                       v-money="$moneyConfig"
                                       v-validate:[form.errors]="'rows.'+index+'.amount'">
                                <error :field="'rows.'+index+'.amount'" :bag="form.errors"></error>
                            </td>
                            <td>
                                <button v-if="!form.mark_project_as_invoiced || row.type === 'manual'" class="btn btn-danger" @click="form.rows.splice(index, 1)">
                                    <icon icon="trash"></icon>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <strong>Totaal</strong>
                            </td>
                            <td>{{ $timeFormatHoursMinutes(totalHours) }}</td>
                            <td>{{ totalAmount }}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else class="card-body">
                <p class="text-center">Je kunt geen factuur maken voor een project zonder budgetten.</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import Project from "../../../store/Models/Project";
import {computed, reactive, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {Item} from "@vuex-orm/core";
import Error from "../../../plugins/validation/Error.vue";
import TimeInput from "../../../components/TimeInput.vue";
import Budget from "../../../store/Models/Budget";
import Invoice from "../../../store/Models/Invoice";
import {cloneDeep, sumBy} from "lodash";
import moment from "moment";

const route = useRoute();
const router = useRouter();

const project = computed<Item<Project> | null>(() => {
    return Project.query()
        .with('budgets')
        .with('files')
        .find(route.params.project)
});

const totalAmount = computed(() => {
    return sumBy(form.rows, row => {
        const amount = row.amount.toString();
        return parseFloat(amount.replace('.', '').replace(',', '.'));
    }).toFixed(2);
});

const totalHours = computed(() => {
    return sumBy(form.rows, 'minutes');
});

const form = reactive({
    description: null,
    date: moment().format('Y-MM-DD'),
    hourly_rate: 105,
    mark_project_as_invoiced: true,

    rows: [] as any[],

    working: false,
    errors: null,
});

watch(() => form.rows, () => {
    document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(tooltipTriggerElement => {
        window.bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerElement);
    });
}, {immediate: true, deep: true});

watch(() => form.hourly_rate, () => {
    for (let i = 0; i < form.rows.length; i++) {
        budgetSelected(i);
    }
});

const addRow = () => {
    form.rows.push({
        budget_id: null,
        description: null,
        amount: 0.00,
        minutes: 0,

        type: 'manual',
    });
}

const rowTimeChanged = (row, value) => {
    row.minutes = value;
    row.amount = value > 0 ? ((value / 60) * form.hourly_rate).toFixed(2) : 0.00
}

const getBudget = (row: any) => {
    return Budget.find(row.budget_id);
}

const budgetSelected = (index: string | number | symbol) => {
    if (form.rows[index].budget_id == null) {
        return;
    }

    const budget = Budget.find(form.rows[index].budget_id);

    if (budget === null) {
        return;
    }

    const workedOrBudgetMinutes = (budget.is_open ? budget.billable_worked_minutes : budget.budget_minutes) ?? 0;

    const minutesToInvoice = workedOrBudgetMinutes - (budget.invoiced_minutes ?? 0);

    form.rows[index].amount = minutesToInvoice > 0 ? ((minutesToInvoice / 60) * form.hourly_rate).toFixed(2) : 0.00;
    form.rows[index].minutes = minutesToInvoice;
}

const saveInvoice = () => {
    form.errors = null;
    form.working = true;

    Invoice.api().post('projects/' + route.params.project + '/invoices', {
        ...form,
        ...{
            rows: form.rows.map((row: any) => {
                row = cloneDeep(row);

                row.amount = row.amount.replace('.', '').replace(',', '.');
                row.amount = parseFloat(row.amount.replace(',', '.'));

                return row;
            }),
        }
    })
        .then(() => {
            router.push({
                name: 'projects.show',
                params: {
                    project: route.params.project,
                }
            });
        })
        .catch(e => {
            console.error(e);
            if (e.response.status === 422) {
                form.errors = e.response.data.errors;
            }
        })
        .finally(() => {
            form.working = false;
        })
}

Project.api().get('projects/' + route.params.project, {
    params: {
        include: 'budgets'
    }
})
    .then(result => {
        form.description = result.response.data.data.name;
        form.mark_project_as_invoiced = !result.response.data.data.is_continuous;

        for (const budget of result.response.data.data.budgets) {
            form.rows.push({
                budget_id: budget.id,
                description: budget.description,
                amount: 0.00,
                minutes: 0,

                type: 'budget',
            });

            budgetSelected(form.rows.length - 1);
        }
    });

document.querySelectorAll('[data-bs-toggle="tooltip-worked-minutes"]').forEach(tooltipTriggerElement => {
    window.bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerElement);
});
</script>

<style scoped>

</style>
