import axios from "axios";
import { loadProgressBar } from 'axios-progress-bar';

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Accept'] = 'application/json';

const source = axios.CancelToken.source();
window.api = axios.create({
    baseURL: window.global.api_url,
    cancelToken: source.token,
    withCredentials: true,
});

loadProgressBar({}, window.api);

// Intercept all delete requests and ask for confirmation
window.api.interceptors.request.use(async (config: any) => {
    if (config.method === 'delete') {
        const swalFire: any = {
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d95c5c',
            cancelButtonColor: '#868686',
            confirmButtonText: 'Yes, delete!',
        };

        let defaultText = swalFire.html = 'Je kunt dit niet terug draaien.';
        if (config.hasOwnProperty('delete') && config.hasOwnProperty('model')) {
            if (window.store.getters.hasOwnProperty('entities/'+config.model+'/find')) {
                const entity = window.store.getters['entities/'+config.model+'/find'](config.delete);
                if (entity !== null && config.hasOwnProperty('name_prop') && entity.hasOwnProperty(config.name_prop)) {
                    let deleteAttribute = config.hasOwnProperty('translate') && config.translate ? entity[config.name_prop] : entity[config.name_prop];
                    if (deleteAttribute === null || deleteAttribute === '') {
                        const singularModelName = window.pluralize.singular(config.model);
                        deleteAttribute = window.ucFirst(singularModelName);
                    }

                    defaultText = deleteAttribute + ' zal verwijderd worden';
                }
            }
        }

        swalFire.html = defaultText;

        const result = await window.Swal.fire(swalFire);

        if (result.isConfirmed) {
            return config;
        } else {
            throw new axios.Cancel('Delete confirmation failed.');
        }
    } else {
        return config;
    }
});

// Intercept all responses. 2xx, 4xx responses will just return the same response. 5xx responses will show error popup
window.api.interceptors.response.use(response => response, error => {
    // Drop cancel request silently
    if (axios.isCancel(error)) {
        return new Promise(() => {});
    }

    if (error.response) {
        if (error.response.status >= 500) {
            window.Swal.fire('Server Error', error.response.data.message ?? 'Er is een onbekende server fout opgestreden. Probeer het later opnieuw.', 'error');
            return Promise.reject(error);
        } else if (error.response.status >= 400 && error.response.status <= 499 && ![422, 401, 429].includes(error.response.status)) {
            window.Swal.fire('Client Error', error.response.data.message ?? 'Er is een onbekende fout opgestreden. Probeer het later opnieuw.', 'error');
            return Promise.reject(error);
        } else if (error.response.status === 429) {
            window.Swal.fire('Too Many Attempts', 'Please wait before retrying.', 'error');
        }
    } else if (error.request) {
        window.Swal.fire('No Response', error.message ?? 'We were unable to get a response from this request. Please try again later', 'error');
    } else {
        window.Swal.fire('Request Error', error.message ?? 'Something went wrong while setting up this request. Please try again later', 'error');
    }

    return Promise.reject(error);
});
