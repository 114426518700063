<template>
    <span class="badge rounded-pill" :class="'bg-'+background">{{ label }}</span>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    props: {
        status: {
            type: String,
            default: 'default'
        }
    },
    computed: {
        background() {
            switch (this.status) {
                case 'active':
                case 'done':
                case 'approved':
                    return 'success';
                case 'invoiced':
                    return 'warning';
                case 'on_hold':
                case 'archived':
                    return 'default';
                case 'denied':
                case 'inactive':
                case 'blocked':
                    return 'danger';
                case 'pending':
                default:
                    return 'info';
            }
        },
        label() {
            switch (this.status) {
                case 'active':
                    return 'Actief';
                case 'approved':
                    return 'Goedgekeurd';
                case 'inactive':
                    return 'Inactief';
                case 'done':
                    return 'Afgerond';
                case 'invoiced':
                    return 'Gefactureerd';
                case 'on_hold':
                    return 'On hold';
                case 'archived':
                    return 'Archief';
                case 'denied':
                    return 'Afgekeurd';
                case 'blocked':
                    return 'Geblokkeerd';
                case 'pending':
                default:
                    return 'In afwachting';
            }
        }
    }
});
</script>

<style scoped>

</style>
