<template>
    <div>
        <div class="card customer-projects">
            <FurloughNavigation />
            <div class="card-body">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>Gebruiker</th>
                            <th v-for="year of years" :key="year">{{ year }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user of users" :key="user.id">
                            <td class="d-flex py-3">
                                <img :src="user.gravatar" class="gravatar-nano me-2" :style="'border: 1px solid ' + user.color">
                                {{ user.name }}
                            </td>
                            <td v-for="year of years" :key="year">
                                <div class="d-flex">
                                    <TimeInput :disabled="true" v-if="form.users[user.id]" v-model="form.users[user.id][year]" @answer="(additional, value) => form.users[user.id][year] = value" />
                                    <button class="btn btn-primary ms-2" @click="modifyBalance(user.id, year)">
                                        <icon icon="pen-to-square"></icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <ModifyBalanceModal :user_id="is_modifying.user_id" :year="is_modifying.year" @saved="modified"></ModifyBalanceModal>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FurloughNavigation from "../../components/FurloughNavigation.vue";
import User from "../../store/Models/User";
import TimeInput from "../../components/TimeInput.vue";
import FurloughBalance from "../../store/Models/FurloughBalance";
import ModifyBalanceModal from "../../components/Dialogs/ModifyBalanceModal.vue";

export default defineComponent({
    components: {ModifyBalanceModal, TimeInput, FurloughNavigation},
    data() {
        return {
            form: {
                users: [] as any,

                working: false,
                errors: null,
            },

            is_modifying: {
                user_id: null as number|null,
                year: null as number|null,
            },

            modal: null as any,
            modalCanBeShown: true,

            years: [] as Array<Number>,
        }
    },
    watch: {
        users(users) {
            for (const user of users) {
                 const userData: any = {};

                for (const year of this.years) {
                    const balance = window._.first(user.furlough_balances.filter((b: FurloughBalance) => b.year == year));

                    if (window._.isNil(balance)) {
                        // @ts-ignore
                        userData[year] = 0;
                    } else {
                        // @ts-ignore
                        userData[year] = balance.minutes;
                    }
                }

                this.form.users[user.id] = userData;
            }
        }
    },
    computed: {
        users(): Array<User> {
            return User.query()
                .with('furlough_balances')
                .get();
        }
    },
    methods: {
        modifyBalance(id: number, year: string|number) {
            this.$bus.emit('clearBalanceMutationForm')

            this.is_modifying = {
                user_id: id,
                year: parseInt(year),
            };

            if (this.modal === null) {
                this.modal = new window.bootstrap.Modal(document.getElementById('mutateBalance'));
            }

            this.modal.show();
        },
        modified() {
            if (this.modal === null) {
                this.modal = new window.bootstrap.Modal(document.getElementById('mutateBalance'));
            }

            this.modal.hide();

            this.$nextTick(() => {
                this.is_modifying = {
                    user_id: null,
                    year: null,
                };
            });

            this.fetchData();
            this.$bus.emit('reloadFurloughBalance');
        },
        fetchData() {
            User.api().get('users?include=furloughBalances');
        },
    },
    created() {
        this.fetchData();

        const startYear = parseInt(this.$moment().subtract(1, 'year').format('Y'));
        for (let i = 0; i < 3; i++) {
            this.years.push(startYear + i);
        }
    }
});
</script>

<style scoped>

</style>
