import {createStore, Store} from 'vuex';
import VuexORM from '@vuex-orm/core';
import database from "./database";
import VuexORMAxios from "@vuex-orm/plugin-axios";

VuexORM.use(VuexORMAxios, {
    axios: window.api
});

const store: Store<any> = window.store = createStore({
    plugins: [
        VuexORM.install(database),
    ]
});

export default store;
