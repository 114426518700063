import {ToastType} from "../../typings/ToastType";

const toast = (type: ToastType, message: string, time: number = 10) => {
    const toastBase = document.getElementById('toast-base');
    const toastHolder = document.getElementById('toast-holder');

    if (toastBase === null) {
        throw 'There is no toast base defined.';
    }

    if (toastHolder === null) {
        throw 'There is no toast holder defined';
    }

    const toastElement = toastBase.cloneNode(true);
    (toastElement as HTMLElement).removeAttribute('id');
    toastHolder.appendChild(toastElement);

    const toast = window.bootstrap.Toast.getOrCreateInstance(toastElement);

    toast._element.querySelector('.toast-body').innerHTML = message;
    toast._element.classList.add('bg-' + type);

    toast.show();

    setTimeout(() => {
        toast.hide();

        // Delay removing node from DOM to make sure animation is finished
        setTimeout(() => {
            (toastElement as HTMLElement).remove();
        }, 5000)
    }, time * 1000)
}

export default {
    install: (app: any) => {
        app.config.globalProperties.$toast = toast;
    }
}

export {
    toast,
}
