<template>
    <div>
        <div class="p-3 bg-secondary bg-opacity-10" v-bind="getRootProps()">
            <input v-bind="getInputProps()" />
            <span v-if="!loading">Sleep de bestanden hierin of klik hier om bestanden te selecteren <icon class="ms-1" icon="file-arrow-up"></icon></span>
            <span v-else>Loading...</span>
        </div>
    </div>
</template>

<script setup>
import {useDropzone} from "vue3-dropzone";
import ProjectFile from "../../store/Models/ProjectFile";
import {ref} from "vue";
import {toast} from "../../plugins/toast/toast";

const props = defineProps(['project'])
const loading = ref(false);

const onDrop = files => {
    loading.value = true;
    const formData = new FormData();

    const fileHandlers = files.map(file => {
        return new Promise(resolve => {
            // Skip file if not an image
            if (!file.type.startsWith('image')) {
                toast('danger', "Je mag alleen correcte foto's plaatsen");

                return resolve();
            }

            const reader = new FileReader();

            reader.onload = event => {
                const img = document.createElement('img');

                img.onload = () => {
                    const canvas = document.createElement('canvas');

                    const aspectRatio = img.height / 60;
                    let width = img.width / aspectRatio;
                    const height = 60;

                    if (width > 600) {
                        width = 600
                    }

                    // resize the canvas and draw the image data into it
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext("2d");

                    ctx.drawImage(img, 0, 0, width, height);

                    canvas.toBlob(blob => {
                        formData.append("images[]", new File([blob], file.name, {type: 'image/png'}));
                        resolve();
                    });
                }

                img.src = event.target.result;
            }

            reader.readAsDataURL(file);
        });
    });

    Promise.all(fileHandlers)
        .then(() => {
            if (formData.getAll("images[]").length === 0) {
                loading.value = false;
            } else {
                ProjectFile.api().post('projects/' + props.project + '/files', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                    .then(result => {
                        loading.value = false;

                        for (const item of result.response.data.data) {
                            if (item.status === 'duplicate') {
                                toast('danger', item.name + ' is al een keer geupload');
                            }
                        }
                    })
                    .catch((err) => {
                        console.warn(err)
                    });
            }
        });
};

const { getRootProps, getInputProps } = useDropzone({ onDrop });
</script>
