<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">Klanten</li>
                </ol>
            </nav>

            <div id="buttons" class="ms-auto text-md-right align-self-center">
                <router-link :to="{name: 'customers.create'}" v-if="$gate.allows('create', 'customer')" class="btn btn-sm btn-primary">
                    Nieuwe klant
                    <icon class="ms-1" icon="plus"></icon>
                </router-link>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <ul class="nav nav-tabs card-header-tabs">
                    <li class="nav-item"><span class="nav-link active">Klanten</span></li>
                </ul>
            </div>

            <div class="card-body">
                <form @click.prevent="fetchCustomers(1)">
                    <div class="input-group">
                        <input ref="search" type="search" required name="customer" class="form-control" placeholder="Zoek..." v-model="search">
                        <div class="input-group-append">
                            <button class="btn btn-primary" type="submit"  :disabled="loading">
                                Zoeken
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-body">
                <table class="table table-sm table-hover table-align-middle mb-0">
                    <thead>
                    <tr>
                        <th scope="col" style="padding-right: 25px;">#</th>
                        <th scope="col">Naam</th>
                        <th width="120" scope="col">Code</th>
                        <th scope="col">Contactpersoon</th>
                        <th scope="col">E-mailadres</th>
                        <th scope="col">Klant sinds</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="customerData?.data?.length > 0">
                        <tr v-for="customer of customerData.data" :key="customer.id">
                            <td>{{ customer.id }}</td>
                            <td>
                                <span class="customer-border" :style="'background-color: '+customer.color">&nbsp;</span>&nbsp;
                                <router-link v-if="$gate.allows('view', customer)" :to="{name: 'customers.show', params: {customer: customer.id}}" v-html="customer.name"></router-link>
                                <span v-else>
                                    <span v-html="customer.name"></span>
                                </span>
                            </td>
                            <td>[{{customer.code}}]</td>
                            <td>{{ customer.contact }}</td>
                            <td><a :href="'mailto:'+customer.email">{{ customer.email }}</a></td>
                            <td>
                                <span v-if="customer.customer_since">
                                    {{ $moment(customer.customer_since).format('dddd D MMMM')}}
                                </span>
                            </td>
                        </tr>
                    </template>
                    <tr v-else>
                        <td colspan="10">
                            Er zijn nog geen projecten beschikbaar.
                        </td>
                    </tr>
                    </tbody>
                </table>

                <Pagination :data="customerData" @pagination-change-page="fetchCustomers" :limit="2" align="center" class="mt-3" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Customer from "../../store/Models/Customer";
import Pagination from 'laravel-vue-pagination';
import { CustomerData } from '../../typings/CustomerData';

export default defineComponent({
    components: {Pagination},
    data() {
        return {
            search: null,
            loading: false,

            customerData: [] as CustomerData | never[],

            page: 0,
        }
    },
    methods: {
        fetchCustomers(page: Number|null = null) {
            this.loading = true;

            page = page ?? this.page + 1;

            let url = 'customers?page[number]='+page;

            if (this.search !== null && this.search !== '') {
                url += '&filter[search]=' + this.search;
            }

            Customer.api().get(url).then(response => {
                let data = response.response.data;

                data.data = data.data.map((item: any) => {
                    return Customer.find(item.id);
                });

                this.customerData = data;
                this.loading = false;
            });
        }
    },
    created() {
        this.fetchCustomers();
    },
    mounted() {
        // set autofocus
        this.$refs.search.focus();
    }
});
</script>

<style scoped>
</style>
