<template>
    <div v-if="rendered" style="height: 100vh">
        <Navigation :me="me" :load="load"></Navigation>

        <main class="container">
            <router-view v-if="gateRendered"></router-view>
        </main>


        <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11" id="toast-holder">
            <div class="toast align-items-center text-white border-0 my-2" role="alert" aria-live="assertive" aria-atomic="true" id="toast-base" data-bs-autohide="false">
                <div class="d-flex">
                    <div class="toast-body"></div>
                    <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Navigation from "./Navigation.vue";
import User from "../store/Models/User";

export default defineComponent({
    data() {
        return {
            rendered: true,
            load: false,

            gateRendered: false,

            loadMe: true,
        }
    },
    components: {
        Navigation,
    },
    computed: {
        me(): User {
            if (this.loadMe) {
                this.loadMe = !this.loadMe;
            }

            if (window._.isNil(window.me)) {
                return this.$me();
            }

            // @ts-ignore
            return User.find(window.me.id);
        },
    },
    watch: {
        '$route.path': {
            handler(value) {
                if (value.startsWith('/auth/')) {
                    this.gateRendered = true;
                }
            },
            immediate: true,
        }
    },
    methods: {
        logout() {
            this.$logout();
        },
        rerender(): void {
            this.rendered = false;
            this.$nextTick(() => {
                this.rendered = true;
            });
        },
    },
    created() {
        this.$bus.on('login', () => {
            this.load = true;
            this.loadMe = !this.loadMe;
        });

        this.$bus.on('reloadMe', () => {
            this.loadMe = !this.loadMe;
        });

        this.$bus.on('gateLoaded', () => {
            setTimeout(() => {
                this.gateRendered = true;
            }, 1000)
        });

        window.rerenderVue = this.rerender;

        this.$checkForCheckin();
    }
});
</script>

<style scoped>

</style>
