import Error from "./Error.vue";

export default {
    install: (app: any) => {
        app.config.globalProperties.$validate = (field: any, bag: any): string | null | undefined => {
            if (typeof field === 'string' && field.includes(',')) {
                const fields = field.split(',');
                for (const fieldItem of fields) {
                    if (typeof bag === 'object' && bag !== null && bag.hasOwnProperty(fieldItem)) {
                        return window._.first(bag[fieldItem]);
                    }
                }
            } else {
                if (typeof bag === 'object' && bag !== null && bag.hasOwnProperty(field)) {
                    return window._.first(bag[field]);
                }
            }

            return null;
        };

        function toggleInvalidClass(el: Element, binding: any) {
            const error = app.config.globalProperties.$validate(binding.value, binding.arg);
            const hasInvalidClass = el.classList.contains('is-invalid');

            if (error !== null && !hasInvalidClass) {
                el.classList.add('is-invalid');
            } else if (error === null && hasInvalidClass) {
                el.classList.remove('is-invalid');
            }
        }

        app.directive('validate', {
            mounted(el: Element, binding: object) {
                toggleInvalidClass(el, binding);
            },
            updated(el: Element, binding: object) {
                toggleInvalidClass(el, binding);
            }
        });

        app.component('error', Error);
    }
}
