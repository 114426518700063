<template>
    <div class="card-body" style="background: #efefef; margin: -1px; padding: 0;">
        <div class="row">
            <div class="col-lg-5 col-md-8 mx-md-auto">
                <div v-if="me !== null" class="card">
                    <div class="card-header">
                        <strong>Hey {{ me.name }}! 👋</strong>
                    </div>
                    <div class="card-body">
                        <button class="btn btn-success" @click="checkin">
                            Check-in! <icon icon="rocket"></icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import User from "../../store/Models/User";

export default defineComponent({
    data() {
        return {
            me: null,
        }
    },
    watch: {
        me(user: User) {
            if (user.is_checked_in) {
                this.$router.push({
                    name: 'dashboard',
                });
            }
        },
    },
    methods: {
        checkin() {
            window.api.post('checkin')
                .then(() => {
                    window.resetMe().then(() => {
                        window.isCheckingForCheckin = false;
                        this.$checkForCheckin();

                        this.$router.push({
                            name: 'dashboard',
                        });
                    });
                });
        }
    },
    created() {
        this.$resetMe()
            .then(() => {
                this.me = User.find(window.me.id);
            });
    }
});
</script>

<style scoped>

</style>
