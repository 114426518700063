<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">Teams</li>
                </ol>
            </nav>

            <div id="buttons" class="ms-auto text-md-right align-self-center">
                <router-link v-if="$gate.allows('create', 'team')" :to="{name: 'management.teams.create'}" class="btn btn-sm btn-primary">
                    Nieuwe team
                    <icon class="ms-1" icon="plus"></icon>
                </router-link>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <table class="table table-hover table-align-middle mb-0">
                    <thead>
                    <tr>
                        <th scope="col">Naam</th>
                        <th scope="col" style="width: 1px;"></th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="team of teams" :key="team.id">
                            <td>{{ team.name }}</td>
                            <td class="d-flex">
                                <router-link v-if="$gate.allows('update', team)" :to="{name: 'management.teams.edit', params: {team: team.id}}" class="btn btn-sm btn-primary me-2">
                                    <icon icon="pen-to-square"></icon>
                                </router-link>
                                <button v-if="$gate.allows('delete', team)" class="btn btn-sm btn-danger" @click="deleteTeam(team.id)">
                                    <icon icon="trash"></icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Team from "../../../store/Models/Team";

export default defineComponent({
    computed: {
        teams(): Array<Team> {
            return Team.query()
                .get();
        },
    },
    methods: {
        deleteTeam(id: number) {
            Team.api().delete('teams/'+id, {
                delete: id,
            })
            .then(() => {
                this.$toast('success', 'Het team is succesvol verwijderd.');
            });
        },
    },
    created() {
        Team.api().get('teams');
    }
});
</script>

<style scoped>

</style>
