<template>
    <em v-if="message !== null" class="form-text text-danger" role="alert">{{ message }}</em>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "Error",
    props: {
        field: String,
        bag: Object,
    },
    computed: {
        message(): string | null | undefined {
            return this.$validate(this.field, this.bag);
        }
    },
});
</script>

<style scoped>

</style>
