import AbsenceCreate from './views/Absence/Create.vue';
import AbsenceEdit from './views/Absence/Edit.vue';
import AbsenceIndex from './views/Absence/Index.vue';
import AccountProfile from './views/Account/Profile.vue';
import CalendarIndex from './views/Calendar/Index.vue';
import Checkin from './views/Authentication/Checkin.vue';
import CustomerCreate from './views/Customer/Create.vue';
import CustomerEdit from './views/Customer/Edit.vue';
import CustomerIndex from './views/Customer/Index.vue';
import CustomerShow from './views/Customer/Show.vue';
import Dashboard from './views/Dashboard.vue';
import ForgotPassword from './views/Authentication/ForgotPassword.vue';
import FurloughBalance from './views/Furlough/Balance.vue';
import FurloughCreate from './views/Furlough/Create.vue';
import FurloughFreeDays from './views/Furlough/FreeDays.vue';
import FurloughHistoryOverview from './views/Furlough/HistoryOverview.vue';
import FurloughPrivateHistory from './views/Furlough/PrivateHistory.vue';
import FurloughRequests from './views/Furlough/Requests.vue';
import HoursIndex from './views/Hours/Index.vue';
import DeadlineIndex from './views/Deadline/Index.vue';
import InvoicesCreate from "./views/Project/Invoices/Create.vue";
import Login from './views/Authentication/Login.vue';
import ManagementCheckins from './views/Management/Checkins.vue';
import ManagementRolesCreate from './views/Management/Roles/Create.vue';
import ManagementRolesEdit from './views/Management/Roles/Edit.vue';
import ManagementRolesIndex from './views/Management/Roles/Index.vue';
import ManagementSSO from "./views/Management/SSO.vue";
import ManagementUsersCreate from './views/Management/Users/Create.vue';
import ManagementUsersEdit from './views/Management/Users/Edit.vue';
import ManagementUsersIndex from './views/Management/Users/Index.vue';
import ManagementTeamsCreate from './views/Management/Teams/Create.vue';
import ManagementTeamsEdit from './views/Management/Teams/Edit.vue';
import ManagementTeamsIndex from './views/Management/Teams/Index.vue';
import ProjectBudget from './views/Project/Budgets.vue';
import ProjectCreate from './views/Project/Create.vue';
import ProjectEdit from './views/Project/Edit.vue';
import ProjectIndex from './views/Project/Index.vue';
import ProjectShow from './views/Project/Show.vue';
import ResetPassword from './views/Authentication/ResetPassword.vue';
import RouteGroup from "./components/RouteGroup.vue";
import Scrumboard from "./views/Scrumboard/Scrumboard.vue";
import SetupPassword from "./views/Authentication/SetupPassword.vue";
import {createWebHistory, createRouter, RouteLocationNormalized, NavigationGuardNext} from "vue-router";
import qs from 'qs';

const routes: Array<any> = [
    {
        name: 'dashboard',
        path: '/',
        component: Dashboard,
    },
    {
        name: 'auth.checkin',
        path: '/checkin',
        component: Checkin,
    },
    {
        path: '/auth',
        component: RouteGroup,
        children: [
            { path: 'login', name: 'auth.login', component: Login },
            { path: 'forgot-password', name: 'auth.forgot', component: ForgotPassword },
            { path: 'reset-password/:email/:token', name: 'auth.reset', component: ResetPassword },
            { path: 'setup-password/:token', name: 'auth.setup', component: SetupPassword },
        ],
    },
    {
        path: '/account',
        component: RouteGroup,
        children: [
            { path: 'profile', name: 'account.profile', component: AccountProfile },
        ],
    },
    {
        path: '/management',
        component: RouteGroup,
        children: [
            {
                path: 'users',
                component: RouteGroup,
                children: [
                    { path: '', name: 'management.users.index', component: ManagementUsersIndex },
                    { path: 'create', name: 'management.users.create', component: ManagementUsersCreate },
                    { path: ':user/edit', name: 'management.users.edit', component: ManagementUsersEdit },
                ],
            },
            {
                path: 'teams',
                component: RouteGroup,
                children: [
                    { path: '', name: 'management.teams.index', component: ManagementTeamsIndex },
                    { path: 'create', name: 'management.teams.create', component: ManagementTeamsCreate },
                    { path: ':team/edit', name: 'management.teams.edit', component: ManagementTeamsEdit },
                ],
            },
            {
                path: '/roles',
                component: RouteGroup,
                children: [
                    { path: '', name: 'management.roles.index', component: ManagementRolesIndex },
                    { path: 'create', name: 'management.roles.create', component: ManagementRolesCreate },
                    { path: ':role/edit', name: 'management.roles.edit', component: ManagementRolesEdit },
                ]
            },
            { path: 'checkins', name: 'management.checkins', component: ManagementCheckins },
            { path: 'sso', name: 'management.sso', component: ManagementSSO },
        ],
    },
    {
        path: '/customers',
        component: RouteGroup,
        children: [
            { path: '', name: 'customers.index', component: CustomerIndex },
            { path: ':customer', name: 'customers.show', component: CustomerShow },
            { path: 'create', name: 'customers.create', component: CustomerCreate },
            { path: ':customer/edit', name: 'customers.edit', component: CustomerEdit },
        ],
    },
    {
        path: '/projects',
        component: RouteGroup,
        children: [
            { path: '', name: 'projects.index', component: ProjectIndex },
            { path: ':project', name: 'projects.show', component: ProjectShow },
            { path: 'create', name: 'projects.create', component: ProjectCreate },
            { path: ':project/edit', name: 'projects.edit', component: ProjectEdit },
            { path: ':project/budgets', name: 'projects.budgets', component: ProjectBudget },
            {
                path: ':project/invoices',
                component: RouteGroup,
                children: [
                    { path: '', name: 'invoices.create', component: InvoicesCreate },
                ]
            }
        ],
    },
    {
        path: '/furlough',
        component: RouteGroup,
        children: [
            { path: 'create', name: 'furlough.create', component: FurloughCreate },
            { path: 'balance', name: 'furlough.balance', component: FurloughBalance },
            { path: 'free-days', name: 'furlough.free_days', component: FurloughFreeDays },
            { path: 'requests', name: 'furlough.requests', component: FurloughRequests },
            { path: 'my-history', name: 'furlough.history', component: FurloughPrivateHistory },
            { path: 'history-overview', name: 'furlough.history_overview', component: FurloughHistoryOverview },
        ],
    },
    {
        path: '/absence',
        component: RouteGroup,
        children: [
            { path: '', name: 'absences.index', component: AbsenceIndex },
            { path: 'create', name: 'absences.create', component: AbsenceCreate },
            { path: ':absence/edit', name: 'absences.edit', component: AbsenceEdit },
        ]
    },
    {
        path: '/hours',
        component: RouteGroup,
        children: [
            { path: '', name: 'hours.index', component: HoursIndex },
        ]
    },
    {
        path: '/deadlines',
        component: RouteGroup,
        children: [
            { path: '', name: 'deadlines.index', component: DeadlineIndex },
        ]
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: CalendarIndex,
    },
    {
        path: '/scrumboard',
        name: 'scrumboard',
        component: Scrumboard,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    // set custom query resolver
    parseQuery(query) {
        return qs.parse(query);
    },
    stringifyQuery(query) {
        return qs.stringify(query);
    }
});

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
    // Check if the user is logged in and if not redirect to the login page (if they are not already on it)
    if (to.name && typeof to.name !== "string" || (to.name as string)?.startsWith('auth')) {
        next();
    } else {
        window.isLoggedIn(true)
            .then(() => {
                window.checkForCheckin();

                // Check if the user has 2FA enabled
                if (
                    window.me.two_factor_confirmed_at === null
                    && window.me.is_checked_in
                    && to.name !== 'account.profile'
                ) {
                    next({
                        name: 'account.profile',
                        query: {
                            force_two_factor: true,
                        }
                    } as any);
                } else {
                    next();
                }
            });
    }
});

export default router;
