<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <router-link class="breadcrumb-item" :to="{name: 'management.users.index'}">Gebruikers</router-link>
                    <li class="breadcrumb-item">{{ user?.name }}</li>
                    <li class="breadcrumb-item active" aria-current="page">Bewerken</li>
                </ol>
            </nav>

            <div id="buttons" class="ms-auto text-md-right align-self-center" v-if="user">
                <router-link :to="{name: 'management.users.index'}" class="btn btn-sm btn-default" :class="{disabled: form.working}" :disabled="form.working">
                    Terug
                    <icon class="ms-1" icon="times"></icon>
                </router-link>
                <button v-if="$gate.allows('delete', user)" class="btn btn-sm btn-danger" :disabled="form.working" @click="deleteUser">
                    Deactiveer
                    <icon class="ms-1" icon="trash"></icon>
                </button>
                <button v-if="$gate.allows('update', user)" class="btn btn-sm btn-success" type="submit" form="create-form" :disabled="form.working">
                    Opslaan
                    <icon class="ms-1" icon="floppy-disk"></icon>
                </button>
            </div>
        </div>

        <form @submit.prevent="save" id="create-form">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group">
                                <label class="form-label">Naam</label>
                                <input class="form-control" type="text" v-model="form.name" v-validate:[form.errors]="'name'" />
                                <error field="name" :bag="form.errors"></error>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Email</label>
                                <input class="form-control" type="text" v-model="form.email" v-validate:[form.errors]="'email'" />
                                <error field="email" :bag="form.errors"></error>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Rol</label>
                                <select class="form-select" v-model="form.roles" v-validate:[form.errors]="'role'" multiple>
                                    <option :value="null">--</option>
                                    <option v-for="role of roles" :value="role.id" :key="role.id">{{ role.name }}</option>
                                </select>
                                <error field="roles" :bag="form.errors"></error>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Datum in dienst</label>
                                <input class="form-control" type="date" v-model="form.date_employed" v-validate:[form.errors]="'date_employed'" />
                                <error field="date_employed" :bag="form.errors"></error>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Verjaardag</label>
                                <input class="form-control" type="date" v-model="form.birthday" v-validate:[form.errors]="'birthday'" />
                                <error field="birthday" :bag="form.errors"></error>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Team</label>
                                <select class="form-select" v-model="form.teams" v-validate:[form.errors]="'team'" multiple>
                                    <option :value="null">--</option>
                                    <option v-for="team of teams" :key="team.id" :value="team.id">{{ team.name }}</option>
                                </select>
                                <error field="role" :bag="form.errors"></error>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Kleur</label>
                                <input class="form-control" type="color" style="max-width: 50px" v-model="form.color" v-validate:[form.errors]="'color'" />
                                <error field="color" :bag="form.errors"></error>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Op infoscherm(en)</label>
                                <input class="form-check" type="checkbox" style="max-width: 50px" v-model="form.on_screen" v-validate:[form.errors]="'on_screen'" />
                                <error field="on_screen" :bag="form.errors"></error>
                            </div>

                            <div v-if="form.on_screen" class="form-group">
                                <label class="form-label">infoscherm(en) foto</label>
                                <input class="form-control" type="file" accept="image/png" v-validate:[form.errors]="'infoscreen_image_path'" @change="imageSelected($event)" />
                                <error field="infoscreen_image_path" :bag="form.errors"></error>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Plaats onderaan in lijsten</label>
                                <input class="form-check" type="checkbox" style="max-width: 50px" v-model="form.deferred" v-validate:[form.errors]="'deferred'" />
                                <error field="deferred" :bag="form.errors"></error>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card">
                        <table class="table mb-0 table-bordered">
                            <thead>
                            <tr>
                                <th>Tijd</th>
                                <th>Maandag</th>
                                <th>Dinsdag</th>
                                <th>Woensdag</th>
                                <th>Donderdag</th>
                                <th>Vrijdag</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(time, i) of times" :key="time">
                                    <td class="p-0 text-center">{{ time }} - {{ times[i + 1] ?? '20:00' }}</td>
                                    <td v-for="day of ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']"
                                        :key="day"
                                        class="cursor-pointer p-0"
                                        :class="{'bg-primary': form?.working_hours?.[day]?.[time]}"
                                        @click="toggleWorkingTime(day, time)">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </form>

    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import User from "../../../store/Models/User";
import Role from "../../../store/Models/Role";
import {Item} from "@vuex-orm/core";
import Team from "../../../store/Models/Team";

export default defineComponent({
    data() {
        return {
            form: {
                name: null,
                email: null,
                roles: [],
                color: '#000000',
                working_hours: null,
                date_employed: null,
                birthday: null,
                on_screen: false,
                deferred: false,

                errors: null,
                working: false,
            } as any,

            infoscreen_image_path: null,

            times: [
                '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00',
                '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30',
                '17:00', '17:30', '18:00', '18:30', '19:00',
            ]
        }
    },
    computed: {
        roles(): Array<Role> {
            return Role.all();
        },
        teams(): Array<Team> {
            return Team.all();
        },
        user(): Item<User> {
            return User.find(
                parseInt((this.$route.params.user) as string),
            )
        }
    },
    watch: {
        user: {
            handler(user) {
                if (user === null) {
                    user = {};
                }

                for (const item in this.form) {
                    if (['errors', 'working', 'roles', 'teams'].includes(item) || window._.isNil(user[item])) {
                        continue;
                    }

                    this.form[item] = user[item];
                }

                this.form.roles = user.roles?.map((role: Role) => role.id) ?? [];
                this.form.teams = user.teams?.map((team: Team) => team.id) ?? [];
            },
            immediate: true,
        }
    },
    methods: {
        imageSelected(event: any) {
            this.form.working = true;

            const file = event.target.files[0];

            if (file) {
                const formData = new FormData;
                formData.append('file', file);

                window.api.post('upload', formData)
                    .then(response => {
                        this.infoscreen_image_path = response.data.path;
                    })
                    .finally(() => {
                        this.form.working = false;
                    });
            }
        },
        toggleWorkingTime(day: string, time: string) {
            if (window._.isNil(this.form.working_hours)) {
                this.form.working_hours = {};
            }

            if (!this.form.working_hours.hasOwnProperty(day)) {
                this.form.working_hours[day] = {};
            }

            if (!this.form.working_hours[day].hasOwnProperty(time)) {
                this.form.working_hours[day][time] = false;
            }

            this.form.working_hours[day][time] = !this.form.working_hours[day][time];
        },
        save() {
            this.form.working = true;
            this.form.errors = null;

            const form = {...this.form};

            if (this.infoscreen_image_path !== null) {
                form.infoscreen_image_path = this.infoscreen_image_path;
            }

            User.api().put('users/'+this.$route.params.user, form)
                .then(() => {
                    this.$toast('success', 'Je wijzigingen zijn opgeslagen.');

                    this.$router.push({
                        name: 'management.users.index',
                    });
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.form.errors = e.response.data.errors;
                    }
                })
                .finally(() => {
                    this.form.working = false;
                });
        },
        deleteUser() {
            const id = parseInt((this.$route.params.user as string));

            User.api().delete('users/'+id, {
                delete: id,
            })
                .then(() => {
                    this.$toast('success', 'De gebruiker is succesvol verwijderd.');

                    this.$router.push({
                        name: 'management.users.index',
                    });
                });
        }
    },
    created() {
        Role.api().get('roles');
        Team.api().get('teams');

        User.api().get('users/'+this.$route.params.user, {
            params: {
                include: 'roles,teams',
            }
        })
            .then(response => {
                this.form.role = response.response.data.data.roles?.[0]?.name;
            });
    }
});
</script>

<style scoped>

</style>
