import Absence from "./Models/Absence";
import Budget from "./Models/Budget";
import Checkin from "./Models/Checkin";
import Customer from "./Models/Customer";
import Deadline from "./Models/Deadline";
import Event from "./Models/Event";
import FreeDay from "./Models/FreeDay";
import Furlough from "./Models/Furlough";
import FurloughBalance from "./Models/FurloughBalance";
import FurloughBalanceMutation from "./Models/FurloughBalanceMutation";
import Invoice from "./Models/Invoice";
import InvoiceRow from "./Models/InvoiceRow";
import Permission from "./Models/Permission";
import Project from "./Models/Project";
import Role from "./Models/Role";
import Site from "./Models/Site";
import Team from "./Models/Team";
import Time from "./Models/Time";
import User from "./Models/User";
import UserCalendarIntegration from "./Models/UserCalendarIntegration";
import VuexORM from '@vuex-orm/core';
import ProjectFile from "./Models/ProjectFile";

const database = new VuexORM.Database();

database.register(Absence);
database.register(Budget);
database.register(Checkin);
database.register(Customer);
database.register(Deadline);
database.register(Event);
database.register(FreeDay);
database.register(Furlough);
database.register(FurloughBalance);
database.register(FurloughBalanceMutation);
database.register(Invoice);
database.register(InvoiceRow);
database.register(Permission);
database.register(Project);
database.register(ProjectFile);
database.register(Role);
database.register(Site);
database.register(Team);
database.register(Time);
database.register(User);
database.register(UserCalendarIntegration);

export default database;
