import User from "../../store/Models/User";

export default {
    install: (app: any) => {
        window.isCheckingForCheckin = false;

        app.config.globalProperties.$authenticate = (data: object) => window.axios.post('/login', data);

        app.config.globalProperties.$register = (data: object) => window.axios.post('/register', data);

        app.config.globalProperties.$setLoggedIn = window.setLoggedIn = (user: object|null = null): Promise<any> => {
            return new Promise((resolve, reject) => {
                if (user === null) {
                    window.api.get('users/@me', {withCredentials: true})
                        .then(response => {
                            resolve(window.setLoggedIn(response.data));
                        }).catch(e => {
                            reject(e);
                        });
                } else {
                    const data = {
                        last_checked: window.moment(),
                        user: user,
                    };

                    window.initializeGate(user);

                    localStorage.setItem('authentication', JSON.stringify(data));

                    window.bus.emit('login');

                    resolve(data);
                }
            });
        }

        app.config.globalProperties.$checkForCheckin = window.checkForCheckin = () => {
            if (window.isCheckingForCheckin) {
                return;
            }

            window.resetMe().then(() => {
                const user = window.getMe();

                if (user === null) {
                    throw 'User not logged in yet.';
                }

                if (!user.is_checked_in && user.should_checkin_today) {
                    app.config.globalProperties.$router.push({
                        name: 'auth.checkin',
                    });
                } else {
                    // If the user is still checked in we should recheck this again in 5 minutes
                    window.isCheckingForCheckin = true;

                    setTimeout(() => {
                        window.isCheckingForCheckin = false;
                        window.checkForCheckin();
                    }, 1000 * 60 * 5);
                }
            });
        }

        app.config.globalProperties.$resetMe = window.resetMe = (): Promise<void> => {
            return new Promise(resolve => {
                window.api.get('users/@me', {withCredentials: true})
                    .then(response => {
                        window.setLoggedIn(response.data).then(() => {
                            window.bus.emit('reloadMe');

                            User.insertOrUpdate({
                                data: response.data
                            });

                            window.me = User.find(response.data.id);

                            resolve();
                        });
                    })
                    .catch(e => {
                        localStorage.removeItem('authentication');
                        // If the user is not logged in always redirect to login page if the is not already on it.
                        if (e.response.status === 401 && !app.config.globalProperties.$route.name.startsWith('auth')) {
                            window.location.href = '/auth/login';
                        }
                    });
            });
        }

        app.config.globalProperties.$me = window.getMe = (): User | null => {
            if (window.hasOwnProperty('me') && window.me !== null) {
                return window.me;
            }

            const authentication = localStorage.getItem('authentication');

            if (authentication === null) {
                return null;
            }

            const user = User.find(JSON.parse(authentication).user.id);

            if (user === null) {
                User.insertOrUpdate({
                    data: JSON.parse(authentication).user,
                });
            }

            window.me = User.find(JSON.parse((authentication as string)).user.id);

            return window.me;
        }

        app.config.globalProperties.$logout = () => {
            window.axios.post('/logout')
                .then(() => {
                    localStorage.removeItem('authentication');
                    window.location.href = '/auth/login';
                });
        }

        app.config.globalProperties.$isLoggedIn = window.isLoggedIn = (shouldRedirectToLogin: boolean = false): Promise<boolean> => {
            return new Promise(resolve => {
                const authenticationState = window.localStorage.getItem('authentication');

                if (authenticationState === null) {
                    window.api.get('users/@me', {withCredentials: true})
                    .then(response => {
                        window.setLoggedIn(response.data);
                        resolve(true);
                    })
                    .catch(e => {
                        localStorage.removeItem('authentication');
                        // If the user is not logged in always redirect to login page.
                        if (e.response.status === 401 && (app.config.globalProperties.$route.name === undefined || !app.config.globalProperties.$route.name.startsWith('auth'))) {
                            shouldRedirectToLogin ? window.location.href = '/auth/login' : resolve(false);
                        }
                    });
                } else {
                    const parsedAuthenticationState = JSON.parse(authenticationState);
                    if (!parsedAuthenticationState.hasOwnProperty('last_checked')) {
                        return shouldRedirectToLogin ? window.location.href = '/auth/login' : resolve(false);
                    }

                    if (window.moment(parsedAuthenticationState.last_checked).isBefore(window.moment().subtract(10, 'minutes'))) {
                        // In this case we just have to run a request that checks again if the user is logged in or not.
                        window.api.get('users/@me', {withCredentials: true})
                            .then(response => {
                                window.setLoggedIn(response.data);
                                resolve(true);
                            })
                            .catch(e => {
                                localStorage.removeItem('authentication');
                                // If the user is not logged in always redirect to login page.
                                if (e.response.status === 401) {
                                    return shouldRedirectToLogin ? window.location.href = '/auth/login' : resolve(false);
                                }
                            });
                    } else {
                        if (window._.isNil(window.me)) {
                            window.setLoggedIn().then(() => {
                                window.initializeGate(window.me);

                                resolve(true);
                            })
                            .catch((e: any) => {
                                localStorage.removeItem('authentication');
                                // If the user is not logged in always redirect to login page.
                                if (e.response.status === 401) {
                                    return shouldRedirectToLogin ? window.location.href = '/auth/login' : resolve(false);
                                }
                            });
                        } else {
                            window.initializeGate(window.me);

                            resolve(true);
                        }
                    }
                }
            });
        }
    }
}
