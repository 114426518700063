<template>
    <div>
        <div v-if="customer !== null" class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <router-link class="breadcrumb-item" :to="{name: 'customers.index'}">Klanten</router-link>
                    <li class="breadcrumb-item active" aria-current="page">
                        [{{ customer?.code }}] <span v-html="customer?.name"></span>
                    </li>
                </ol>
            </nav>

            <div id="buttons" class="ms-auto text-md-right align-self-center">
                <router-link v-if="$gate.allows('update', customer)"
                             :to="{name: 'customers.edit', params: {customer: customer.id}}"
                             class="btn btn-sm btn-primary">
                    Bewerken
                    <icon class="ms-1" icon="pen-to-square"></icon>
                </router-link>
                <button v-if="$gate.allows('delete', customer)" class="btn btn-sm btn-danger" @click="deleteCustomer">
                    Verwijderen
                    <icon class="ms-1" icon="trash"></icon>
                </button>
            </div>
        </div>

        <div v-if="customer" class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <div class="card-table card mb-0">
                            <table class="table table-sm mb-0">
                                <tbody>
                                <tr>
                                    <td>Naam</td>
                                    <td v-html="customer.name"></td>
                                </tr>
                                <tr>
                                    <td>Code</td>
                                    <td>{{ customer.code.toUpperCase() }}</td>
                                </tr>
                                <tr>
                                    <td>Kleur</td>
                                    <td>
                                        <div
                                            :style="'background-color: '+customer.color+'; width: 1.4rem; height: 1.4rem; border-radius: 50%;'">
                                            &nbsp;
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card-table card mb-0">
                            <table class="table table-sm mb-0">
                                <tbody>
                                <tr>
                                    <td>Contactpersoon</td>
                                    <td>
                                        <span v-if="customer.contact">{{ customer.contact }}</span>
                                        <span class="text-muted" v-else>Geen contactpersoon</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>E-mailadres</td>
                                    <td>
                                        <span v-if="customer.email">{{ customer.email }}</span>
                                        <span class="text-muted" v-else>Geen emailadres</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Telefoonnummer</td>
                                    <td>
                                        <span v-if="customer.phone">{{ customer.phone }}</span>
                                        <span class="text-muted" v-else>Geen telefoonnummer</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ProjectList v-if="customer !== null" :customer="customer?.id"></ProjectList>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Customer from "../../store/Models/Customer";
import {Item} from "@vuex-orm/core";
import ProjectList from "../../components/Project/ProjectList.vue";

export default defineComponent({
    components: {ProjectList},
    computed: {
        customer(): Item<Customer> {
            return Customer.find(
                parseInt((this.$route.params.customer as string))
            );
        }
    },
    methods: {
        deleteCustomer() {
            if (this.customer === null) {
                return;
            }

            Customer.api().delete('customers/' + this.customer.id, {
                delete: this.customer.id,
            });
        }
    },
    created() {
        Customer.api().get('customers/' + this.$route.params.customer);
    }
});
</script>

<style scoped>

</style>
