import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";

export default class Team extends Model {
    static entity = 'teams';

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
        }
    }

    declare id: number;
    declare name: string;

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'name',
    }
}
