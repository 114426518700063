import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";

export default class Role extends Model {
    static entity = 'roles';

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
        }
    }

    id!: number;
    name!: string;

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'name',
    }
}
