<template>
    <div v-if="$gate.allows('view-any', 'project')" class="card customer-projects mb-3">
        <div v-if="header" class="card-header">
            Projecten
        </div>
        <div class="card-header">
            <ul class="nav nav-tabs card-header-tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link cursor-pointer" data-toggle="tab" role="tab" @click="filter.status = 'active'" :class="{active: filter.status === 'active'}">
                        Actief
                        <span class="badge rounded-pill bg-primary" v-if="filter.status === 'active'">{{projectData.total}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link cursor-pointer" data-toggle="tab" role="tab" @click="filter.status = 'inactive'" :class="{active: filter.status === 'inactive'}">
                        Inactief
                        <span class="badge rounded-pill bg-primary" v-if="filter.status === 'inactive'">{{projectData.total}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link cursor-pointer" data-toggle="tab" role="tab" @click="filter.status = 'invoiced'" :class="{active: filter.status === 'invoiced'}">
                        Gefactureerd
                        <span class="badge rounded-pill bg-primary" v-if="filter.status === 'invoiced'">{{projectData.total}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link cursor-pointer" data-toggle="tab" role="tab" @click="filter.status = 'on_hold'" :class="{active: filter.status === 'on_hold'}">
                        On hold
                        <span class="badge rounded-pill bg-primary" v-if="filter.status === 'on_hold'">{{projectData.total}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link cursor-pointer" data-toggle="tab" role="tab" @click="filter.status = 'archived'" :class="{active: filter.status === 'archived'}">
                        Gearchiveerd
                        <span class="badge rounded-pill bg-primary" v-if="filter.status === 'archived'">{{projectData.total}}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="card-body">
            <form @click.prevent="fetchProjects(1)">
                <div class="row">
                    <div class="col-12">
                        <strong>🔎 Zoeken:</strong>
                        <input ref="search" type="search" required name="customer" class="form-control d-inline-block w-auto ms-1" placeholder="" v-model="filter.search" style="min-width: 400px;">

                        <strong class="ms-4">Project Manager:</strong>
                        <select class="form-select d-inline-block w-auto ms-1" v-model="filter.projectManager">
                            <option value="" :selected="!filter.projectManager"></option>
                            <option v-for="projectManager in projectManagers" :key="projectManager.id" :value="projectManager.id" :selected="filter.projectManager === projectManager.id">{{ projectManager.name }}</option>
                        </select>

                        <strong class="ms-4">Account Manager:</strong>
                        <select class="form-select d-inline-block w-auto ms-1" v-model="filter.accountManager">
                            <option value="" :selected="!filter.accountManager"></option>
                            <option v-for="accountManager in accountManagers" :key="accountManager.id" :value="accountManager.id" :selected="filter.accountManager === accountManager.id">{{ accountManager.name }}</option>
                        </select>

                        <button class="btn btn-primary d-none" type="submit" :disabled="loading">Filter</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="card-body">
            <table class="table table-sm table-hover table-align-middle mb-0">
                <thead>
                <tr>
                    <th scope="col" style="padding-right: 25px;" class="cursor-pointer" @click="sortProjects('id')">
                        #
                        <icon v-if="currentSort === 'id'" class="ms-1" icon="chevron-up"></icon>
                        <icon v-if="currentSort === '-id'" class="ms-1" icon="chevron-down"></icon>
                    </th>
                    <th v-if="!customer" scope="col" class="cursor-pointer" @click="sortProjects('customers.name')">
                        Klant
                        <icon v-if="currentSort === 'customers.name'" class="ms-1" icon="chevron-up"></icon>
                        <icon v-if="currentSort === '-customers.name'" class="ms-1" icon="chevron-down"></icon>
                    </th>
                    <th scope="col" class="cursor-pointer" @click="sortProjects('name')">
                        Project
                        <icon v-if="currentSort === 'name'" class="ms-1" icon="chevron-up"></icon>
                        <icon v-if="currentSort === '-name'" class="ms-1" icon="chevron-down"></icon>
                    </th>
                    <th scope="col" class="cursor-pointer" @click="sortProjects('last_activity_at')">
                        Activiteit
                        <icon v-if="currentSort === 'last_activity_at'" class="ms-1" icon="chevron-up"></icon>
                        <icon v-if="currentSort === '-last_activity_at'" class="ms-1" icon="chevron-down"></icon>
                    </th>
                    <th scope="col" title="Project Manager">PM</th>
                    <th scope="col" title="Account Manager">AM</th>
                    <th scope="col" class="cursor-pointer" @click="sortProjects('budgets.budget_minutes')">
                        Budget
                        <icon v-if="currentSort === '-budgets.budget_minutes'" class="ms-1" icon="chevron-up"></icon>
                        <icon v-if="currentSort === 'budgets.budget_minutes'" class="ms-1" icon="chevron-down"></icon>
                    </th>
                    <th scope="col" class="cursor-pointer" @click="sortProjects('budgets.billable_worked_minutes')">
                        Te factureren
                        <icon v-if="currentSort === '-budgets.billable_worked_minutes'" class="ms-1" icon="chevron-up"></icon>
                        <icon v-if="currentSort === 'budgets.billable_worked_minutes'" class="ms-1" icon="chevron-down"></icon>
                    </th>
                    <th scope="col" class="cursor-pointer" @click="sortProjects('status')" v-if="(filter.status=='active' || filter.status=='done')">
                        Status
                        <icon v-if="currentSort === 'status'" class="ms-1" icon="chevron-up"></icon>
                        <icon v-if="currentSort === '-status'" class="ms-1" icon="chevron-down"></icon>
                    </th>
                </tr>
                </thead>
                <tbody v-if="projectData?.data?.length > 0">
                    <tr v-for="(project, index) of projectData.data" :key="index">
                        <td>{{ project.id }}</td>
                        <td v-if="!customer">
                            <span v-if="project.customer" :title="project.customer.name">
                                <span class="project-border" :style="'background-color: '+project.customer.color">&nbsp;</span>&nbsp;
                                <router-link v-if="$gate.allows('view', project.customer)" :to="{name: 'customers.show', params: {customer: project.customer?.id}}" v-html="project.customer?.name"></router-link>
                                <span v-else v-html="project.customer?.name"></span>
                            </span>
                            <span v-if="project.sub_customer" :title="project.sub_customer.name" class="ms-2">
                                <span class="project-border" :style="'background-color: '+project.sub_customer.color">&nbsp;</span>&nbsp;
                                <router-link v-if="$gate.allows('view', project.customer)" :to="{name: 'customers.show', params: {customer: project.sub_customer?.id}}" v-html="project.sub_customer?.name"></router-link>
                                <span v-else v-html="project.sub_customer?.name"></span>
                            </span>
                        </td>
                        <td>
                            <div class="text-clamp" :title="project.name">
                                <router-link v-if="$gate.allows('view', project)" :to="{name: 'projects.show', params: {project: project.id}}">{{ project.name }}</router-link>
                                <span v-else>{{ project.name }}</span>
                            </div>
                        </td>
                        <td class="text-nowrap pe-3">
                            <span v-if="project.last_activity_at !== null">{{ $moment(project.last_activity_at).format('DD-MM-yyyy') }}</span>
                        </td>
                        <td>
                            <div v-if="project.project_manager" class="text-clamp" :title="project.project_manager?.name">
                                <img :src="project.project_manager?.gravatar" class="gravatar-nano me-1" :style="'border: 1px solid ' + project.project_manager?.color">
                            </div>
                        </td>
                        <td>
                            <div v-if="project.account_manager" class="text-clamp" :title="project.account_manager?.name">
                                <img :src="project.account_manager?.gravatar" class="gravatar-nano me-1" :style="'border: 1px solid ' + project.account_manager?.color">
                            </div>
                        </td>
                        <td>
                            <span v-if="project.budget_minutes == 0 && project.billable_worked_minutes > 0 && project.workable_minutes <= 0" title="Werkbare uren" class="text-secondary">open</span>
                            <span v-else>{{ $timeFormatHoursMinutes(project.budget_minutes) }}</span>

                            <small class="ms-1" v-if="project.budget_minutes!=project.workable_minutes && project.workable_minutes > 0" title="Werkbare uren">(<em>{{ $timeFormatHoursMinutes(project.workable_minutes) }}</em>)</small>
                        </td>
                        <td>
                            <span :class="{'text-warning': project.billable_worked_minutes > project.workable_minutes}">
                                {{ $timeFormatHoursMinutes(project.billable_worked_minutes) }}
                                <small v-if="project.worked_minutes!=project.billable_worked_minutes" title="Totaal gewerkte uren">(<em>{{ $timeFormatHoursMinutes(project.worked_minutes) }}</em>)</small>
                            </span>
                        </td>
                        <td v-if="(filter.status=='active' || filter.status=='done')">
                            <span class="text-nowrap">
                                {{ project.status }}<span v-if="project.all_deadlines_finished" class="circle ms-1" style="background-color: #f60;" title="Geen openstaande deadlines"></span>
                            </span>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="10">
                            <icon v-if="loading" :spin="true" icon="circle-notch" style="left: 50%"></icon>
                            <span v-else>Er zijn nog geen projecten beschikbaar.</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <Pagination :data="projectData" @pagination-change-page="fetchProjects" :limit="2" align="center" class="mt-3" />
        </div>
    </div>
    <div class="dropdown project-settings-cogs">
        <button class="btn btn-secondary dropdown-toggle rounded-pill" type="button" id="projectsDropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
            <icon icon="cogs"></icon>
        </button>
        <div class="dropdown-menu" aria-labelledby="projectsDropdownMenu">
            <div class="card-body">
                <label class="form-label">Aantal projecten</label>
                <select class="form-select" id="page-size" @change="setPageSize($event)">
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {ProjectData} from "../../typings/ProjectData";
import Pagination from 'laravel-vue-pagination';
import Project from "../../store/Models/Project";
import {isNil} from "lodash";
import User from "../../store/Models/User";

export default defineComponent({
    components: {Pagination},
    props: {
        header: {
            type: Boolean,
            default: true,
        },
        customer: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            loading: false,

            page: 0,

            filter: {
                status: 'active',
                search: null,
                projectManager: null,
                accountManager: null,
            },
            sort: {
                customer: null,
                name: null
            },
            currentSort: null,

            projectData: [] as ProjectData | never[],
            projectManagers: [] as User | never[],
            accountManagers: [] as User | never[],
        }
    },
    watch: {
        'filter.status': {
            handler() {
                this.fetchProjects();
            },
            deep: true,
        },
        'currentSort': {
            handler() {
                this.fetchProjects();
            },
            deep: true,
        }
    },
    methods: {
        fetchProjectManagers() {
            // fetch project managers
            User.api().get('users?filter[role_id]=3').then(response => {
                this.projectManagers = response.response.data.data;
            });
        },
        fetchAccountManagers() {
            // fetch account managers
            User.api().get('users?filter[role_id]=6').then(response => {
                this.accountManagers = response.response.data.data;
            });
        },
        fetchProjects(page: Number|null = null) {
            // prevent multiple requests
            if (this.loading) {
                return;
            }

            this.loading = true;

            page = page ?? this.page;

            let url = 'projects?page[number]='+page+'&include=customer,subCustomer,projectManager,accountManager&filter[status]='+this.filter.status;

            if (this.filter.search !== null && this.filter.search !== '') {
                url += '&search=' + this.filter.search;
            }

            if (this.filter.projectManager !== null && this.filter.projectManager !== '') {
                url += '&filter[project_manager_id]=' + this.filter.projectManager;
            }
            if (this.filter.accountManager !== null && this.filter.accountManager !== '') {
                url += '&filter[account_manager_id]=' + this.filter.accountManager;
            }

            if (this.customer) {
                url += '&filter[customer_id]='+this.customer;
            }

            if (localStorage.getItem('projectsPageSize')) {
                url += '&page[size]=' + localStorage.getItem('projectsPageSize');
            }

            if (this.currentSort) {
                url += '&sort=' + this.currentSort;
            }

            Project.api().get(url).then(response => {
                let data = response.response.data;

                data.data = data.data.map((item: any) => {
                    Project.insertOrUpdate({
                        data: item
                    });

                    return Project.query()
                        .with('customer')
                        .with('sub_customer')
                        .with('project_manager')
                        .with('account_manager')
                        .where('id', item.id)
                        .first();
                });

                // update url with query params
                let queryFilter = {};
                if (this.filter.projectManager) {
                    queryFilter.project_manager_id = this.filter.projectManager;
                }
                if (this.filter.accountManager) {
                    queryFilter.account_manager_id = this.filter.accountManager;
                }
                this.$router.replace({query: {
                    search: this.filter.search,
                    status: this.filter.status,
                    filter: queryFilter,
                    page: page,
                    sort: this.currentSort,
                }});

                this.projectData = data;
                this.loading = false;
            });
        },
        setPageSize(event) {
            localStorage.setItem('projectsPageSize', event.target.value);
            this.fetchProjects();
        },
        sortProjects(sort: string) {
            this.currentSort === sort ? this.currentSort = '-' + sort : this.currentSort = sort
        }
    },
    mounted() {
        // set autofocus
        this.$refs.search.focus();

        // set page size
        (document.getElementById('page-size') as any).value = localStorage.getItem('projectsPageSize') || '25';
    },
    created() {
        // set filters from query params
        if (!isNil(this.$route.query?.search)) {
            this.filter.search = this.$route.query.search as string;
        }
        if (!isNil(this.$route.query?.status)) {
            this.filter.status = this.$route.query.status as string;
        }
        if (!isNil(this.$route.query?.filter?.project_manager_id)) {
            this.filter.projectManager = this.$route.query.filter.project_manager_id as number;
        }
        if (!isNil(this.$route.query?.filter?.account_manager_id)) {
            this.filter.accountManager = this.$route.query.filter.account_manager_id as number;
        }
        if (!isNil(this.$route.query?.sort)) {
            this.currentSort = this.$route.query.sort as string;
        } else {
            this.currentSort = '-id';
        }
        this.page = 1
        if (!isNil(this.$route.query?.page)) {
            this.page = this.$route.query.page as number;
        }

        // fetch projects
        this.fetchProjectManagers();
        this.fetchAccountManagers();
        this.fetchProjects();
    }
});
</script>

<style scoped>

.project-settings-cogs {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
}  .dropdown-toggle::after {
    display: none !important;
}
</style>
