<template>
    <div class="row">
        <div class="col-md-6">
            <TwoFactorAuthentication />
        </div>
        <div class="col-md-6">
            <ResetPassword />
        </div>
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    <strong>Styling</strong>
                </div>
                <div class="card-body">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="wantsSmallScreen" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            Klein scherm
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import ResetPassword from "../../components/Account/ResetPassword.vue";
import TwoFactorAuthentication from "../../components/Account/TwoFactorAuthentication.vue";

export default defineComponent({
    components: {TwoFactorAuthentication, ResetPassword},
    data() {
        return {
            wantsSmallScreen: false,
        }
    },
    watch: {
        wantsSmallScreen() {
            window.api.put('users/@me', {
                wants_small_screen: this.wantsSmallScreen,
            })
                .then(() => {
                    if (this.wantsSmallScreen) {
                        document.getElementById('small-screen-style').innerHTML = '';
                    } else {
                        document.getElementById('small-screen-style').innerHTML = 'main.container {\n' +
                            '                max-width: 90%;\n' +
                            '            }';
                    }
                });
        }
    },
    mounted() {
        this.wantsSmallScreen = window.me.wants_small_screen;
    },
})
</script>

<style scoped>

</style>
