import lodash from 'lodash';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap';
// @ts-ignore
import moment from 'moment/min/moment-with-locales.js';
import pluralize from "pluralize";
import 'bootstrap-icons/font/bootstrap-icons.css';

moment.locale('nl');

window._ = lodash;
window.Swal = Swal;
window.moment = moment;
window.pluralize = pluralize;
window.bootstrap = bootstrap;

import './api';
