<template>
    <nav class="navbar navbar-expand-md navbar-dark bg-primary shadow-sm">
        <div class="container">
            <router-link class="navbar-brand" :to="{name: 'dashboard'}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132.1 125.8" style="margin-top: -10px">
                    <path fill="currentColor" d="M98 45.5a8.6 8.6 0 0 0-4.7 1.6 29.7 29.7 0 0 0-9 12.9 48 48 0 0 0-2.8 9.1 16.6 16.6 0 0 0 3.4.3 20.8 20.8 0 0 0 7.3-1.4c6-2.3 9.4-8.4 10-13.5.4-2.5.3-5.8-1.1-7.6a3.8 3.8 0 0 0-3.2-1.4Z" />
                    <path fill="currentColor" d="M65.2 0H63a67.5 67.5 0 0 0-47 21.5 57.2 57.2 0 0 0-16 41c1.8 43.7 40.4 63.3 67.2 63.3l4-.1c13.7-1 29.2-10 41.6-24s19.6-31.3 19.2-46C131.5 26 100.3 0 65.2 0Zm-4.7 81.5a35 35 0 0 1-25.4 12.8c-5.6 0-10.5-2-13-5.4a3.8 3.8 0 0 1-.5-1.3 18 18 0 0 1-.3-2 .4.4 0 0 1 0-.4.2.2 0 0 1 .2 0c.1 0 .2 0 .5.4l.1.1v.1a.6.6 0 0 1 .2.1c1.4 1.6 4.5 4.3 10.7 4.3h.2c8.7 0 25.6-11.8 25.8-30 0-4.2-.9-7.2-2.7-9.1a7 7 0 0 0-5.1-2C44.9 49 37.4 55 34.8 62c-1.3 7-2.4 14-3.2 21.1v.2a.8.8 0 0 1-.7.3 2 2 0 0 1-.7-.1 34.6 34.6 0 0 1-6.8-7.5 2.5 2.5 0 0 1-.3-1.4 118.6 118.6 0 0 1 2.1-14A124.7 124.7 0 0 1 45 18a1.6 1.6 0 0 1 .5-.4 15.8 15.8 0 0 1 3.4-1.1.8.8 0 0 1 .6.1c.2 0 .3.3.1.7a172 172 0 0 0-12.4 34.3c3.2-4.6 8.7-9.7 17-9.3a15 15 0 0 1 13.6 9.6c3.7 8.8.9 20.5-7.3 29.7Zm49.3-21.3C106.3 69 95.2 73 86.4 73a25 25 0 0 1-5.4-.6c-.6 6.4 1.3 11 5.6 13.5a10.3 10.3 0 0 0 5.3 1.5c5 0 9.4-3.5 11.6-6.4l.1-.3a.4.4 0 0 1 .3-.1c.1 0 .2 0 .3.3a6.4 6.4 0 0 1-.4 1.8 9.5 9.5 0 0 1-2.1 3.4 16.9 16.9 0 0 1-7.5 4.8 18.9 18.9 0 0 1-6 1 16.1 16.1 0 0 1-11.4-4.6 21 21 0 0 1-5-20c2-9 6-16.2 11.8-20.9a24 24 0 0 1 14.8-5.6h.3a13 13 0 0 1 10.6 5.6c2.5 3.7 2.7 8.6.5 13.9Z" />
                </svg>
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <!-- Left Side Of Navbar -->
                <ul class="navbar-nav mr-auto">
                    <template v-if="load && $route.name !== 'auth.checkin'">
                        <router-link class="nav-link" :class="{active: $route.name === 'dashboard'}" :to="{name: 'dashboard'}">Dashboard</router-link>
                        <router-link v-if="$gate.allows('view_scrumboard')" :class="{active: $route.name === 'scrumboard'}" class="nav-link" :to="{name: 'scrumboard'}">Kanban</router-link>
                        <router-link v-if="$gate.allows('view-any', 'project')" :class="{active: $route.name.startsWith('projects')}" class="nav-link" :to="{name: 'projects.index'}">Projecten</router-link>
                        <router-link v-if="$gate.allows('view-any', 'customer')" :class="{active: $route.name.startsWith('customers')}" class="nav-link" :to="{name: 'customers.index'}">Klanten</router-link>
                        <router-link v-if="$gate.allows('view-any', 'event')" :class="{active: $route.name.startsWith('calendar')}" class="nav-link" :to="{name: 'calendar'}">Agenda</router-link>
                        <router-link v-if="$gate.allows('hours')" :class="{active: $route.name.startsWith('hours')}" class="nav-link" :to="{name: 'hours.index'}">Uren</router-link>
                        <router-link v-if="$gate.allows('view_deadlines')" :class="{active: $route.name.startsWith('deadlines')}" class="nav-link" :to="{name: 'deadlines.index'}">Deadlines</router-link>
                    </template>
                    <span v-else-if="$route.name === 'auth.checkin'" class="nav-link">Inchecken</span>
                    <span v-else-if="$route.name === 'auth.login'" class="nav-link">Inloggen</span>
                    <span v-else-if="$route.name === 'auth.forgot'" class="nav-link">Wachtwoord vergeten</span>
                    <span v-else-if="$route.name === 'auth.reset'" class="nav-link">Wachtwoord herstellen</span>
                    <span v-else-if="$route.name === 'auth.checkout'" class="nav-link">Checkout</span>
                </ul>

                <!-- Right Side Of Navbar -->
                <ul v-if="load && $route.name !== 'auth.checkin'" class="navbar-nav ms-auto">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="false" id="policy">
                            <icon icon="building-shield"></icon>
                            <span class="caret"></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-lg-right" aria-labelledby="policy">
                            <a href="/assets/policy/cybersecurity-protocol.pdf" class="dropdown-item" download="cybersecurity-protocol.pdf">Cyber Security Protocol</a>
                            <a href="/assets/policy/personeelshandboek-be-interactive.pdf" class="dropdown-item" download="personeelshandboek-be-interactive.pdf">Personeelshandboek</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="false" id="furlough">
                            Verlof <span v-if="balance !== null" class="badge badge-pill badge-primary">{{ balance }}</span>
                            <span class="caret"></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-lg-right" aria-labelledby="furlough">
                            <router-link v-if="$gate.allows('create', 'furlough')" class="dropdown-item" :to="{name: 'furlough.create'}">Aanvragen</router-link>
                            <router-link v-if="$gate.allows('accept', 'furlough')" class="dropdown-item" :to="{name: 'furlough.requests'}">Goed te keuren</router-link>
                            <router-link v-if="$gate.allows('history', 'furlough')" class="dropdown-item" :to="{name: 'furlough.history'}">Geschiedenis (mijzelf)</router-link>
                            <router-link v-if="$gate.allows('history_overview', 'furlough')" class="dropdown-item" :to="{name: 'furlough.history_overview'}">Overzicht</router-link>
                            <hr v-if="$gate.allows('absence', 'furlough')" />
                            <router-link v-if="$gate.allows('absence', 'furlough')" class="dropdown-item" :to="{name: 'absences.index'}">Verzuim</router-link>
                            <router-link v-if="$gate.allows('freeDays', 'furlough')" class="dropdown-item" :to="{name: 'furlough.free_days'}">Vrije dagen</router-link>
                            <router-link v-if="$gate.allows('balance', 'furlough')" class="dropdown-item" :to="{name: 'furlough.balance'}">Balans</router-link>
                        </div>
                    </li>
                    <li class="nav-item dropdown" v-if="$gate.allows('view-any', 'user') || $gate.allows('view-any', 'role') || $gate.allows('manage-sso') || $gate.allows('view-checkins', 'user')">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="false" aria-expanded="false" id="management">Beheer <span class="caret"></span></a>
                        <div class="dropdown-menu dropdown-menu-lg-right" aria-labelledby="management">
                            <router-link v-if="$gate.allows('view-any', 'user')" class="dropdown-item" :to="{name: 'management.users.index'}">Gebruikers</router-link>
                            <router-link v-if="$gate.allows('view-any', 'team')" class="dropdown-item" :to="{name: 'management.teams.index'}">Teams</router-link>
                            <router-link v-if="$gate.allows('view-any', 'role')" class="dropdown-item" :to="{name: 'management.roles.index'}">Rollen</router-link>
                            <router-link v-if="$gate.allows('manage-sso')" class="dropdown-item" :to="{name: 'management.sso'}">SSO</router-link>
                            <router-link v-if="$gate.allows('view-checkins', 'user')" class="dropdown-item" :to="{name: 'management.checkins'}">Check-ins</router-link>
                        </div>
                    </li>
                    <li class="nav-item dropdown">
                        <a id="accountDropdown" class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img :src="me?.gravatar" :style="'border: 1px solid' + me?.color " class="gravatar-nano">&nbsp;&nbsp;&nbsp;{{ me?.name }} <span class="caret"></span></a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="accountDropdown">
                            <router-link class="dropdown-item" :to="{name: 'account.profile'}">Mijn Profiel</router-link>
                            <button class="dropdown-item" @click="$logout">Uitloggen</button>
                        </div>
                    </li>
                    <li v-if="me?.is_checked_in" class="nav-item">
                        <a class="nav-link btn btn-sm btn-secondary position-relative mt-3 mt-lg-0 ms-0 ms-lg-2" @click="checkout">
                            Check uit <icon icon="rocket" class="ms-2"></icon>

                            <span
                                class="position-absolute top-0 start-100 ms-n4 ms-lg-0 translate-middle badge rounded-pill d-none"
                                id="checked-in-since"
                                style="background-color: #877e61"
                            >
                                0:01
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import User from "../store/Models/User";

export default defineComponent({
    name: "Navigation",
    props: {
        load: Boolean,
        me: Object,
    },
    data() {
        return {
            is_loaded: true,

            balance: null as string|null,
        }
    },
    watch: {
        $route() {
            this.closeMobileMenu();
        }
    },
    methods: {
        checkout(): void {
            window.api.post('checkout')
                .then(() => {
                    window.resetMe();
                    this.$toast('success', 'Houdoe en bedankt ' + window.me?.name)
                });
        },
        setCheckedInSinceTime() {
            const element = document.getElementById('checked-in-since');
            const me = window.me;

            if (me === null || me === undefined) {
                if (element !== null) {
                    element.classList.add('d-none');
                }

                return setTimeout(() => {
                    this.setCheckedInSinceTime();
                }, 1000 * 5);
            }

            if (me.is_checked_in) {
                if (element !== null) {
                    const now = this.$moment().format('YYYY-MM-DD HH:mm:ss');
                    const checkedInSince = this.$moment.utc(me.checked_in_since).format('YYYY-MM-DD HH:mm:ss');

                    const diff = this.$moment(now).diff(checkedInSince);
                    const duration = this.$moment.duration(diff);

                    element.textContent = this.$timeFormatHoursMinutes(Math.ceil(duration.asMinutes()));
                    element.classList.remove('d-none');
                }
            }

            setTimeout(() => {
                this.setCheckedInSinceTime();
            }, 1000 * 60);
        },
        getFurloughBalance() {
            User.api().get('users/'+window.me?.id+'?include=furloughBalances')
                .then(response => {
                    const item = window._.find(
                        response.response.data.data.furlough_balances,
                        ['year', parseInt(this.$moment().format('Y'))]
                    );

                    if (item) {
                        this.balance = this.$timeFormatDaysHours(item.minutes);
                    }
                });
        },
        closeMobileMenu() {
            const menuToggle = document.getElementById('navbarSupportedContent')
            const bsCollapse = new window.bootstrap.Collapse(menuToggle, {toggle:false})

            bsCollapse.hide();
        }
    },
    mounted() {
        this.setCheckedInSinceTime();
        this.getFurloughBalance();

        this.$bus.on('reloadFurloughBalance', () => {
            this.getFurloughBalance();
        });

    }
});

</script>

<style scoped>

</style>
