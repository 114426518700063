import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";
import Project from "./Project";
import Deadline from "./Deadline";

export default class Budget extends Model {
    static entity = 'budgets';

    static fields() {
        return {
            id: this.attr(null),
            project_id: this.attr(null),
            group: this.string(null),
            position: this.number(1),
            description: this.string(''),
            amount: this.number(0.00),
            budget_minutes: this.number(0),
            workable_minutes: this.number(0),
            is_open: this.boolean(false),
            status: this.string('active'),

            worked_minutes: this.number(null).nullable(),
            billable_worked_minutes: this.number(null).nullable(),
            non_billable_worked_minutes: this.number(null).nullable(),
            invoiced_minutes: this.number(null).nullable(),

            created_at: this.string(false),
            updated_at: this.string(false),

            project: this.belongsTo(Project, 'project_id'),
            deadlines: this.hasMany(Deadline, 'budget_id'),
        }
    }

    declare id: number;
    declare project_id: number;
    declare group: Group;
    declare position: number;
    declare description: string;
    declare amount: number;
    declare budget_minutes: number;
    declare workable_minutes: number;
    declare status: string;
    declare is_open: boolean;
    declare created_at: string;
    declare updated_at: string;

    declare worked_minutes: number | null;
    declare billable_worked_minutes: number | null;
    declare non_billable_worked_minutes: number | null;
    declare invoiced_minutes: number | null;

    declare project: Project;
    declare deadlines: Array<Deadline>;

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'description',
    }
}

export type Group = 'web' | 'design' | 'project_management' | 'content' | 'other';
