import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";
import User from "./User";

export default class UserCalendarIntegration extends Model {
    static entity = 'user_calendar_integrations';

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            type: this.string(null),
            description: this.string(null),
            is_private: this.boolean(true),
            oauth_token: this.string(null),

            created_at: this.string(false),
            updated_at: this.string(false),

            user: this.belongsTo(User, 'user_id'),
        }
    }

    id!: number;
    user_id!: number;
    type!: string;
    description!: string;
    is_private!: boolean;
    oauth_token!: string;
    created_at!: string;
    updated_at!: string;

    user!: User;

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'description',
    }
}
