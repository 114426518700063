// @ts-ignore
import mitt from "mitt";
import {isNil} from "lodash";
import User from "../../store/Models/User";
import moment from "moment";
import login from "../../views/Authentication/Login.vue";

export default {
    install: (app: any) => {
        app.config.globalProperties.$ucFirst = window.ucFirst = (str: string): string => {
            if (window._.isNil(str)) {
                return '';
            }

            return str.charAt(0).toUpperCase() + str.slice(1);
        }

        app.config.globalProperties.$moment = window.moment;

        app.config.globalProperties.$bus = window.bus = mitt();

        app.config.globalProperties.$global = window.global;

        app.config.globalProperties.$moneyConfig = {
            decimal: ',',
            thousands: '.',
            prefix: '',
            suffix: '',
            precision: 2,
            masked: false
        }

        app.config.globalProperties.$timeConfig = '##:##';

        app.config.globalProperties.$timeFormatHoursMinutes = window.timeFormatHoursMinutes = (minutes: any): string => {
            if (isNil(minutes)) {
                return '00:00';
            }

            let belowZero = false;
            if (minutes < 0) {
                belowZero = true;

                minutes = minutes + (-minutes * 2);
            }

            const hours = parseInt((Number(minutes) / 60) as any);

            minutes = Number(minutes % 60);

            if (minutes <= 9) {
                minutes = '0' + minutes;
            }

            return (belowZero ? '-' : '') +hours + ':' + minutes;
        }

        app.config.globalProperties.$timeFormatDaysHours = window.timeFormatDaysHours = (minutes: any): string => {

            const dayName = 'd';
            const hourName = 'u';
            const minuteName = 'm';

            if (isNil(minutes)) {
                return '0 dagen';
            }

            let belowZero = false;
            if (minutes < 0) {
                belowZero = true;
                minutes = -minutes;
            }

            const hours = parseInt((Number(minutes) / 60) as any);
            const days = Math.floor(hours / 8) as any;
            const hoursDiff = Number(hours % 8);
            const minutesDiff = Number(minutes % 60);

            if (minutesDiff === 0) {
                return (belowZero ? '-' : '') +days + dayName + ' ' + hoursDiff + hourName;
            }

            return (belowZero ? '-' : '') +days + dayName+ ' '  + hoursDiff + hourName + ' ' + minutesDiff + minuteName;
        }

        function hexDecimal(hexString: string) {
            hexString = (hexString + '').replace(/[^a-f0-9]/gi, '')
            return parseInt(hexString, 16)
        }

        app.config.globalProperties.$timeToColor = (user: User, day: any , minutes: number) => {
            day = moment(day).format('dddd').toLowerCase();

            if (user?.working_hours) {
                const times = (user?.working_hours[day]);

                let totalMinutes = 0;

                Object.keys(times).forEach(function(key) {
                    times[key] ? totalMinutes += 30 : null
                })

                if (minutes < (totalMinutes * 0.625)) {
                    return 'red';
                } else if(minutes < (totalMinutes * 0.875)) {
                    return 'orange';
                }

                return 'green';
            }
        }

        app.config.globalProperties.$calculateTextColor = (backgroundColor: string, darkText = '#333', lightText = '#fff'): string => {
            backgroundColor = backgroundColor.replace("#", "");
            const r = parseInt(backgroundColor.substring(0,2),16);
            const g = parseInt(backgroundColor.substring(2,2),16);
            const b = parseInt(backgroundColor.substring(4,2),16);

            const yiq = ((r*299)+(g*587)+(b*114))/1000;

            return (yiq >= 128) ? darkText : lightText;
        }
    }
}
