<template>
    <div class="card">
        <div class="card-header">
            <strong>Wachtwoord aanpassen</strong>
        </div>
        <div class="card-body">
            <form @submit.prevent="resetNow">
                <div class="form-group row">
                    <label for="password" class="col-12 col-form-label pt-0">
                        Wachtwoord
                    </label>

                    <div class="col-12">
                        <input id="password" type="password" class="form-control" required autocomplete="password" autofocus v-model="form.password" v-validate:[form.errors]="'password'">
                        <error field="password" :bag="form.errors"></error>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="password" class="col-12 col-form-label pt-0">
                        Wachtwoord bevestigen
                    </label>

                    <div class="col-12">
                        <input id="password_confirmation" type="password" class="form-control" required autocomplete="password_confirmation" autofocus v-model="form.password_confirmation" v-validate:[form.errors]="'password_confirmation'">
                        <error field="password_confirmation" :bag="form.errors"></error>
                    </div>
                </div>

                <div class="form-group row mb-0">
                    <div class="col-12">
                        <button type="submit" class="btn btn-primary" :disabled="form.working">Opslaan</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {reactive} from "vue";
import {toast} from "../../plugins/toast/toast";

const form = reactive({
    password: null,
    password_confirmation: null,

    working: false,
    errors: null,
});

const resetNow = () => {
    form.errors = null;
    form.working = true;

    window.api.post('/account/reset-password', form)
        .then(() => {
            toast('success', 'Your password has been changed successfully!');
        })
        .catch(e => {
            if (e.response.status === 422) {
                form.errors = e.response.data.errors;
            }
        }).finally(() => {
        form.working = false
    });
}
</script>

<style scoped>

</style>
