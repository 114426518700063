<template>
    <div>
        <div class="card customer-projects">
            <FurloughNavigation />
            <div class="card-body">
                <div class="ms-auto d-flex flex-wrap justify-content-end align-items-center">
                    <UserSelect :selected-users="this.user ? [this.user.id] : []" @answer="setUser" />
                    <div class="px-1 rounded cursor-pointer">
                        <select class="form-select form-select-sm" v-model="year" @change="fetchData">
                            <option value="">Alle jaren</option>
                            <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                        </select>
                    </div>
                    <div class="px-1 rounded cursor-pointer">
                        <button class="btn btn-secondary btn-sm " data-tooltip="Iedereen" @click="unsetUser()">
                            Reset
                        </button>
                    </div>
                </div>
                <div class="mb-4" v-for="(requests, month) of requestsGroupedByMonth" :key="month">
                    <h4>{{ month }}</h4>
                    <table class="table table-sm">
                        <thead>
                        <tr>
                            <th class="fs-small">Gebruiker</th>
                            <th class="fs-small">Omschrijving</th>
                            <th class="fs-small">Start</th>
                            <th class="fs-small">Eind</th>
                            <th class="fs-small">Tijd</th>
                            <th class="fs-small"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="request of requests" :key="request.id">
                            <td class="col-2">
                                <div v-if="request.user" class="d-flex align-self-center">
                                    <img :src="request.user.gravatar" :style="'border: 1px solid' + request.user.color" class="gravatar-nano me-3" />
                                    <span>{{ request.user.name }}</span>
                                </div>
                                <span v-else>Geen gebruiker...</span>
                            </td>
                            <td class="col-4">
                                <template v-if="$gate.allows('descriptions', 'furlough')">
                                    {{ request.description }}
                                </template>
                                <template v-else>
                                    ...
                                </template>
                            </td>
                            <td class="col-2">
                                <small>{{ $moment(request.start).format(this.user ? 'ddd D MMM YYYY HH:mm' : 'ddd D MMM HH:mm') }}</small>
                            </td>
                            <td class="col-2">
                                <small>{{ $moment(request.end).format(this.user ? 'ddd D MMM YYYY HH:mm' : 'ddd D MMM HH:mm') }}</small>
                            </td>
                            <td class="col-1">
                                {{ $timeFormatHoursMinutes(request.minutes) }}
                            </td>
                            <td class="col-1 text-end">
                                <button class="btn btn-danger btn-sm" v-if="$gate.allows('accept', 'furlough')" @click="deleteFurlough(request)">
                                    <icon icon="trash"></icon>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                        <tbody v-if="total">
                        <tr>
                            <td colspan="3"></td>
                            <td>
                                <strong>Totaal</strong>
                            </td>
                            <td colspan="3">
                                <strong>{{ $timeFormatHoursMinutes(total) }}</strong> <span v-if="balance && $gate.allows('accept', 'furlough')" class="ms-2 text-muted">Balans {{ $timeFormatHoursMinutes(balance) }}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <Pagination v-if="data !== null" :data="data" @pagination-change-page="fetchFurloughs" :limit="2" align="center" class="mt-3" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FurloughNavigation from "../../components/FurloughNavigation.vue";
import UserSelect from "../../components/AvatarUserSelect.vue";
import User from "../../store/Models/User";
import Furlough from "../../store/Models/Furlough";
import Pagination from 'laravel-vue-pagination';
import {toast} from "../../plugins/toast/toast";
import {isNil} from "lodash";

// last 4 years
const currentYear = new Date().getFullYear();

export default defineComponent({
    components: {FurloughNavigation, UserSelect, Pagination},
    data() {
        return {
            requests: [] as Furlough[],
            requestsGroupedByMonth: [] as Furlough[][],
            data: null,
            year: '',
            years: [currentYear, currentYear - 1, currentYear - 2, currentYear - 3],
            user: null as User | null,
            balance: null,
            total: null,
        }
    },
    computed: {
        users(): Array<User> {
            return User.query()
                .get();
        },
    },
    methods: {
        unsetUser() {
            this.user = null;
            this.$router.replace({name: 'furlough.history_overview'});
            this.fetchData();
        },
        setUser(user: User) {
            this.user = user;
            this.$router.replace({name: 'furlough.history_overview', query: {user: this.user.id}});
            this.fetchData();
        },
        deleteFurlough(request: Furlough) {
            Furlough.api().delete('furloughs/' + request.id, {
                delete: request.id
            })
                .then(() => {
                    toast('success', 'Je hebt succesvol het verlof verwijderd.');
                });
        },
        fetchData() {
            Furlough.deleteAll();

            this.fetchFurloughs();
        },
        fetchFurloughs(page = 1) {
            Furlough.api().get('furloughs', {
                params: {
                    'page[number]': page,
                    'page[size]': 30,
                    'accepted': true,
                    'filter[user_id]': this.user ? this.user.id : null,
                    'filter[year]': this.year ? this.year : null,
                    'include': 'user',
                }
            })
                .then(result => {
                    this.data = result.response.data;
                    if (result.response.data.hasOwnProperty('total')) {
                        this.total = result.response.data.total;
                    } else {
                        this.total = null;
                    }

                    if (result.response.data.hasOwnProperty('balance')) {
                        this.balance = result.response.data.balance;
                    } else {
                        this.balance = null;
                    }

                    this.requests = Furlough.query()
                        .whereIdIn(result.response.data.data.map((furlough: any) => furlough.id))
                        .with('user')
                        .orderBy('start', 'desc')
                        .get();

                    this.requestsGroupedByMonth = window._.groupBy(this.requests, (request: Furlough) => {
                        return this.user ? '' : this.$moment(request.start).format('MMMM YYYY');
                    });

                });
        }
    },
    created() {
        if (!isNil(this.$route.query.user)) {

            // add user from url to calendar
            const userId = this.$route.query.user;
            User.api().get('users/'+userId).then(() => {
                const user = User.find(userId);
                this.setUser(user);
            });

        } else {
            this.fetchData();
        }

    }
});
</script>

<style scoped>

</style>
