import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";
import Role from "./Role";
import FurloughBalance from "./FurloughBalance";
import Deadline from "./Deadline";

export default class User extends Model {
    static entity = 'users';

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            initials: this.string(''),
            email: this.string(''),
            gravatar: this.string(''),
            color: this.string(''),
            is_checked_in: this.boolean(false),
            should_checkin_today: this.boolean(false),
            checked_in_since: this.attr(null),
            working_hours: this.attr(null),
            date_employed: this.string(null).nullable(),
            birthday: this.string(null).nullable(),
            on_screen: this.boolean(false),
            deferred: this.boolean(false),
            wants_small_screen: this.boolean(false),

            two_factor_confirmed_at: this.string(null).nullable(),

            deleted_at: this.string(null).nullable(),

            roles: this.attr([]),
            permissions: this.attr([]),
            teams: this.attr([]),

            furlough_balances: this.hasMany(FurloughBalance, 'user_id'),
            deadlines: this.hasMany(Deadline, 'user_id'),

        }
    }

    declare id: number;
    declare name: string;
    declare initials: string;
    declare email: string;
    declare gravatar: string;
    declare color: string;
    declare is_checked_in: boolean;
    declare should_checkin_today: boolean;
    declare checked_in_since: any;
    declare working_hours: any;
    declare date_employed: string | null;
    declare birthday: string | null;
    declare on_screen: boolean;
    declare deferred: boolean;
    declare wants_small_screen: boolean;

    declare roles: Array<any>;
    declare permissions: Array<any>;
    declare teams: Array<any>;
    declare furlough_balances: Array<FurloughBalance>;
    declare deadlines: Array<Deadline>;

    declare two_factor_confirmed_at: string|null;
    declare deleted_at: string|null;

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'name',
    }

    setRoles(): Promise<void> {
        return new Promise(resolve => {
            window.api.get('users/'+this.id+'/roles')
                .then(response => {
                    this.roles = response.data.map((r: Role) => r.id);
                    resolve();
                });
        });
    }

    setPermissions(): Promise<void> {
        return new Promise(resolve => {
            User.update({
                where: this.id,
                data: {permissions: []}
            });

            window.api.get('users/'+this.id+'/permissions')
                .then(response => {
                    User.update({
                        where: this.id,
                        data: {
                            permissions: response.data.map((p: any) => p.name)
                        }
                    });
                    resolve();
                });
        });
    }

    hasPermissionTo(permission: string): boolean {
        return this.permissions.includes(permission);
    }
}
