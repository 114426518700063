<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">Gebruikers</li>
                </ol>
            </nav>

            <div id="buttons" class="ms-auto text-md-right align-self-center">
                <router-link v-if="$gate.allows('create', 'user')" :to="{name: 'management.users.create'}" class="btn btn-sm btn-primary">
                    Nieuwe gebruiker
                    <icon class="ms-1" icon="plus"></icon>
                </router-link>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <ul role="tablist" id="userTab" class="nav nav-tabs card-header-tabs">
                    <li class="nav-item">
                        <button class="nav-link active" id="active-tab" data-bs-toggle="tab" data-bs-target="#active" type="button" role="tab" aria-controls="active" aria-selected="true">
                            Actieve gebruikers <span class="badge rounded-pill bg-primary">{{ users.length }}</span>
                        </button>
                    </li>
                    <li class="nav-item">
                        <button class="nav-link" id="inactive-tab" data-bs-toggle="tab" data-bs-target="#inactive" type="button" role="tab" aria-controls="inactive" aria-selected="false">
                            Inactieve gebruikers <span class="badge rounded-pill bg-primary">{{ deletedUsers.length }}</span>
                        </button>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content" id="userTabContent">
                    <div id="active" class="tab-pane fade show active" role="tabpanel" aria-labelledby="active-tab">
                        <table class="table table-hover table-align-middle mb-0">
                            <thead>
                            <tr>
                                <th scope="col" width="40">#</th>
                                <th scope="col">Naam</th>
                                <th scope="col">E-mailadres</th>
                                <th scope="col">Rol</th>
                                <th scope="col">Team</th>
                                <th scope="col">Infoscherm</th>
                                <th scope="col" style="width: 1px;"></th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user of users" :key="user.id">
                                    <td>{{ user.id }}</td>
                                    <td>{{ user.name }}</td>
                                    <td>{{ user.email }}</td>
                                    <td>
                                        <span v-for="role of user.roles" :key="role.id" class="badge rounded-pill bg-primary me-1">{{ role.name }}</span>
                                    </td>
                                    <td>
                                        <span v-for="team of user.teams" :key="team.id" class="badge rounded-pill bg-primary me-1">{{ team.name }}</span>
                                    </td>
                                    <td>
                                        <span v-if="user.on_screen" class="badge rounded-pill bg-success">Ja</span>
                                        <span v-else class="badge rounded-pill bg-danger">Nee</span>
                                    </td>
                                    <td class="d-flex">
                                        <router-link v-if="$gate.allows('update', user)" :to="{name: 'management.users.edit', params: {user: user.id}}" class="btn btn-sm btn-primary me-2">
                                            <icon icon="pen-to-square"></icon>
                                        </router-link>
                                        <button v-if="$gate.allows('delete', user)" class="btn btn-sm btn-danger" @click="deleteUser(user.id)">
                                            <icon icon="trash"></icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div id="inactive" class="tab-pane fade" role="tabpanel" aria-labelledby="inactive-tab">
                        <table class="table table-hover table-align-middle mb-0">
                            <thead>
                            <tr>
                                <th scope="col" width="40">#</th>
                                <th scope="col">Naam</th>
                                <th scope="col">E-mailadres</th>
                                <th scope="col">Rol</th>
                                <th scope="col">Team</th>
                                <th scope="col" style="width: 1px;"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="user of deletedUsers" :key="user.id">
                                <td>{{ user.id }}</td>
                                <td>{{ user.name }}</td>
                                <td>{{ user.email }}</td>
                                <td>
                                    <span v-for="role of user.roles" :key="role.id" class="badge rounded-pill bg-primary me-1">{{ role.name }}</span>
                                </td>
                                <td>
                                    <span v-for="team of user.teams" :key="team.id" class="badge rounded-pill bg-primary me-1">{{ team.name }}</span>
                                </td>
                                <td class="d-flex">
                                    <button v-if="$gate.allows('restore', user)" class="btn btn-sm btn-success d-flex" @click="restoreUser(user.id)">
                                        <icon icon="trash-arrow-up" class="me-2 align-self-center"></icon> Herstellen
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import User from "../../../store/Models/User";

export default defineComponent({
    computed: {
        users(): Array<User> {
            return User.query()
                .where('deleted_at', null)
                .get();
        },
        deletedUsers(): Array<User> {
            return User.query()
                .where((user: User) => {
                    return user.deleted_at !== null;
                })
                .get();
        }
    },
    methods: {
        deleteUser(id: number) {
            User.api().delete('users/'+id, {
                delete: id,
            })
            .then(() => {
                this.$toast('success', 'De gebruiker is succesvol verwijderd.');
            });
        },
        restoreUser(id: number) {
            User.api().put('users/'+id+'/restore')
                .then(() => {
                    this.$toast('success', 'De gebruiker is succesvol verwijderd.');
                });
        }
    },
    created() {
        User.api().get('users', {
            params: {
                with_trashed: true,
                include: 'roles,teams',
            }
        });
    }
});
</script>

<style scoped>

</style>
