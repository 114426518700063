<template>
    <form @submit.prevent="save">
        <div v-if="deadline !== null" class="modal fade" :id="'editDeadline_'+deadline.id" tabindex="-1" :aria-labelledby="'editDeadlineLabel_'+deadline.id" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" :id="'editDeadline_'+deadline.id">Deadline aanpassen</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="description" class="form-label">Omschrijving</label>
                            <input class="form-control" type="text" v-model="form.description" v-validate:[form.errors]="'description'" style="min-height: 50px">
                            <error field="description" :bag="form.errors"></error>
                        </div>
                        <div class="mb-3">
                            <label for="date" class="form-label">Datum</label>
                            <input class="form-control w-50 me-2 flex-shrink-1" type="date" v-model="form.date" v-validate:[form.errors]="'date'" />
                        </div>
                        <div class="mb-3">
                            <label for="date" class="form-label">Opmerking</label>
                            <textarea class="form-control" v-model="form.note" v-validate:[form.errors]="'note'" style="min-height: 50px"></textarea>
                            <error field="note" :bag="form.errors"></error>
                        </div>
                        <div class="mb-3">
                            <label for="date" class="form-label">Scrum status</label>
                            <select class="form-select" v-model="form.scrum_status" v-validate:[form.errors]="'scrum_status'">
                                <option v-for="status of scrumStatus" :key="status.value" :value="status.value">{{ status.title }}</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="user_id" class="form-label">Gebruiker</label>
                            <UserSelect
                                :selected="form.user_id"
                                @answer="(response) => form.user_id = response.id"
                                v-validate:[form.errors]="'user_id'"
                            ></UserSelect>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Team</label>
                            <select class="form-select" v-model="form.team_id" v-validate:[form.errors]="'team_id'">
                                <option v-for="team of teams" :key="team.id" :value="team.id">{{ team.name }}</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <div class="form-check form-switch me-2">
                                <input class="form-check-input" type="checkbox" v-model="form.is_finished" v-validate:[form.errors]="'is_finished'" />
                                <label class="form-check-label">Afgerond</label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="form.working">Annuleren</button>
                        <button type="button" class="btn btn-danger" @click="deleteEvent" :disabled="form.working">Verwijder</button>
                        <button type="submit" class="btn btn-primary" :disabled="form.working">Opslaan</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script lang="ts">
import {defineComponent, Prop} from "vue";
import Deadline from "../../store/Models/Deadline";
import UserSelect from "../UserSelect.vue";
import Team from "../../store/Models/Team";

export default defineComponent({
    components: {UserSelect},
    props: {
        deadline: Object as Prop<Deadline>,
    },
    data() {
        return {
            form: {
                description: null,
                date: null,
                note: null,
                scrum_status: null,
                user_id: null,
                team_id: null,
                is_finished: false,

                errors: null,
                working: false,
            } as any,
            teams: [],
            scrumStatus: [
                {
                    'title': 'New',
                    'value': 'new',
                },
                {
                    'title': 'Active',
                    'value': 'active',
                },
                {
                    'title': 'Test internal',
                    'value': 'test_internal',
                },
                {
                    'title': 'Test external',
                    'value': 'test_external',
                },
                {
                    'title': 'On hold',
                    'value': 'on_hold',
                },
                {
                    'title': 'Finished',
                    'value': 'finished',
                },
            ]
        }
    },
    emits: [
        'saved', 'deleted',
    ],
    watch: {
        deadline: {
            handler(deadline) {
                if (deadline === null) {
                    deadline = {};
                }

                for (const item in this.form) {
                    if (['errors', 'working'].includes(item)) {
                        continue;
                    }

                    this.form[item] = deadline[item] ?? '';
                }
            },
            deep: true,
            immediate: false
        }
    },
    methods: {
        deleteEvent() {
            Deadline.api().delete('deadlines/'+this.deadline?.id)
                .then(() => {
                    this.$toast('success', 'De deadline is verwijderd.', 1);
                    this.$emit('deleted');

                    const modal = window.bootstrap.Modal.getOrCreateInstance(document.getElementById('editDeadline_'+this.deadline?.id));
                    modal.hide();
                });
        },
        save() {
            Deadline.api().put('deadlines/'+this.deadline?.id, this.form)
                .then(response => {
                    this.$toast('success', 'Je agenda item is opgeslagen.', 1);
                    this.$emit('saved', response.entities?.events[0]);

                    this.form = {
                        title: null,
                        description: null,

                        errors: null,
                        working: false,
                    };
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.form.errors = e.response.data.errors;
                    }
                })
                .finally(() => {
                    this.form.working = false;

                    const modal = window.bootstrap.Modal.getOrCreateInstance(document.getElementById('editDeadline_'+this.deadline?.id))
                    modal.hide();
                    this.$emit('saved');
                });
        },
        fetchTeams() {
            Team.api().get('teams')
                .then(result => {
                    this.teams = result.response.data.data;
                });
        },
    },
    created() {
        this.fetchTeams();
    }
});
</script>

<style scoped>

</style>
