<template>
    <form id="password-confirm-form" @submit.prevent="confirmPassword" class="my-3">
        <hr />
        <p>Voordat je verder kunt moet je eerst je wachtwoord nogmaals invullen, zodat we zeker zijn dat je deze actie uit mag voeren.</p>

        <label for="confirm-password">Bevestig je wachtwoord</label>
        <input class="form-control" id="confirm-password" type="password" name="password" required autocomplete="current-password" v-model="form.password" v-validate:[form.errors]="'password'">
        <error field="password" :bag="form.errors"></error>

        <button class="btn btn-primary mt-2" type="submit" form="password-confirm-form" :disabled="form.working">Verder</button>
        <hr />
    </form>
</template>

<script lang="ts" setup>
import {reactive} from "vue";

const form = reactive({
    password: null,

    errors: null,
    working: false,
});

const emit = defineEmits(['confirmed']);

const confirmPassword = () => {
    form.errors = null;
    form.working = true;

    window.axios.post('/user/confirm-password', form)
        .then(() => {
            emit('confirmed');
        })
        .catch(e => {
            if (e.response.status === 422) {
                form.errors = e.response.data.errors;
            }
        })
        .finally(() => {
            form.working = false
        });
}

</script>

<style scoped>

</style>
