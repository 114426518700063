<template>
    <div v-for="user in users" :key="user.id" class="my-1 me-1">
        <span v-if="selectedUsers.includes(user.id) && !isAdding" class="badge rounded-pill d-flex align-items-center cursor-pointer me-1" :style="'background-color: '+user.color">
            <img class="ms-n1 gravatar-agenda" style="width: 24px; height: auto;" :src="user.gravatar" />
            <span class="ms-1" style="margin-bottom:-1px;font-weight:400;font-size:0.75rem;">{{ user.initials }}</span>
        </span>
        <span v-if="!selectedUsers.includes(user.id)" class="badge rounded-pill d-flex align-items-center cursor-pointer me-1" :style="'background-color: #fff'" @click="setAnswer(user)">
            <img class="ms-n1 gravatar-agenda" :style="'width: 24px; height: auto; background-color:'+user.color" :src="user.gravatar" />
            <span class="ms-1" :style="'margin-bottom:-1px;font-weight:400;font-size:0.75rem;color: '+user.color" :data-tooltip=user.name>{{ user.initials }}</span>
        </span>
    </div>
</template>

<script>
import User from "../store/Models/User";

export default {
    props: {
        selected: Number|String,
        numericSelect: Boolean,
        selectedUsers: Array | null,
        isAdding: Boolean
    },
    emits: ['answer'],
    data() {
        return {
            user: null,
            users: [],
            excludedUsers: [1], // 1:Bart
        }
    },
    watch: {
        selected: {
            handler(selected) {
                if (selected === null) {
                    return;
                }

                if (typeof selected === 'number') {
                    User.api().get('users/'+selected).then(() => {
                        this.user = User.find(selected);
                    });
                } else {
                    this.user = selected;
                }
            },
            immediate: false,
        }
    },
    methods: {
        fetchUsers() {
            User.api().get('users')
                .then(() => {
                    if (this.excludedUsers.length) {
                        this.users = User.query().where(user => {
                            if (user.deleted_at !== null) {
                                return false;
                            }

                            return !this.excludedUsers.includes(user.id);
                        }).orderBy('deferred').orderBy('name').get();
                    } else {
                        this.users = User.query().where(user => {
                            if (user.deleted_at !== null) {
                                return false;
                            }
                        }).orderBy('deferred').orderBy('name').get();
                    }
                })
        },
        setAnswer(value) {
            this.$emit('answer', value);
        }
    },
    created() {
        this.fetchUsers();
    }
}
</script>

<style scoped>

</style>
