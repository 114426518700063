import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";
import User from "./User";

export default class FurloughBalanceMutation extends Model {
    static entity = 'furlough_balance_mutations';

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            year: this.number(0),
            minutes: this.number(0),
            description: this.string(''),

            created_at: this.string(false),
            updated_at: this.string(false),

            user: this.belongsTo(User, 'user_id'),
        }
    }

    id!: number;
    user_id!: number;
    year!: number;
    minutes!: number;
    description!: string;
    created_at!: string;
    updated_at!: string;

    user!: User;

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'description',
    }
}
