<template>
    <div>
        <table class="table table-striped">
            <tbody>
            <tr>
                <td class="fw-bold">Omschrijving</td>
                <td>
                    {{ deadline?.description }}
                </td>
            </tr>
            <tr>
                <td class="fw-bold">Datum</td>
                <td>
                    {{ $moment(deadline?.date).format('DD-MM-yyyy') }}
                </td>
            </tr>
            <tr>
                <td class="fw-bold">Gebruiker</td>
                <td class="d-flex align-items-center py-3">
                    <img :src="deadline?.user.gravatar" class="gravatar-nano me-2" :style="'border: 1px solid ' + deadline?.user.color">
                    {{ deadline?.user.name }}
                </td>
            </tr>
            </tbody>

        </table>
    </div>
</template>

<script lang="ts">
import {defineComponent, Prop} from "vue";
import Deadline from "../store/Models/Deadline";
import {Item} from "@vuex-orm/core";

export default defineComponent({
    props: {
        deadline_id: Number as Prop<number>,
    },
    computed: {
        deadline(): Item<Deadline> {
            return Deadline.query()
                .with('user')
                .where('id', this.deadline_id)
                .first();
        },
    },
    watch: {
        deadline_id: {
            handler(id: number) {
                Deadline.api().get('deadlines/'+id+'?include=user');
            },
            immediate: true,
        }
    }
})
</script>

<style scoped>

</style>
