<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">Rollen</li>
                </ol>
            </nav>

            <div id="buttons" class="ms-auto text-md-right align-self-center">
                <router-link v-if="$gate.allows('create', 'role')" :to="{name: 'management.roles.create'}" class="btn btn-sm btn-primary">
                    Nieuwe rol
                    <icon class="ms-1" icon="plus"></icon>
                </router-link>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <table class="table table-hover table-align-middle mb-0">
                    <thead>
                    <tr>
                        <th scope="col" width="40">#</th>
                        <th scope="col">Naam</th>
                        <th scope="col" style="width: 1px;"></th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="role of roles" :key="role.id">
                            <td width="40">{{ role.id }}</td>
                            <td>{{ role.name }}</td>
                            <td class="d-flex">
                                <router-link v-if="$gate.allows('update', role)" :to="{name: 'management.roles.edit', params: {role: role.id}}" class="btn btn-sm btn-primary me-2">
                                    <icon icon="pen-to-square"></icon>
                                </router-link>
                                <button v-if="$gate.allows('delete', role)" class="btn btn-sm btn-danger" @click="deleteRole(role.id)">
                                    <icon icon="trash"></icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Role from "../../../store/Models/Role";

export default defineComponent({
    computed: {
        roles(): Array<Role> {
            return Role.all();
        }
    },
    methods: {
        deleteRole(id: number) {
            Role.api().delete('roles/'+id, {
                delete: id,
            });
        }
    },
    created() {
        Role.api().get('roles');
    }
});
</script>

<style scoped>

</style>
