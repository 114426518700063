<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">Uren</li>
                </ol>
            </nav>

            <div id="buttons" class="ms-auto text-md-right align-self-center">
                <div class="btn-group">
                    <button class="btn btn-primary m-0" @click="previous()">
                        <icon icon="arrow-left"></icon>
                    </button>
                    <button class="btn btn-primary m-0" disabled>
                        {{ $moment(startDate).format('DD-MM-yyyy') }} - {{ $moment(endDate).format('DD-MM-yyyy') }}
                    </button>
                    <button class="btn btn-primary m-0" @click="next()">
                        <icon icon="arrow-right"></icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <div v-if="loading" class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
                </div>

                <template v-else>
                    <div v-if="data !== null" class="times-week">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Gebruiker</th>
                                    <th v-for="day of data.dates" :key="day">{{ $moment(day).format('DD-MM-yyyy') }}</th>
                                    <th>Totaal</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user of data.users" :key="user" class="position-relative">
                                    <td>
                                        <img :src="user.gravatar" class="gravatar-nano me-2" :style="'border: 1px solid ' + user.color" :alt="user.name">
                                        {{ user.name }}
                                    </td>
                                    <td v-for="day of data.dates" :key="day">
                                        <span :style="'color: ' + $timeToColor(user, day, user.times[day])">
                                            {{ $timeFormatHoursMinutes(user.times[day]) }}
                                        </span>
                                    </td>
                                    <td>{{ $timeFormatHoursMinutes(user.total) }}</td>
                                    <td><a href="#" class="stretched-link" @click="fetchUserWeekHours(user.id)"></a></td>
                                </tr>
                                <tr>
                                    <td colspan="6"></td>
                                    <td>
                                        <strong>{{ $timeFormatHoursMinutes(data.total) }}</strong> ({{ $timeFormatHoursMinutes(data.total_average) }})
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </div>
        </div>

        <div class="card" v-if="detailShown" id="detailCard">
            <div class="card-body">
                <div v-if="detailLoading" class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
                </div>

                <template v-else>
                    <div v-if="detailData !== null" class="times-week">
                        <table class="table table-hover">
                            <thead>
                            <tr>
                                <th>Uren</th>
                                <th>Project</th>
                                <th>Omschrijving</th>
                                <th>Tijdstip</th>
                            </tr>
                            </thead>
                            <tbody v-for="(timeGroup, key, index) of detailData" :key="index">
                                <tr>
                                    <td colspan="4">
                                        <strong>{{ moment(key).format('dd D MMM') }}</strong>
                                    </td>
                                </tr>
                                <tr v-for="time of timeGroup" :key="time.id">
                                    <td>{{ $timeFormatHoursMinutes(time.minutes) }}</td>
                                    <td>
                                        <router-link :to="{name: 'projects.show', params: {project: time.project.id}}">[{{ time.project.customer.code }}] {{ time.project.name }}</router-link>
                                    </td>
                                    <td>
                                        <div class="text-clamp" :title="time.description">
                                            {{ time.description }} <span class="badge bg-secondary" v-if="time.is_not_billable">#niet-facturabel</span>
                                        </div>
                                    </td>
                                    <td>
                                        <span>{{ momentTz(momentTz.utc(time.created_at, 'YYYY-MM-DDThh:mm:ss')).tz('Europe/Amsterdam').format('HH:mm') }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {ref} from "vue";
import Time from "../../store/Models/Time";
import {groupBy} from "lodash";
import moment from "moment/min/moment-with-locales";
moment.locale('nl');
import momentTz from "moment-timezone";


const data = ref<any>(null);
const loading = ref<boolean>(false);

const detailLoading = ref<boolean>(false);
const detailShown = ref<boolean>(false);
const detailData = ref<any>(null);

const startDate = ref<string>(window.moment().startOf('week').format('Y-MM-DD'));
const endDate = ref<string>(window.moment().endOf('week').subtract(2, 'day').format('Y-MM-DD'));

const previous = () => {
    const newStartDate = window.moment(startDate.value).subtract(1, 'week').startOf('week');
    const newEndDate = window.moment(newStartDate).endOf('week').subtract(2, 'day');

    startDate.value = newStartDate.format('Y-MM-DD');
    endDate.value = newEndDate.format('Y-MM-DD');

    fetchForGivenDates();
}

const next = () => {
    const newStartDate = window.moment(startDate.value).add(1, 'week').startOf('week');
    const newEndDate = window.moment(newStartDate).endOf('week').subtract(2, 'day');

    startDate.value = newStartDate.format('Y-MM-DD');
    endDate.value = newEndDate.format('Y-MM-DD');

    fetchForGivenDates();
}

const fetchForGivenDates = () => {
    loading.value = true;

    window.api.get('hours-overview', {
        params: {
            start: startDate.value,
            end: endDate.value,
        }
    })
        .then(response => {
            data.value = response.data;
        })
        .finally(() => {
            loading.value = false;
        })
}

fetchForGivenDates();


const fetchUserWeekHours = (id) => {
    detailLoading.value = true;
    detailShown.value = true;

    Time.api().get('times', {
        params: {
            include: 'project,project.customer',
            'filter[user_id]': id,
            'filter[date_between]': startDate.value + ',' + endDate.value,
        },
    })
        .then(result => {
            // group items by date
            detailData.value = groupBy(result.response.data.data, 'date');

            // scroll to detail
            setTimeout(() => {
                const detailCard = document.querySelector('#detailCard');
                detailCard.scrollIntoView({behavior: 'smooth'});
            }, 200);
        })
        .finally( () => {
            detailLoading.value = false;
        });
}
</script>

<style scoped>

</style>
