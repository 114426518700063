<template>
    <div class="card-body" style="background: #efefef; margin: -1px; padding: 0;">
        <div class="row">
            <div class="col-lg-5 col-md-8 mx-md-auto">
                <div class="card">
                    <div class="card-header">
                        <strong>Wachtwoord herstellen</strong>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="send">
                            <div class="form-group row">
                                <label for="password" class="col-12 col-form-label pt-0">
                                    Wachtwoord
                                </label>

                                <div class="col-12">
                                    <input id="password" type="password" class="form-control" required autocomplete="password" autofocus v-model="form.password" v-validate:[form.errors]="'password'">
                                    <error field="password" :bag="form.errors"></error>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="password" class="col-12 col-form-label pt-0">
                                    Wachtwoord bevestigen
                                </label>

                                <div class="col-12">
                                    <input id="password_confirmation" type="password" class="form-control" required autocomplete="password_confirmation" autofocus v-model="form.password_confirmation" v-validate:[form.errors]="'password_confirmation'">
                                    <error field="password_confirmation" :bag="form.errors"></error>
                                </div>
                            </div>

                            <div class="form-group row mb-0">
                                <div class="col-12">
                                    <button type="submit" class="btn btn-primary" :disabled="form.working">Reset nu</button>
                                    <router-link :to="{name: 'auth.login'}" class="btn btn-link" :class="{disabled: form.working}" :disabled="form.working">Toch inloggen?</router-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    data() {
        return {
            form: {
                password: null,
                password_confirmation: null,

                working: false,
                errors: null,
            }
        }
    },
    methods: {
        send() {
            this.form.errors = null;
            this.form.working = true;

            window.axios.post('/reset-password?token=' + this.$route.params.token, Object.assign(this.form, {
                email: this.$route.params.email,
            }))
                .then(() => {
                    this.$toast('success', 'Your password has been changed successfully!');
                    this.$router.push({
                        name: 'auth.login',
                    });
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.form.errors = e.response.data.errors;
                    }
                }).finally(() => {
                this.form.working = false
            });
        }
    },
    mounted() {
        this.$isLoggedIn(false).then((isLoggedIn: boolean) => {
            if (isLoggedIn) {
                this.$router.push({
                    name: 'dashboard',
                });
            }
        });
    }
});
</script>

<style scoped>

</style>
