<template>
    <form @submit.prevent="save">
        <div v-if="event !== null" class="modal fade" :id="'editEvent_'+user.id" tabindex="-1" :aria-labelledby="'editEventLabel_'+user.id" aria-hidden="true">
            <div class="modal-dialog" :class="{'modal-xl': !event.is_private}">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" :id="'editEventLabel_'+user.id">Agenda item aanpassen</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <h5 v-if="!event.is_private">Agenda info</h5>
                        <div v-if="event.is_private" class="mb-3">
                            <label for="title" class="form-label">Titel</label>
                            <input class="form-control" v-model="form.title" v-validate:[form.errors]="'title'" />
                            <error field="title" :bag="form.errors"></error>
                        </div>

                        <div class="mb-3">
                            <label for="description" class="form-label">Omschrijving</label>
                            <textarea class="form-control" v-model="form.description" v-validate:[form.errors]="'description'" style="min-height: 50px"></textarea>
                            <error field="description" :bag="form.errors"></error>
                        </div>

                        <div v-if="!event.is_private">
                            <div class="mt-4">
                                <h5>Deadline info</h5>
                                <DeadlineInformation :deadline_id="event.deadline_id"></DeadlineInformation>
                            </div>

                            <div class="mt-4">
                                <h5>Project info</h5>
                                <ProjectInformation :project_id="event.deadline.budget.project_id" :small="true" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="form.working">Annuleren</button>
                        <button type="button" class="btn btn-danger" @click="deleteEvent" :disabled="form.working">Verwijder</button>
                        <button type="submit" class="btn btn-primary" :disabled="form.working">Opslaan</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script lang="ts">
import {defineComponent, Prop} from "vue";
import Event from "../../store/Models/Event";
import User from "../../store/Models/User";
import ProjectInformation from "../Project/ProjectInformation.vue";
import DeadlineInformation from "../DeadlineInformation.vue";

export default defineComponent({
    components: {DeadlineInformation, ProjectInformation},
    props: {
        user: Object as Prop<User>,
        event: Object as Prop<Event>,
    },
    data() {
        return {
            form: {
                title: null,
                description: null,

                errors: null,
                working: false,
            } as any,
        }
    },
    emits: [
        'saved', 'deleted',
    ],
    watch: {
        event: {
            handler(event) {
                if (event === null) {
                    event = {};
                }

                for (const item in this.form) {
                    if (['errors', 'working'].includes(item)) {
                        continue;
                    }

                    this.form[item] = event[item] ?? '';
                }
            },
            deep: true,
            immediate: false
        }
    },
    methods: {
        deleteEvent() {
            Event.api().delete('users/'+this.user?.id+'/events/'+this.event?.id, {
                delete: this.event?.id,
            })
                .then(() => {
                    this.$toast('success', 'Je agenda item is verwijderd.', 1);
                    this.$emit('deleted', this.event?.id);

                    const modal = window.bootstrap.Modal.getOrCreateInstance(document.getElementById('editEvent_'+this.user?.id))
                    modal.hide();
                });
        },
        save() {
            Event.api().put('users/'+this.user?.id+'/events/'+this.event?.id, this.form)
                .then(response => {
                    this.$toast('success', 'Je agenda item is opgeslagen.', 1);
                    this.$emit('saved', response.entities?.events[0]);

                    this.form = {
                        title: null,
                        description: null,

                        errors: null,
                        working: false,
                    };
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.form.errors = e.response.data.errors;
                    }
                })
                .finally(() => {
                    this.form.working = false;
                });
        }
    },
});
</script>

<style scoped>

</style>
