<template>
    <div>
        <div v-if="customer !== null" class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <router-link class="breadcrumb-item" :to="{name: 'customers.index'}">Klanten</router-link>
                    <router-link class="breadcrumb-item" aria-current="page" :to="{name: 'customers.show', params: {customer: customer.id}}">
                        [{{ customer?.code }}] {{ customer?.name }}
                    </router-link>
                    <li class="breadcrumb-item active" aria-current="page">Bewerken</li>
                </ol>
            </nav>

            <div id="buttons" class="ms-auto text-md-right align-self-center">
                <router-link v-if="$gate.allows('view', customer)" :to="{name: 'customers.show', params: customer?.id}" class="btn btn-default" :class="{disabled: form.working}" :disabled="form.working">
                    Terug
                    <icon class="ms-1" icon="times"></icon>
                </router-link>
                <button v-if="$gate.allows('delete', customer)" class="btn btn-danger" :disabled="form.working" @click="deleteCustomer">
                    Verwijder
                    <icon class="ms-1" icon="trash"></icon>
                </button>
                <button v-if="$gate.allows('update', customer)" class="btn btn-success" type="submit" form="create-form" :disabled="form.working">
                    Opslaan
                    <icon class="ms-1" icon="floppy-disk"></icon>
                </button>
            </div>
        </div>

        <form @submit.prevent="save" id="create-form">
            <div class="row">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group">
                                <label class="form-label">Naam</label>
                                <input class="form-control" type="text" v-model="form.name" v-validate:[form.errors]="'name'"/>
                                <error field="name" :bag="form.errors"></error>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Code</label>
                                <input class="form-control" type="text" v-model="form.code" v-validate:[form.errors]="'code'"/>
                                <error field="code" :bag="form.errors"></error>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Contactpersoon</label>
                                <input class="form-control" type="text" v-model="form.contact" v-validate:[form.errors]="'contact'"/>
                                <error field="contact" :bag="form.errors"></error>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Email</label>
                                <input class="form-control" type="text" v-model="form.email" v-validate:[form.errors]="'email'"/>
                                <error field="email" :bag="form.errors"></error>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Telefoonnummer</label>
                                <input class="form-control" type="text" v-model="form.phone" v-validate:[form.errors]="'phone'"/>
                                <error field="phone" :bag="form.errors"></error>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Kleur</label>
                                <input class="form-control" type="color" style="max-width: 50px" v-model="form.color" v-validate:[form.errors]="'color'"/>
                                <error field="color" :bag="form.errors"></error>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="customer" class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Bedrijfsvorm</label>
                                        <select class="form-select" v-model="form.size" v-validate:[form.errors]="'size'">
                                            <option :value="null" disabled hidden>--</option>
                                            <option value="startup">Startup</option>
                                            <option value="zzp">ZZP</option>
                                            <option value="mkb">MKB</option>
                                            <option value="enterprise">Enterprise</option>
                                        </select>
                                        <error field="size" :bag="form.errors"></error>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Aantal medewerkers</label>
                                        <select class="form-select" v-model="form.employees" v-validate:[form.errors]="'employees'">
                                            <option :value="null" disabled hidden>--</option>
                                            <option value="1-10">1-10</option>
                                            <option value="10-50">10-50</option>
                                            <option value="50-100">50-100</option>
                                            <option value="100+">100+</option>
                                        </select>
                                        <error field="employees" :bag="form.errors"></error>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Branche</label>
                                        <select class="form-select" v-model="form.branche" v-validate:[form.errors]="'branche'">
                                            <option :value="null" disabled hidden>--</option>
                                            <option value="Advies/Consultancy">Advies/Consultancy</option>
                                            <option value="Agrosector">Agrosector</option>
                                            <option value="Bouw, installatie en infrastructuur">Bouw, installatie en infrastructuur</option>
                                            <option value="Cultuur en sport">Cultuur en sport</option>
                                            <option value="Energie">Energie</option>
                                            <option value="Financiële dienstverlening">Financiële dienstverlening</option>
                                            <option value="Gezondheidszorg en maatschappelijke dienstverlening">Gezondheidszorg en maatschappelijke dienstverlening</option>
                                            <option value="Groot- en detailhandel">Groot- en detailhandel</option>
                                            <option value="Horeca">Horeca</option>
                                            <option value="ICT, media en communicatie">ICT, media en communicatie</option>
                                            <option value="Industrie en Delfstoffen">Industrie en Delfstoffen</option>
                                            <option value="Onderwijs en training">Onderwijs en training</option>
                                            <option value="Onroerend goed">Onroerend goed</option>
                                            <option value="Persoonlijke dienstverlening en not-for-profit">Persoonlijke dienstverlening en not-for-profit</option>
                                            <option value="Vervoer, post en opslag">Vervoer, post en opslag</option>
                                            <option value="Water en afval">Water en afval</option>
                                            <option value="Zakelijke dienstverlening">Zakelijke dienstverlening</option>
                                        </select>
                                        <error field="branche" :bag="form.errors"></error>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Accountmanager</label>
                                        <UserSelect
                                            class="me-2"
                                            id="account_manager_id"
                                            :selected="form.account_manager_id"
                                            @answer="(u: any) => form.account_manager_id = u.id"
                                            v-validate:[form.errors]="'account_manager_id'"/>
                                        <error field="account_manager_id" :bag="form.errors"></error>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Website</label>
                                        <input class="form-control" type="text" v-model="form.website" v-validate:[form.errors]="'website'"/>
                                        <error field="website" :bag="form.errors"></error>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Opgericht</label>
                                        <VueCtkDateTimePicker
                                            id="founded_at"
                                            label="Datum"
                                            v-model="form.founded_at"
                                            format="YYYY-MM-DD"
                                            formatted="ll"
                                            locale="nl"
                                            :only-date="true"
                                            :no-header="true"
                                            input-size="sm"
                                            v-validate:[form.errors]="'founded_at'"
                                        />
                                        <error field="founded_at" :bag="form.errors"></error>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Lead via</label>
                                        <input class="form-control" type="text" v-model="form.lead" v-validate:[form.errors]="'lead'"/>
                                        <error field="lead" :bag="form.errors"></error>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Klant sinds</label>
                                        <VueCtkDateTimePicker
                                            id="customer_since"
                                            label="Datum"
                                            v-model="form.customer_since"
                                            format="YYYY-MM-DD"
                                            formatted="ll"
                                            locale="nl"
                                            :only-date="true"
                                            :no-header="true"
                                            input-size="sm"
                                            v-validate:[form.errors]="'customer_since'"
                                        />
                                        <error field="customer_since" :bag="form.errors"></error>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Adres</label>
                                        <input class="form-control" type="text" v-model="form.address" v-validate:[form.errors]="'address'"/>
                                        <error field="address" :bag="form.errors"></error>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Postcode</label>
                                        <input class="form-control" type="text" v-model="form.zip" v-validate:[form.errors]="'zip'"/>
                                        <error field="zip" :bag="form.errors"></error>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Plaats</label>
                                        <input class="form-control" type="text" v-model="form.city" v-validate:[form.errors]="'city'"/>
                                        <error field="city" :bag="form.errors"></error>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Land</label>
                                        <input class="form-control" type="text" v-model="form.country" v-validate:[form.errors]="'country'"/>
                                        <error field="country" :bag="form.errors"></error>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>


        </form>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Customer from "../../store/Models/Customer";
import {Item} from "@vuex-orm/core";
import UserSelect from "../../components/UserSelect.vue";
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';

export default defineComponent({
    components: {VueCtkDateTimePicker, UserSelect},
    data() {
        return {
            form: {
                name: null,
                code: null,
                contact: null,
                email: null,
                phone: null,
                color: null,

                size: null,
                employees: null,
                branche: null,
                address: null,
                zip: null,
                city: null,
                country: null,
                website: null,
                founded_at: null,
                lead: null,
                customer_since: null,
                account_manager_id: null,

                working: false,
                errors: null,
            } as any,
        }
    },
    computed: {
        customer(): Item<Customer> {
            return Customer.find(
                parseInt((this.$route.params.customer as string))
            );
        }
    },
    watch: {
        customer: {
            handler(customer) {
                if (customer === null) {
                    customer = {};
                }

                for (const item in this.form) {
                    if (['errors', 'working'].includes(item)) {
                        continue;
                    }

                    // fix '0000-00-00'
                    if (item === 'founded_at' || item === 'customer_since') {
                        this.form[item] = customer[item] ? customer[item] : null;
                        if (this.form[item] === '0000-00-00') {
                            this.form[item] = null;
                        }
                        continue;
                    }

                    this.form[item] = customer[item] ?? '';
                }
            },
            immediate: true,
        }
    },
    methods: {
        save() {
            this.form.working = true;
            this.form.errors = null;

            Customer.api().put('customers/' + this.customer?.id, this.form)
                .then(response => {
                    this.$toast('success', 'Je hebt succesvol een klant aangepast.');

                    this.$router.push({
                        name: 'customers.show',
                        params: {
                            customer: response.response.data.data.id,
                        },
                    });
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.form.errors = e.response.data.errors;
                    }
                })
                .finally(() => {
                    this.form.working = false;
                });
        },
        deleteCustomer() {
            Customer.api().delete('customers/' + this.customer?.id, {
                delete: this.customer?.id,
            })
                .then(() => {
                    this.$toast('success', 'Je hebt succesvol een klant verwijderd.');

                    this.$router.push({
                        name: 'customers.index',
                    });
                });
        }
    },
    created() {
        Customer.api().get('customers/' + this.$route.params.customer);
    }
});
</script>

<style scoped>

</style>
