<template>
    <form @submit.prevent="save">
        <div class="modal fade" :id="'createEvent_'+user.id" tabindex="-1" :aria-labelledby="'createEventLabel_'+user.id" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" :id="'createEventLabel_'+user.id">Agenda item maken</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="title" class="form-label">Titel</label>
                            <input class="form-control" id="calendar-title-input" v-model="form.title" v-validate:[form.errors]="'title'" />
                            <error field="title" :bag="form.errors"></error>
                        </div>

                        <div class="mb-3">
                            <label for="description" class="form-label">Omschrijving</label>
                            <textarea class="form-control" v-model="form.description" v-validate:[form.errors]="'description'"></textarea>
                            <error field="description" :bag="form.errors"></error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="form.working">Annuleren</button>
                        <button type="submit" class="btn btn-primary" :disabled="form.working">Opslaan</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Event from "../../store/Models/Event";

export default defineComponent({
    props: ['data', 'user'],
    data() {
        return {
            form: {
                title: null,
                description: null,

                errors: null,
                working: false,
            },
        }
    },
    emits: [
        'saved',
    ],
    methods: {
        save() {
            Event.api().post('users/'+this.user.id+'/events', {
                ...this.form,
                ...{
                    start: this.$moment(this.data.start).format('Y-MM-DD HH:mm:ss'),
                    end: this.$moment(this.data.end).format('Y-MM-DD HH:mm:ss'),
                    is_private: true,
                }
            })
                .then(response => {
                    this.$toast('success', 'Je agenda item is opgeslagen.', 1);
                    this.$emit('saved', response.entities?.events[0]);

                    this.form = {
                        title: null,
                        description: null,

                        errors: null,
                        working: false,
                    };
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.form.errors = e.response.data.errors;
                    }
                })
                .finally(() => {
                    this.form.working = false;
                });
        }
    },
});
</script>

<style scoped>

</style>
