<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <router-link class="breadcrumb-item" :to="{name: 'management.teams.index'}">Teams</router-link>
                    <li class="breadcrumb-item">{{ team?.name }}</li>
                    <li class="breadcrumb-item active" aria-current="page">Bewerken</li>
                </ol>
            </nav>

            <div id="buttons" class="ms-auto text-md-right align-self-center" v-if="team">
                <router-link :to="{name: 'management.teams.index'}" class="btn btn-sm btn-default" :class="{disabled: form.working}" :disabled="form.working">
                    Terug
                    <icon class="ms-1" icon="times"></icon>
                </router-link>
                <button v-if="$gate.allows('delete', team)" class="btn btn-sm btn-danger" :disabled="form.working" @click="deleteTeam">
                    Verwijder
                    <icon class="ms-1" icon="trash"></icon>
                </button>
                <button v-if="$gate.allows('update', team)" class="btn btn-sm btn-success" type="submit" form="create-form" :disabled="form.working">
                    Opslaan
                    <icon class="ms-1" icon="floppy-disk"></icon>
                </button>
            </div>
        </div>

        <form @submit.prevent="save" id="create-form">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group">
                                <label class="form-label">Naam</label>
                                <input class="form-control" type="text" v-model="form.name" v-validate:[form.errors]="'name'" />
                                <error field="name" :bag="form.errors"></error>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Team from "../../../store/Models/Team";
import {Item} from "@vuex-orm/core";

export default defineComponent({
    data() {
        return {
            form: {
                name: null,

                errors: null,
                working: false,
            } as any,
        }
    },
    computed: {
        team(): Item<Team> {
            return Team.find(
                parseInt((this.$route.params.team) as string),
            )
        }
    },
    watch: {
        team: {
            handler(team) {
                if (team === null) {
                    team = {};
                }

                for (const item in this.form) {
                    if (['errors', 'working'].includes(item) || window._.isNil(team[item])) {
                        continue;
                    }

                    this.form[item] = team[item];
                }
            },
            immediate: true,
        }
    },
    methods: {
        save() {
            this.form.working = true;
            this.form.errors = null;

            const form = {...this.form};

            Team.api().put('teams/'+this.$route.params.team, form)
                .then(() => {
                    this.$toast('success', 'Je wijzigingen zijn opgeslagen.');

                    this.$router.push({
                        name: 'management.teams.index',
                    });
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.form.errors = e.response.data.errors;
                    }
                })
                .finally(() => {
                    this.form.working = false;
                });
        },
        deleteTeam() {
            const id = parseInt((this.$route.params.team as string));

            Team.api().delete('teams/'+id, {
                delete: id,
            })
                .then(() => {
                    this.$toast('success', 'Het team is succesvol verwijderd.');

                    this.$router.push({
                        name: 'management.teams.index',
                    });
                });
        }
    },
});
</script>

<style scoped>

</style>
