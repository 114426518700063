import {Model as BaseModel} from '@vuex-orm/core';
import pluralize from "pluralize";

export default class Model extends BaseModel {
    getSingularEntity(): string {
        return pluralize.singular(
            Object.getPrototypeOf(this).constructor.entity
        );
    }
}
