<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">Checkins</li>
                </ol>
            </nav>

            <div id="buttons" class="ms-auto text-md-right align-self-center">
                <div class="btn-group">
                    <button class="btn btn-primary m-0" @click="previous()">
                        <icon icon="arrow-left"></icon>
                    </button>
                    <button class="btn btn-primary m-0" disabled>
                        {{ startDate }} - {{ endDate }}
                    </button>
                    <button class="btn btn-primary m-0" @click="next()">
                        <icon icon="arrow-right"></icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <div v-if="loading" class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
                </div>

                <template v-else>
                    <div v-if="data !== null" class="times-week">
                        <table class="table table-hover">
                            <thead>
                            <tr>
                                <th>Gebruiker</th>
                                <th v-for="day of data.dates" :key="day">{{ day }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="user of data.users" :key="user.id">
                                <td>
                                    <img :src="user.gravatar" class="gravatar-nano me-2" :style="'border: 1px solid ' + user.color" :alt="user.name">
                                    {{ user.name }}
                                </td>
                                <td v-for="day of data.dates" :key="day" class="fs-6">
                                    <span v-if="user.checkins[day] === null">
                                        x
                                    </span>
                                    <span v-else>
                                        {{ user.checkins[day].created_at.substring(11, 16) }}
                                         -
                                        <span v-if="user.checkins[day].checkout !== null">
                                           {{ user.checkins[day].checkout.substring(11, 16) }}
                                        </span>
                                        <span :class="'fw-bold '+(user.checkins[day].minutes<user.checkins[day].workableMinutes ? 'text-danger' : '')" v-if="user.checkins[day].minutes > 0">
                                            ({{ $timeFormatHoursMinutes(user.checkins[day].minutes) }})
                                        </span>
                                    </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {ref} from "vue";

const data = ref<any>(null);
const loading = ref<boolean>(false);

const startDate = ref<string>(window.moment().startOf('week').format('Y-MM-DD'));
const endDate = ref<string>(window.moment().endOf('week').format('Y-MM-DD'));

const previous = () => {
    const newStartDate = window.moment(startDate.value).subtract(1, 'week').startOf('week');
    const newEndDate = window.moment(newStartDate).endOf('week');

    startDate.value = newStartDate.format('Y-MM-DD');
    endDate.value = newEndDate.format('Y-MM-DD');

    fetchForGivenDates();
}

const next = () => {
    const newStartDate = window.moment(startDate.value).add(1, 'week').startOf('week');
    const newEndDate = window.moment(newStartDate).endOf('week');

    startDate.value = newStartDate.format('Y-MM-DD');
    endDate.value = newEndDate.format('Y-MM-DD');

    fetchForGivenDates();
}

const fetchForGivenDates = () => {
    loading.value = true;

    window.api.get('checkins-overview', {
        params: {
            start: startDate.value,
            end: endDate.value,
        }
    })
        .then(response => {
            data.value = response.data;
        })
        .finally(() => {
            loading.value = false;
        })
}

fetchForGivenDates();
</script>

<style scoped>

</style>
