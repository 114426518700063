<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">Deadlines</li>
                </ol>
            </nav>
        </div>

        <div class="card">
            <div class="d-flex">
                <div class="form-group mx-3 w-25">
                    <label class="form-label">Team</label>
                    <select class="form-select" v-model="filters['filter[user.teams.id]']" @change="resetUser()">
                        <option :value="null">--</option>
                        <option v-for="team of teams" :key="team.id" :value="team.id">{{ team.name }}</option>
                    </select>
                </div>
                <div class="form-group mx-3 w-25">
                    <label class="form-label">Gebruiker</label>
                    <select class="form-select" v-model="filters['filter[user.id]']" @change="resetTeam()">
                        <option :value="null">--</option>
                        <option v-for="user of users" :key="user.id" :value="user.id">{{ user.name }}</option>
                    </select>
                </div>
                <div class="form-group mx-3 w-25">
                    <label class="form-label">Status</label>
                    <select class="form-select" v-model="filters['filter[scrum_status]']">
                        <option :value="null">--</option>
                        <option v-for="status of scrumStatus" :key="status.value" :value="status.value">{{ status.title }}</option>
                    </select>
                </div>
                <div class="form-group mx-3 w-25">
                    <label class="form-label">Sort</label>
                    <select class="form-select" v-model="filters['sort']">
                        <option :value="null">--</option>
                        <option v-for="sort of sorts" :key="sort.value" :value="sort.value">{{ sort.title }}</option>
                    </select>
                </div>
            </div>
            <div class="card-body">
                <template v-for="(deadlines, index) of data" :key="index">
                    <div class="d-flex flex-row">
                        <h3 class="text-primary-darken" v-if="deadlines[0]?.user">{{ deadlines[0]?.user?.name }} ({{ deadlines.length }})</h3>
                        <h3 v-else>Nog toewijzen ({{ deadlines.length }})</h3>
                        <div class="ms-auto">
                            <router-link class="btn btn-default btn-sm mb-1" target="_blank" v-if="$gate.allows('view', 'user') &&  deadlines[0]?.user" :to="{name: 'scrumboard', query: {'user': deadlines[0]?.user?.id}}" v-html="'Scrumboard'"></router-link>
                            <router-link class="ms-3 btn btn-default btn-sm mb-1 d-none" v-if="$gate.allows('create', 'project')" :to="{name: 'projects.index'}" v-html="'+ Deadline'"></router-link>
                        </div>
                    </div>

                    <table class="table mb-4">
                        <template v-for="(status, index) of scrumStatus" :key="index">
                            <thead v-if="deadlines.some(item => item.scrum_status === status.value)" class="card-header">
                                <tr>
                                    <th width="15%"><b>{{ status.title }}</b></th>
                                    <th width="25%"><small class="text-muted">Project</small></th>
                                    <th width="20%"><small class="text-muted">Deadline</small></th>
                                    <th width="25%"><small class="text-muted">Opmerkingen</small></th>
                                    <th width="5%"><small class="text-muted">Uren</small></th>
                                    <th width="8%"><small class="text-muted">Datum</small></th>
                                    <th width="2%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(deadline, deadlineIndex) of deadlines" :key="deadlineIndex" class="hover-show">
                                    <template v-if="deadline.scrum_status === status.value">
                                        <td class="py-2 pe-5">
                                            <span class="text-clamp">
                                                <span class="py-1 pe-1 me-2" :style="'background-color: ' + deadline.budget.project.customer.color"></span>
                                                <router-link v-if="$gate.allows('view', 'customer')" :to="{name: 'customers.show', params: {customer: deadline.budget.project.customer?.id}}" v-html="deadline.budget.project.customer.name"></router-link>
                                                <span v-else>
                                                <span v-html="deadline.budget.project.customer.name"></span>
                                            </span>
                                        </span>
                                        </td>
                                        <td class="pe-5">
                                            <span class="text-clamp">
                                                #{{ deadline.budget.project.id }} |
                                                <router-link target="_blank" v-if="$gate.allows('view', 'budget')" :to="{'name': 'projects.budgets', 'params': {'project': deadline.budget.project?.id}}" v-html="deadline.budget.project.name"></router-link>
                                                <span v-else v-html="deadline.budget.project.customer.name"></span>
                                            </span>
                                        </td>
                                        <td>
                                            {{ deadline.description}}
                                        </td>
                                        <td>
                                            {{ deadline.note}}
                                        </td>
                                        <td>
                                            <span v-if="deadline.workable_minutes === 0 && deadline.budget.is_open">
                                                Open
                                            </span>
                                            <span v-else-if="deadline.workable_minutes > 0 && deadline.budget.is_open">
                                                {{ $timeFormatHoursMinutes(deadline.workable_minutes) }}*
                                            </span>
                                            <span v-else>
                                                {{ $timeFormatHoursMinutes(deadline.workable_minutes) }}
                                            </span>
                                        </td>
                                        <td :class="deadline.date < new Date().toISOString().slice(0, 10) ? 'text-danger fw-bold' : ''">
                                            {{ $moment(deadline.date).format('DD-MM-y')}}
                                        </td>
                                        <td class="text-nowrap" style="text-align: right">
                                            <button class="hover-hidden bg-primary btn btn-sm btn-primary me-2" type="button" @click="editDeadline(deadline)"><icon icon="pen-to-square"></icon></button>
                                        </td>
                                    </template>
                                </tr>
                            </tbody>
                        </template>
                    </table>
                </template>
            </div>
        </div>
        <EditDeadlineModal :deadline="editingDeadline" @deleted="fetchDeadlines" @saved="fetchDeadlines"></EditDeadlineModal>
    </div>
</template>

<script lang="ts" setup>
import {nextTick, reactive, ref, watch} from "vue";
import moment from "moment/min/moment-with-locales";
import Deadline from "../../store/Models/Deadline";
import Team from "../../store/Models/Team";
import User from "../../store/Models/User";
import EditDeadlineModal from "../../components/Dialogs/EditDeadlineModal.vue";
import {isNil, toArray} from "lodash";

moment.locale('nl');

const teams = ref<any>(null);
const data = ref<any>(null);
const users = ref<any>(null);
const editingDeadline = ref<Deadline>(null);
const editDeadlineModal = ref<any>(null);

const scrumStatus = ref([
    {
        'title': 'New',
        'value': 'new',
    },
    {
        'title': 'Active',
        'value': 'active',
    },
    {
        'title': 'Test internal',
        'value': 'test_internal',
    },
    {
        'title': 'Test external',
        'value': 'test_external',
    },
    {
        'title': 'On hold',
        'value': 'on_hold',
    },
    {
        'title': 'Finished (today)',
        'value': 'finished',
    },
]);

const sorts = ref([
    {
        'title': 'Date ↑',
        'value': 'date',
        'icon': 'chevron-up',
    },
    {
        'title': 'Date ↓',
        'value': '-date',
        'icon': 'chevron-down',
    },
])

const filters = reactive({
    'filter[user.teams.id]': null,
    'filter[user.id]': null,
    'filter[scrum_status]': null,
    'sort': '-date'
});

watch(() => filters, () => {
    if (!isNil(filters["filter[user.teams.id]"])){
        sessionStorage.setItem('teamId', filters["filter[user.teams.id]"])
    }

    fetchDeadlines();
}, { deep: true});


const resetTeam = () => {
    filters["filter[user.teams.id]"] = null;
}

const resetUser = () => {
    filters["filter[user.id]"] = null;
}

const fetchDeadlines = () => {
    Deadline.api().get('deadlines', {
        params: {
            include: 'user.teams,budget.project.customer',
            ...filters
        }
    }).then(result => {
        data.value = result.response.data;
        data.value = toArray(data.value).reverse()
    });
}

const fetchTeams = () => {
    Team.api().get('teams')
        .then(result => {
            teams.value = result.response.data.data;
            if (sessionStorage.getItem('teamId')){
                filters["filter[user.teams.id]"] = sessionStorage.getItem('teamId')
            } else {
                filters["filter[user.teams.id]"] = teams.value[0].id;
            }
        });
}

const fetchUsers = () => {
    User.api().get('users')
        .then(result => {
            users.value = result.response.data.data;
        });
}

const editDeadline = (deadline: Deadline) => {
    editingDeadline.value = deadline

    nextTick(() => {
        editDeadlineModal.value = new window.bootstrap.Modal(document.getElementById('editDeadline_' + deadline.id));
        editDeadlineModal.value.show();
    });
}

fetchTeams();
fetchUsers();
</script>

<style scoped>

</style>
