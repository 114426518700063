<template>
    <div class="modal fade" :id="'editTime_' + time.id" tabindex="-1" aria-labelledby="editTimeLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="editTimeLabel">Urenregistratie aanpassen</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div v-if="!loading" class="modal-body">
                    <div class="mb-3">
                        <label for="date" class="form-label">Datum</label>
                        <div class="d-flex">
                            <FlatPicker class="form-control form-date flex-grow-1" :config="config" v-model="datePicker"></FlatPicker>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="minutes" class="form-label">Tijd</label>
                        <TimeInput
                            v-model="form.minutes"
                            @answer="answer => form.minutes = answer"
                            v-validate:[form.errors]="'minutes'"
                        >
                        </TimeInput>
                        <error field="minutes" :bag="form.errors"></error>
                    </div>
                    <div class="mb-3">
                        <label for="project" class="form-label">Project</label>
                        <ProjectSelect
                            @project="project => form.project_id = project.id"
                            :selected="form.project_id"
                            :onlyForMe="!$gate.allows('updateForOthers', 'time')"
                            :not_archived="true"
                            v-validate:[form.errors]="'project_id'"
                        ></ProjectSelect>
                        <error field="project_id" :bag="form.errors"></error>
                    </div>
                    <div class="mb-3">
                        <label for="deadline" class="form-label">Deadline</label>
                        <Multiselect
                            trackBy="description"
                            v-model="form.deadline_id"
                            placeholder="Selecteer een deadline"
                            label="description"
                            valueProp="id"
                            v-validate:[form.errors]="'deadline_id'"
                            @change="answer => deadlineChanged(answer)"
                            :options="deadlines"
                        >
                        </Multiselect>
                        <error field="deadline_id" :bag="form.errors"></error>
                    </div>
                    <div class="mb-3">
                        <label for="description" class="form-label">Omschrijving</label>
                        <textarea
                            class="form-control"
                            v-model="form.description"
                            v-validate:[form.errors]="'description'"
                        ></textarea>
                        <error field="description" :bag="form.errors"></error>
                    </div>
                    <div class="mb-3">
                        <div class="align-self-center">
                            <input type="checkbox" class="checkbox-margin" id="isNotBillableCheck" name="billable" v-model="form.is_not_billable">
                            <label for="isNotBillableCheck" class="d-none d-md-inline-block">
                                Niet Facturabel
                            </label>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="form.working || loading">Annuleren</button>
                    <button type="button" class="btn btn-primary" @click="save" :disabled="form.working || loading">Opslaan</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import TimeInput from "../TimeInput.vue";
import FlatPicker from 'vue-flatpickr-component';
import { Dutch } from "flatpickr/dist/l10n/nl.js";
import ProjectSelect from "../Project/ProjectSelect.vue";
import Time from "../../store/Models/Time";
import Project from "../../store/Models/Project";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
    components: {ProjectSelect, TimeInput, FlatPicker, Multiselect},
    props: {
        time: Object,
    },
    data() {
        return {
            form: {
                date: this.time?.date,
                project_id: this.time?.project_id,
                budget_id: this.time?.budget_id,
                deadline_id: this.time?.deadline_id,
                minutes: this.time?.minutes,
                description: this.time?.description,
                is_not_billable: this.time?.is_not_billable,

                errors: null,
                working: false,
            },
            loading: false,
            datePicker: this.time?.date,
            deadlines: [] as any,
            config: {
                altInput: true,
                allowInput: true,
                altFormat: 'd-m-Y',
                locale: {
                    ...Dutch,
                    firstDayOfWeek: 1,
                },
            },
        }
    },
    emits: [
        'saved',
    ],
    watch: {
        datePicker(value) {
            this.form.date = value;
        },
        'form.project_id': function (value) {
            this.fetchProject(value);
        },
    },
    methods: {
        fetchProject(id: number) {
            this.loading = true;
            this.deadlines = [];

            Project.api().get('projects/' + id, {
                params: {
                    include: 'budgets.deadlines',
                }
            })
                .then((result) => {
                    result.response.data.data.budgets.map((budget: any) => {
                        if (budget.status === 'active' || budget.status === 'blocked') {
                            for (const deadline of budget.deadlines) {
                                if (window.gate.allows('updateForOthers', 'time') || deadline.user_id === window.me?.id) {
                                    this.deadlines.push(deadline)
                                }
                            }
                        }
                    })
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        save() {
            Time.api().put('times/' + this.time?.id, this.form)
                .then(() => {
                    this.$toast('success', 'De uren registratie is succesvol aangepast.');
                    this.$emit('saved');
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.form.errors = e.response.data.errors;
                    }
                })
                .finally(() => {
                    this.form.working = false;
                });
        },
        deadlineChanged(answer: any) {
            this.form.budget_id = this.deadlines.find(({id}: any) => id === answer).budget_id
        },
    },
    created() {
        this.fetchProject(this.time?.project_id);
    }
});
</script>

<style scoped>
.checkbox-margin{
    margin-right: 4px;
}
</style>
