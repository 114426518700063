<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <router-link class="breadcrumb-item" :to="{name: 'management.users.index'}">Gebruikers</router-link>
                    <li class="breadcrumb-item active" aria-current="page">Nieuwe gebruiker</li>
                </ol>
            </nav>

            <div id="buttons" class="ms-auto text-md-right align-self-center">
                <router-link :to="{name: 'management.users.index'}" class="btn btn-sm btn-default" :class="{disabled: form.working}" :disabled="form.working">
                    Terug
                    <icon class="ms-1" icon="times"></icon>
                </router-link>
                <button v-if="$gate.allows('create', 'user')" class="btn btn-sm btn-success" type="submit" form="create-form" :disabled="form.working">
                    Opslaan
                    <icon class="ms-1" icon="floppy-disk"></icon>
                </button>
            </div>
        </div>

        <form @submit.prevent="save" id="create-form">
            <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label class="form-label">Naam</label>
                        <input class="form-control" type="text" v-model="form.name" v-validate:[form.errors]="'name'" />
                        <error field="name" :bag="form.errors"></error>
                    </div>

                    <div class="form-group">
                        <label class="form-label">Email</label>
                        <input class="form-control" type="text" v-model="form.email" v-validate:[form.errors]="'email'" />
                        <error field="email" :bag="form.errors"></error>
                    </div>

                    <div class="form-group">
                        <label class="form-label">Rol</label>
                        <select class="form-select" v-model="form.roles" v-validate:[form.errors]="'role'" multiple>
                            <option :value="null">--</option>
                            <option v-for="role of roles" :key="role.id" :value="role.id">{{ role.name }}</option>
                        </select>
                        <error field="role" :bag="form.errors"></error>
                    </div>

                    <div class="form-group">
                        <label class="form-label">Datum in dienst</label>
                        <input class="form-control" type="date" v-model="form.date_employed" v-validate:[form.errors]="'date_employed'" />
                        <error field="date_employed" :bag="form.errors"></error>
                    </div>

                    <div class="form-group">
                        <label class="form-label">Verjaardag</label>
                        <input class="form-control" type="date" v-model="form.birthday" v-validate:[form.errors]="'birthday'" />
                        <error field="birthday" :bag="form.errors"></error>
                    </div>

                    <div class="form-group">
                        <label class="form-label">Team</label>
                        <select class="form-select" v-model="form.teams" v-validate:[form.errors]="'team'" multiple>
                            <option :value="null">--</option>
                            <option v-for="team of teams" :key="team.id" :value="team.id">{{ team.name }}</option>
                        </select>
                        <error field="role" :bag="form.errors"></error>
                    </div>

                    <div class="form-group">
                        <label class="form-label">Kleur</label>
                        <input class="form-control" type="color" style="max-width: 50px" v-model="form.color" v-validate:[form.errors]="'color'" />
                        <error field="color" :bag="form.errors"></error>
                    </div>
                </div>
            </div>
        </form>

    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import User from "../../../store/Models/User";
import Role from "../../../store/Models/Role";
import Team from "../../../store/Models/Team";

export default defineComponent({
    data() {
        return {
            form: {
                name: null,
                email: null,
                roles: null,
                date_employed: null,
                birthday: null,
                teams: null,
                color: '#000000',

                errors: null,
                working: false,
            },
        }
    },
    computed: {
        roles(): Array<Role> {
            return Role.all();
        },
        teams(): Array<Team> {
            return Team.all();
        },
    },
    methods: {
        save() {
            this.form.working = true;
            this.form.errors = null;

            User.api().post('users', this.form)
            .then(response => {
                this.$toast('success', 'Je hebt succesvol een nieuwe gebruiker gemaakt.');

                this.$router.push({
                    name: 'management.users.edit',
                    params: {
                        user: response.response.data.data.id,
                    },
                });
            })
            .catch(e => {
                if (e.response.status === 422) {
                    this.form.errors = e.response.data.errors;
                }
            })
            .finally(() => {
                this.form.working = false;
            });
        },
    },
    created() {
        Role.api().get('roles');
        Team.api().get('teams');
    }
});
</script>

<style scoped>

</style>
