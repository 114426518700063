<template>
    <div>
        <template v-if="project !== null">
            <div class="breadcrumbs d-flex">
                <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                    <ol class="breadcrumb">
                        <router-link class="breadcrumb-item" :to="{name: 'projects.index'}">Projecten</router-link>
                        <router-link v-if="project" class="breadcrumb-item" :to="{name: 'projects.show', params: {project: project.id}}">{{ project?.name }}</router-link>
                        <li class="breadcrumb-item active" aria-current="page">Budgetbeheer</li>
                    </ol>
                </nav>
            </div>

            <div class="card">
                <div class="card-header">
                    <ul class="nav nav-tabs card-header-tabs ms-n1">
                        <template v-if="$gate.allows('update', project)">
                            <li class="nav-item" role="presentation">
                                <router-link :to="{name: 'projects.show', params: {project: project.id}}" class="nav-link">
                                    Details
                                </router-link>
                            </li>
                        </template>
                        <template v-if="$gate.allows('update', project)">
                            <li class="nav-item" role="presentation">
                                <router-link :to="{name: 'projects.edit', params: {project: project.id}}" class="nav-link">
                                    Bewerken <icon class="ms-1" icon="pen-to-square"></icon>
                                </router-link>
                            </li>
                        </template>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link active fw-bold" aria-selected="true">Budgetbeheer <icon class="ms-1" icon="file-signature"></icon></a>
                        </li>
                        <div id="buttons" class="ms-auto me-2 text-md-right align-self-center">
                            <button v-if="project && $gate.allows('update', project)" @click="save" class="btn btn-sm btn-success" :class="{disabled: form.working}" :disabled="form.working">
                                Opslaan <icon class="ms-1" icon="floppy-disk"></icon>
                            </button>
                        </div>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content">
                        <div class="tab-pane fade show active">
                            <h4 class="mb-3">Budgetgroepen</h4>
                            <div class="offer-details card">
                                <table class="table table-align-middle mb-0">
                                    <thead>
                                    <tr>
                                        <th style="width: 32px"></th>
                                        <th style="width: 200px">Groep</th>
                                        <th>Omschrijving</th>
                                        <th style="width: 96px">Bedrag</th>
                                        <th style="width: 88px">Budget</th>
                                        <th style="width: 88px">Besteedbaar</th>
                                        <th style="width: auto">Status</th>
                                        <th style="width: 50px;">
                                            <button
                                                v-if="$gate.allows('update', 'budget')"
                                                @click="addBudget"
                                                type="button"
                                                :disabled="form.working"
                                                class="btn btn-sm btn-secondary"
                                                title="Budgetgroep aanmaken"
                                            >
                                                <icon icon="plus"></icon>
                                            </button>
                                        </th>
                                    </tr>
                                    </thead>
                                    <draggable
                                        v-if="form.budgets.length > 0"
                                        v-model="form.budgets"
                                        tag="tbody"
                                        group="budgets"
                                        handle=".handle"
                                        item-key="id">
                                        <template #item="{index, element}">
                                            <tr>
                                                <td class="cursor-pointer handle">
                                                    <icon icon="grip"></icon>
                                                </td>
                                                <td>
                                                    <select class="form-select" v-model="element.group" v-validate:[form.errors]="'budgets.'+index+'.group'">
                                                        <option value="web">Web</option>
                                                        <option value="design">Design</option>
                                                        <option value="content">Content</option>
                                                        <option value="project_management">Project Management</option>
                                                        <option value="other">Anders</option>
                                                    </select>
                                                    <error :field="'budgets.'+index+'.group'" :bag="form.errors"></error>
                                                </td>
                                                <td>
                                                    <input class="form-control"
                                                           type="text"
                                                           v-model="element.description"
                                                           v-validate:[form.errors]="'budgets.'+index+'.description'">
                                                    <error :field="'budgets.'+index+'.description'" :bag="form.errors"></error>
                                                </td>
                                                <td>
                                                    <input v-model.lazy="element.amount"
                                                           class="form-control"
                                                           v-money="$moneyConfig"
                                                           v-validate:[form.errors]="'budgets.'+index+'.amount'"
                                                           :disabled="element.budget_minutes > 0"
                                                    />
                                                    <error :field="'budgets.'+index+'.amount'" :bag="form.errors"></error>
                                                </td>
                                                <td>
                                                    <TimeInput v-model="element.budget_minutes"
                                                               :additional="index"
                                                               @answer="(i, val) => [element.old_budget_minutes = element.budget_minutes, element.budget_minutes = val]"
                                                               @change="element.workable_minutes === null || element.workable_minutes === element.old_budget_minutes ? element.workable_minutes = element.budget_minutes : null"
                                                               v-validate:[form.errors]="'budgets.'+index+'.budget_minutes'" />
                                                    <error :field="'budgets.'+index+'.budget_minutes'" :bag="form.errors"></error>
                                                </td>
                                                <td>
                                                    <TimeInput v-model="element.workable_minutes"
                                                               :class="{'cloned-border': element.workable_minutes === element.budget_minutes && element.workable_minutes > 0}"
                                                               :additional="index"
                                                               @answer="(i, val) => element.workable_minutes = val"
                                                               v-validate:[form.errors]="'budgets.'+index+'.workable_minutes'" />
                                                    <error :field="'budgets.'+index+'.workable_minutes'" :bag="form.errors"></error>
                                                </td>
                                                <td>
                                                    <select class="form-select"
                                                            v-model="element.status"
                                                            v-validate:[form.errors]="'budgets.'+index+'.status'">
                                                        <option value="active">Actief</option>
                                                        <option value="inactive">Inactief</option>
                                                        <option value="blocked">Geblokkeerd</option>
                                                    </select>
                                                    <error :field="'budgets.'+index+'.status'" :bag="form.errors"></error>
                                                </td>
                                                <td>
                                                    <button class="btn btn-danger btn-sm" v-if="$gate.allows('delete', 'budget')" @click="removeBudget(element.id)">
                                                        <icon icon="times"></icon>
                                                    </button>
                                                </td>
                                            </tr>
                                        </template>
                                    </draggable>
                                    <tbody v-else>
                                    <td colspan="6">
                                        Er zijn momenteel geen budgetten gekoppeld aan dit project.
                                    </td>
                                    </tbody>
                                </table>
                            </div>

                            <div v-if="form.budgets.length > 0" class="mt-5">
                                <h4 class="mb-3">Deadlines</h4>

                                <template v-for="(budget, i) of form.budgets" :key="budget.id">
                                    <div v-if="budget.description !== ''" class="offer-details card mb-3">
                                        <div class="card-header d-flex align-items-center">
                                            <strong>{{ budget.description }}</strong>
                                            <span class="mx-2">&middot;</span>

                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" :id="budget.id" v-model="budget.is_open" />
                                                <label class="form-check-label" :for="budget.id">Open</label>
                                            </div>

                                            <template v-if="!budget.is_open">
                                                <span class="ms-3">&middot;</span>

                                                <span class="ms-3" :class="{'text-danger': totalWorkableHours(budget).startsWith('-')}">
                                                    Te verdelen uren: {{ totalWorkableHours(budget) }}
                                                </span>
                                            </template>

                                            <span class="ms-3">&middot;</span>
                                            <span class="ms-3 text-secondary">
                                                ({{ $timeFormatHoursMinutes(budget.worked_minutes) }} gewerkt)
                                            </span>

                                            <button class="btn btn-secondary btn-sm ms-auto" v-if="$gate.allows('update', 'deadline')" @click="addDeadline(i)">
                                                Deadline
                                                <icon icon="plus" class="ms-2"></icon>
                                            </button>
                                        </div>
                                        <div class="card-body p-0">
                                            <div v-if="form.errors && form.errors.hasOwnProperty('budgets.' + i)" class="alert alert-danger rounded-0">
                                                {{ form.errors['budgets.' + i][0] }}
                                            </div>

                                            <div v-if="budget.deadlines.length > 0" class="list-group list-group-flush">
                                                <div v-for="(deadline, dIndex) of budget.deadlines" :key="deadline.id" class="list-group-item d-flex align-items-center">
                                                    <input class="form-control w-50 me-2 flex-shrink-1" type="date" v-model="deadline.date" v-validate:[form.errors]="'budgets.'+i+'.deadlines.'+dIndex+'.date'" />

                                                    <TimeInput
                                                        v-model="deadline.workable_minutes"
                                                        :additional="{budget: i, deadline: dIndex}"
                                                        class="me-2 w-25 flex-shrink-1"
                                                        v-validate:[form.errors]="'budgets.'+i+'.deadlines.'+dIndex+'.workable_minutes'"
                                                        @answer="(i, val) => deadline.workable_minutes = val"
                                                    />

                                                    <input class="form-control w-100 me-2 flex-grow-1" type="text" v-model="deadline.description" placeholder="Omschrijving" v-validate:[form.errors]="'budgets.'+i+'.deadlines.'+dIndex+'.description'" />

                                                    <UserSelect
                                                        class="me-2 w-75"
                                                        :selected="deadline.user_id"
                                                        @answer="(response) => { deadline.user_id = response?.id ?? null; }"
                                                        v-validate:[form.errors]="'budgets.'+i+'.deadlines.'+dIndex+'.user_id'"
                                                    ></UserSelect>

                                                    <select class="form-select w-25 m-2" v-model="deadline.team_id" v-validate:[form.errors]="'budgets.'+i+'.deadlines.'+dIndex+'.team_id'">
                                                        <option v-for="team of teams" :key="team.id" :value="team.id">{{ team.name }}</option>
                                                    </select>

                                                    <input class="form-control w-100 me-2 flex-grow-1" type="text" v-model="deadline.note" placeholder="Opmerking" v-validate:[form.errors]="'budgets.'+i+'.deadlines.'+dIndex+'.note'" />

                                                    <div class="form-check form-switch me-2">
                                                        <input class="form-check-input" type="checkbox" :id="deadline.id" v-model="deadline.is_finished" v-validate:[form.errors]="'budgets.'+i+'.deadlines.'+dIndex+'.is_finished'" />
                                                    </div>
                                                    <button class="btn btn-danger btn-sm" v-if="$gate.allows('delete', 'deadline')" @click="removeDeadline(i, deadline.id)">
                                                        <icon icon="times"></icon>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="p-3" v-else>
                                                Dit budget heeft nog geen deadlines.
                                            </div>
                                        </div>
                                    </div>
                                </template>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import UserSelect from "../../components/UserSelect.vue";
import Project from "../../store/Models/Project";
import {Item} from "@vuex-orm/core";
import draggable from 'vuedraggable';
import Budget from "../../store/Models/Budget";
import {VMoney} from 'v-money';
import TimeInput from "../../components/TimeInput.vue";
import Deadline from "../../store/Models/Deadline";
import User from "../../store/Models/User";
import Team from "../../store/Models/Team";

export default defineComponent({
    components: {TimeInput, UserSelect, draggable},
    directives: {
        money: VMoney,
    },
    data() {
        return {
            form: {
                budgets: [] as Array<Budget>,

                working: false,
                errors: null,
            } as any,

            teams: [] as Array<Team>,
            trackId: 0,
        }
    },
    computed: {
        project(): Item<Project> {
            return Project.query()
                .with('budgets.deadlines')
                .where('id', parseInt(this.$route.params.project as string))
                .first();
        },
    },
    watch: {
        project: {
            handler(project) {
                if (project === null) {
                    project = {};
                }

                for (const item in this.form) {
                    if (['errors', 'working'].includes(item) || !this.form.hasOwnProperty(item)) {
                        continue;
                    }

                    let value = project[item];

                    if (item === 'budgets') {
                        value = window._.orderBy(project[item], 'position');
                    }

                    this.form[item] = value;
                }
            },
            immediate: true,
        },
    },
    methods: {
        setDeadlineUser(user, deadline: Deadline) {
            if (deadline.user_id === null) {
                deadline.user_id = window.user.id;
            }
        },
        getUser(id: number) {
            return User.find(id);
        },
        totalWorkableHours(budget: Budget): string {
            let total = 0;

            for (const deadline of budget.deadlines) {
                total += deadline.workable_minutes;
            }

            total = budget.workable_minutes - total;

            return this.$timeFormatHoursMinutes(total);
        },
        removeBudget(id: number) {
            window.Swal.fire({
                title: 'Weet je het zeker?',
                text: "Je kunt dit niet terugdraaien.",
                icon: 'warning',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonClass: 'bg-primary',
                cancelButtonClass: 'bg-danger',
                confirmButtonText: 'Verwijder',
                reverseButtons: true,
            }).then((result: any) => {
                if (result.isConfirmed) {
                    Budget.delete(id);
                    window._.remove(this.form.budgets, ['id', id]);
                }
            });
        },
        removeDeadline(budget: number, deadline: number) {
            window.Swal.fire({
                title: 'Weet je het zeker?',
                text: "Je kunt dit niet terugdraaien.",
                icon: 'warning',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonClass: 'bg-primary',
                cancelButtonClass: 'bg-danger',
                confirmButtonText: 'Verwijder',
                reverseButtons: true,
            }).then((result: any) => {
                if (result.isConfirmed) {
                    Deadline.delete(deadline);
                    window._.remove(this.form.budgets[budget].deadlines, ['id', deadline]);
                }
            });
        },
        save() {
            this.form.working = true;
            this.form.errors = null;

            const budgets = this.form.budgets.map((budget: any) => {
                budget = {...budget};
                budget.amount = parseFloat(budget.amount.replace(',', '.'));
                return budget;
            });

            window.api.put('projects/'+this.$route.params.project+'/budgets', {...this.form, ...{budgets: budgets}})
                .then(() => {
                    this.$toast('success', 'Je wijzigingen zijn succesvol opgeslagen!');

                    this.fetchData();
                })
                .catch(e => {
                    if (e.response?.status === 422) {
                        this.form.errors = e.response.data.errors;
                    }
                })
                .finally(() => {
                    this.form.working = false;
                })
        },
        addBudget() {
            this.form.budgets.push(new Budget({
                id: --this.trackId,
            }));
        },
        addDeadline(index: number) {
            let budget = this.form.budgets[index];
            let currentDeadlines = budget.deadlines.length;
            budget.deadlines.push(new Deadline({
                id: --this.trackId,
                description: !currentDeadlines ? budget.description : '',
            }));
        },
        fetchData() {
            this.form.working = true;

            Project.api().get('projects/'+this.$route.params.project+'?include=budgets.deadlines')
                .finally(() => {
                    this.form.working = false;
                });
        },
        fetchTeams() {
            Team.api().get('teams')
                .then(result => {
                    this.teams = result.response.data.data;
                });
        }
    },
    created() {
        this.fetchData();
        this.fetchTeams();
    }
});
</script>

<style scoped>
.cloned-border {
    border-color: green;
    box-shadow: green;
}
</style>
