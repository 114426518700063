<template>
    <div>
        <button type="button"
                class="btn btn-sm me-1 date-button text-capitalize"
                :class="{
                    'btn-primary': $moment(date).isSame($moment().subtract(2, 'days'), 'day'),
                    'btn-default': !$moment(date).isSame($moment().subtract(2, 'days'), 'day'),
                }"
                @click="setDate($moment().subtract(2, 'days'))"
        >
            {{ $moment().subtract(2, 'days').format('dddd') }}
        </button>
        <button type="button"
                class="btn btn-sm me-1 date-button"
                :class="{
                    'btn-primary': $moment(date).isSame($moment().subtract(1, 'days'), 'day'),
                    'btn-default': !$moment(date).isSame($moment().subtract(1, 'days'), 'day'),
                }"
                @click="setDate($moment().subtract(1, 'days'))"
        >
            Gisteren
        </button>
        <button type="button"
                class="btn btn-sm me-1 date-button"
                :class="{
                    'btn-primary': $moment(date).isSame($moment(), 'day'),
                    'btn-default': !$moment(date).isSame($moment(), 'day'),
                }"
                @click="setDate($moment())"
        >
            Vandaag
        </button>
        <FlatPicker class="form-control form-date" :config="config" v-model="datePicker"></FlatPicker>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import FlatPicker from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Dutch } from "flatpickr/dist/l10n/nl.js";

export default defineComponent({
    data() {
        return {
            datePicker: null,
            config: {
                altInput: true,
                allowInput: true,
                altFormat: 'd-m-Y',
                locale: {
                    ...Dutch,
                    firstDayOfWeek: 1,
                },
            },
        }
    },
    watch: {
        date: {
            handler(val) {
                this.datePicker = val;
            },
            immediate: true,
        },
        datePicker(val) {
            if (!this.$moment(val).isSame(this.date, 'day')) {
                this.$emit('date', this.$moment(val));
            }
        }
    },
    components: {FlatPicker},
    props: ['date'],
    emits: [
        'date',
    ],
    methods: {
        setDate(date: any) {
            this.datePicker = date.format('Y-MM-DD');
        },
    },
});
</script>

<style scoped>

</style>
