<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">Vrije dagen</li>
                </ol>
            </nav>
        </div>

        <div class="card">
            <div class="card-header d-flex">
                <span class="align-self-center">
                    <strong>Vrije dagen beheer</strong>
                </span>

                <div class="ms-auto">
                    <button class="btn btn-primary btn-sm" :disabled="form.working" @click="addFreeDay">
                        Toevoegen
                        <icon icon="plus" class="ms-2"></icon>
                    </button>

                    <button class="btn btn-success btn-sm ms-3" :disabled="form.working" @click="save">
                        Opslaan
                        <icon icon="floppy-disk" class="ms-2"></icon>
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th>Omschrijving</th>
                            <th>Datum</th>
                            <th>Ieder jaar</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(freeDay, index) of form.free_days" :key="freeDay.id">
                            <td>
                                <input class="form-control" type="text" v-model="freeDay.description" v-validate:[form.errors]="'free_days.'+index+'.description'" />
                                <error :field="'free_days.'+index+'.description'" :bag="form.errors"></error>
                            </td>
                            <td>
                                <input class="form-control" type="date" v-model="freeDay.date" v-validate:[form.errors]="'free_days.'+index+'.date'" />
                                <error :field="'free_days.'+index+'.date'" :bag="form.errors"></error>
                            </td>
                            <td>
                                <div class="d-flex h-100 align-items-center">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" v-model="freeDay.is_yearly" v-validate:[form.errors]="'free_days.'+index+'.is_yearly'">
                                    </div>
                                    <error :field="'free_days.'+index+'.is_yearly'" :bag="form.errors"></error>
                                </div>
                            </td>
                            <td class="d-flex">
                                <button class="btn btn-danger ms-auto" @click="form.free_days.splice(index, 1)">
                                    <icon icon="trash"></icon>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import FreeDay from "../../store/Models/FreeDay";

export default defineComponent({
    data() {
        return {
            form: {
                free_days: [] as Array<FreeDay>,

                working: false,
                errors: null,
            } as any,

            track_id: 0 as number,
        }
    },
    computed: {
        freeDays(): Array<FreeDay> {
            return FreeDay.all();
        }
    },
    watch: {
        freeDays: {
            handler(freeDays) {
                this.form.free_days = freeDays;
            },
            immediate: true,
        }
    },
    methods: {
        addFreeDay() {
            this.form.free_days.unshift(new FreeDay({
                description: null,
                date: null,
                is_yearly: true,
            }));
        },
        save() {
            this.form.working = true;
            this.form.errors = null;

            FreeDay.api().put('free-days', this.form)
                .then(() => {
                    this.$toast('success', 'Alle wijzigingen zijn succesvol opgeslagen.');
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.form.errors = e.response.data.errors;
                    }
                })
                .finally(() => {
                    this.form.working = false;
                });
        }
    },
    created() {
        FreeDay.api().get('free-days');
    }
});
</script>

<style scoped>

</style>
