<template>
    <div>
        <table class="table table-hover">
            <thead>
            <tr>
                <th>Datum</th>
                <th>Omschrijving</th>
                <th>Uren</th>
                <th style="width: 50px">Exporteer gekoppelde uren</th>
            </tr>
            </thead>
            <tbody v-if="invoices.result !== null">
                <tr v-for="invoice of invoices.result.data" :key="invoice.id">
                    <td>{{ $moment(invoice.date).format('Y-MM-DD') }}</td>
                    <td>{{ invoice.description }}</td>
                    <td>{{ $timeFormatHoursMinutes(invoice.total_minutes) }}</td>
                    <td>
                        <button class="btn btn-primary btn-sm" @click="downloadInvoice(invoice.id)">
                            Exporteer
                            <icon icon="download" class="ms-2"></icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <Pagination v-if="invoices.result !== null" :data="invoices.result" @pagination-change-page="fetchInvoices" :limit="2" align="center" class="mt-3" />
    </div>
</template>

<script lang="ts" setup>
import Project from "../../store/Models/Project";
import {reactive, watch} from "vue";
import Time from "../../store/Models/Time";
import Pagination from 'laravel-vue-pagination';

const invoices = reactive({
    result: null as any,
});

const props = defineProps<{
    project: Project,
}>();

const fetchInvoices = (page: number = 1) => {
    if (invoices.result !== null && page === invoices.result.meta.current_page) {
        return;
    }

    Time.api().get('projects/' + props.project.id + '/invoices', {
        params: {
            'page[number]': page
        }
    })
        .then(result => {
            invoices.result = result.response.data;
        });
}

watch(() => props.project, () => {
    fetchInvoices(1);
}, {immediate: true});

const downloadInvoice = (id: number) => {
    window.open('/invoices/' + id + '/export');
}
</script>

<style scoped>

</style>
