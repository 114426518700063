<template>
    <div class="card">
        <div class="card-header" :style="'background-color:'+user.color+';color:'+$calculateTextColor(user.color)">
            {{ user.name }}
        </div>
        <div class="card-body p-0">
            <div class="row m-0">
                <div v-if="show_projects" class="col-3" style="padding: 0.5rem 1rem;">

                    <div class="d-flex mb-4 mt-0 me-3">
                        <div class="input-group">
                            <input type="search" name="deadline" v-model="search" class="form-control" placeholder="Zoek projecten">
                        </div>
                    </div>

                    <div class="open-events" ref="eventContainer">
                        <div
                            v-for="deadline of userDeadlines"
                            :key="deadline.id"
                            class="d-flex mb-1 lh-1 draggable"
                            :data-deadline="JSON.stringify(deadline)"
                        >
                            <template v-if="deadline && deadline.budget && deadline.budget.project">
                                <div
                                    class="p-2 rounded-2 me-2 w-100 cursor-pointer"
                                    :style="'background-color:'+deadline.budget.project.customer.color+';color:'+$calculateTextColor(deadline.budget.project.customer.color)"
                                >
                                    <strong style="font-size: 90%">[<span v-html="(deadline.budget.project.sub_customer ? deadline.budget.project.sub_customer.code : deadline.budget.project.customer.code)"></span>]</strong> {{ deadline.budget.project.name }}<br />
                                    <span class="fs-small">{{ deadline.description.substring(0, 60) }}</span>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="my-2" :class="show_projects ? 'col-9' : 'col-12'">
                    <div
                        v-if="hoveringEventInfo !== null"
                        class="event-hover-info"
                        :style="'background-color: ' + hoveringEventInfo.background+';color:'+hoveringEventInfo.text"
                        v-html="hoveringEventInfo.title"
                    ></div>
                    <FullCalendar :options="config" :ref="'fullCalendar_' + this.user?.id"></FullCalendar>
                </div>
            </div>
        </div>

        <CreateEventModal :data="modalData" :user="user" @saved="eventStored"></CreateEventModal>
        <EditEventModal :event="editingEvent" :user="user" @saved="eventUpdated" @deleted="removeEventById"></EditEventModal>
    </div>
</template>

<script lang="ts">
import CreateEventModal from "../../components/Dialogs/CreateEventModal.vue";
import EditEventModal from "../../components/Dialogs/EditEventModal.vue";
import Event from "../../store/Models/Event";
import FullCalendar from '@fullcalendar/vue3';
import User from "../../store/Models/User";
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, {Draggable} from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import nlLocale from '@fullcalendar/core/locales/nl';
import rrulePlugin from '@fullcalendar/rrule'
import timeGridPlugin from '@fullcalendar/timegrid';
import {EventAdditionalUpdateData, EventModalData} from "../../typings/EventInterfaces";
import {defineComponent} from "vue";
import {debounce, isNil} from "lodash";
import Deadline from "../../store/Models/Deadline";

export default defineComponent({
    components: {
        FullCalendar, CreateEventModal, EditEventModal,
    },
    props: {
        user: User|Object,
    },
    data() {
        return {
            show_projects: true,

            calendar: null as any,

            event_elements: {},

            search: null,

            config: {
                plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin, bootstrap5Plugin, rrulePlugin],
                initialView: 'timeGridWeek',
                themeSystem: 'bootstrap5',
                headerToolbar: {
                    start: 'toggleProjects prev,next today',
                    center: 'title',
                    end: 'dayGridMonth,timeGridWeek,list',
                },
                buttonIcons: {
                    prev: 'arrow-left',
                    next: 'arrow-right',
                    prevYear: 'arrow-left',
                    nextYear: 'arrow-right',
                },
                buttonText: {
                    today: 'Vandaag',
                    month: 'Maand',
                    week: 'Week',
                    list: 'Lijst',
                },
                height: 700,
                nowIndicator: true,
                //businessHours: {
                //    startTime: '09:00',
                //    endTime: '17:30',
                //},
                weekends: false,
                slotDuration: '00:30:00',
                slotMinTime: '8:00',
                slotMaxTime: '19:30',
                locale: nlLocale,
                customButtons: {
                    toggleProjects: {
                        icon: 'square-half',
                        click: () => {
                            // @ts-ignore
                            this.show_projects = !this.show_projects;
                        }
                    },
                    // syncCalendar: {
                    //     icon: 'sliders',
                    //     click: () => {
                    //         // @ts-ignore
                    //         this.launchSettingsModal();
                    //     }
                    // }
                },
                eventDisplay: 'block',

                droppable: true,
                selectable: true,
                editable: true,

                eventReceive: this.storeEvent,
                select: this.selectArea,
                eventClick: this.editEvent,
                eventResize: this.updateEvent,
                eventDrop: this.updateEvent,

                eventContent: (data: any) => {
                    let title = '';
                    let projectTitle = '';
                    let customerTitle = '';

                    if (
                        !data.event.extendedProps.hasOwnProperty('is_free_day')
                        && !data.event.extendedProps.hasOwnProperty('is_working_hour')
                        && !data.event.allDay
                    ) {
                        // If it is only schedules for 30 minutes we should only show the start date
                        if (this.$moment(data.event.start).add(30, 'minutes').isSame(data.event.end)) {
                            title += this.$moment(data.event.start).format('HH:mm');
                        } else {
                            title += this.$moment(data.event.start).format('HH:mm') + ' - ' + this.$moment(data.event.end).format('HH:mm');
                            title += '<br />';
                        }


                        if (!isNil(data.event.extendedProps.project_title)) {
                            projectTitle = data.event.extendedProps.project_title;
                        }
                        if (!isNil(data.event.extendedProps.customer_title)) {
                            customerTitle = data.event.extendedProps.customer_title;
                        }

                        title += ' ';
                    }


                    title += '<span id="title" data-project-title="' + projectTitle + '" data-customer-title="' + customerTitle + '">' + data.event.title + '</span>';

                    if (data.event.extendedProps.description !== null) {
                        title += '<br /><p style="margin-top: 7.5px">' + data.event.extendedProps.description + '</p>';
                    }


                    return {
                        html: title,
                    }
                },

                events: this.fetchEvents,
            } as any,

            hoveringEventInfo: null as any,

            modal: null as any,
            modalData: null as EventModalData | null,
            editModal: null as any,
            editingEvent: null as any,

            settingsModal: null as any,
        }
    },
    watch: {
        show_projects: {
            handler(value) {
                this.$nextTick(() => {
                    this.calendar.updateSize();
                });

                if (value) {
                    this.$nextTick(() => {
                        new Draggable((this.$refs.eventContainer as HTMLElement), {
                            itemSelector: '.draggable',
                            eventData: (element) => {
                                const deadline = JSON.parse((element.dataset.deadline as string));

                                let customerCode = 1;
                                if (deadline.budget.project.sub_customer) {
                                    customerCode = deadline.budget.project.sub_customer.code;
                                } else {
                                    customerCode = deadline.budget.project.customer.code;
                                }
                                return {
                                    id: deadline.id,
                                    title: `[${customerCode}] ${deadline.description}`,
                                    backgroundColor: deadline.budget.project.customer.color,
                                    textColor: this.$calculateTextColor(deadline.budget.project.customer.color),
                                    duration: '2:00',
                                    create: true,
                                };
                            }
                        });
                    });
                }
            },
            immediate: true,
        }
    },
    computed: {
        userDeadlines() {
            if (this.search === null || this.search === '') {
                return this.user.deadlines;
            }

            const format = (string: string|null) => {
                if (string === null) {
                    return string;
                }

                return string.toLowerCase().trim();
            }

            const search = format(this.search);

            return this.user.deadlines.filter((deadline: Deadline) => {
                if (
                    format(deadline.description).includes(search)
                    || format(deadline.budget.description).includes(search)
                    || format(deadline.budget.project.name).includes(search)
                    || format(deadline.budget.project.customer.name).includes(search)
                ) {
                    return true;
                }

                return false;
            });
        }
    },
    methods: {
        storeEvent(data: any) {
            Event.api().post('users/'+this.user?.id+'/events', {
                deadline_id: parseInt(data.event.id),
                start: this.$moment(data.event.start).format('Y-MM-DD HH:mm:ss'),
                end: data.event.allDay
                    ? this.$moment(data.event.start).format('Y-MM-DD HH:mm:ss')
                    : this.$moment(data.event.end).format('Y-MM-DD HH:mm:ss'),
                title: data.event.title,
                description: null,
                is_private: false,
            })
                .then(response => {
                    this.$toast('success', 'Je agenda item is opgeslagen.', 1);

                    data.event.setProp('id', response.response.data.data.id);

                    this.calendar.refetchEvents();
                    data.event.remove();
                })
                .catch(() => {
                    this.$toast('danger', 'Er is een fout opgetreden bij het opslaan van je item');
                });
        },
        updateEvent(data: any, additionalData: EventAdditionalUpdateData) {
            const updateData = {
                ...{
                    start: this.$moment(data.event.start).format('Y-MM-DD HH:mm:ss'),
                    end: this.$moment(data.event.end).format('Y-MM-DD HH:mm:ss'),
                },
                ...additionalData,
            }

            Event.api().put('users/'+this.user?.id+'/events/'+data.event.id, updateData)
                .then(() => {
                    this.$toast('success', 'Je agenda item is opgeslagen.', 1);
                })
                .catch(() => {
                    this.$toast('danger', 'Er is een fout opgetreden bij het opslaan van je item');
                });
        },
        selectArea(data: any) {
            const element = document.getElementById('createEvent_'+this.user?.id);

            element.addEventListener('shown.bs.modal', function () {
                document.getElementById('calendar-title-input').focus();
            });

            this.modalData = {
                start: data.start,
                end: data.end,
            };

            if (this.modal === null) {
                this.modal = new window.bootstrap.Modal(document.getElementById('createEvent_'+this.user?.id));
            }

            this.modal.show();
        },
        editEvent(data: any) {
            if (data.event.extendedProps.hasOwnProperty('is_event') && data.event.extendedProps.is_event === false) {
                return;
            }

            this.editingEvent = Event.query()
                .with('deadline.budget')
                .whereId(parseInt(data.event.id))
                .first();

            this.$nextTick(() => {
                if (this.editModal === null) {
                    const modalComponent = document.getElementById('editEvent_'+this.user?.id);

                    modalComponent.addEventListener('shown.bs.modal', () => {
                        window.bus.emit('modalShown');
                    })

                    this.editModal = new window.bootstrap.Modal(modalComponent);
                }

                this.editModal.show();
            });
        },
        eventStored() {
            if (this.modal === null) {
                this.modal = new window.bootstrap.Modal(document.getElementById('createEvent_'+this.user?.id));
            }

            this.modal.hide();

            this.calendar.refetchEvents();
        },
        eventUpdated(event: Event) {
            if (this.editModal === null) {
                this.editModal = new window.bootstrap.Modal(document.getElementById('editEvent_'+this.user?.id));
            }

            this.editModal.hide();

            this.removeEventById(event.id);
            this.calendar.refetchEvents();
        },
        removeEventById(id: number) {
            const calendarEvent = this.calendar.getEventById(id);
            calendarEvent.remove();
        },
        fetchEvents(info: any, success: any) {
            Event.api().get('users/'+this.user?.id+'/events', {
                params: {
                    include: 'deadline.budget',
                    additional: 'free_days,absence,furlough',
                    'filter[start]': this.$moment(info.start).format('Y-MM-DD'),
                    'filter[end]': this.$moment(info.end).format('Y-MM-DD'),
                },
            }).then(response => {
                success(
                    Event.query()
                        .whereIdIn(window._.map(response.response.data.data, 'id'))
                        .get()
                        .concat(
                            response.response.data.free_days,
                            response.response.data.absence,
                            response.response.data.furlough,
                            response.response.data.working_hours,
                        )
                );

                this.resetHoverEventListeners();
            });
        },
        // launchSettingsModal() {
        //     if (this.settingsModal === null) {
        //         this.settingsModal = new window.bootstrap.Modal(document.getElementById('calendarSettingsModal'));
        //     }
        //
        //     this.settingsModal.show();
        // },
        updateAll() {
            this.calendar.updateSize();
            this.refetchEvents();
        },
        refetchEvents: debounce(function () {
            // @ts-ignore
            this.calendar.refetchEvents();
        }, 300),
        resetHoverEventListeners() {
            document.querySelectorAll('.fc-timegrid-event-harness').forEach(element => {
                element.addEventListener('mouseenter', e => this.hoverEvent(e, 'enter'));
                element.addEventListener('mouseleave', e => this.hoverEvent(e, 'leave'));
            });

        },
        hoverEvent(event, type) {

            if (isNil(event.target.querySelector('#title').dataset.projectTitle) || event.target.querySelector('#title').dataset.projectTitle === 'null') {
                this.hoveringEventInfo = null;
                return;
            }

            if (type === 'leave') {
                this.hoveringEventInfo = null;
                return;
            }

            this.hoveringEventInfo = {
                title: '<strong>[' + event.target.querySelector('#title').dataset.customerTitle + ']</strong>&nbsp;' + event.target.querySelector('#title').dataset.projectTitle,
                background: event.target.querySelector('.fc-event').style.backgroundColor,
                text: event.target.querySelector('.fc-event-main').style.color,
            };
        }
    },
    updated() {
        if (this.user?.id === this.$me().id) {
            (this.config.headerToolbar as any).start = 'toggleProjects prev,next today';
        } else {
            (this.config.headerToolbar as any).start = 'toggleProjects prev,next today';
        }
    },
    mounted() {
        this.calendar = (this.$refs['fullCalendar_' + this.user?.id] as any).getApi();

        this.$bus.on('reloadCalendar', () => {
            this.updateAll();
        });

        if (this.user?.id === this.$me().id) {
            (this.config.headerToolbar as any).start = 'toggleProjects prev,next today';
        } else {
            (this.config.headerToolbar as any).start = 'toggleProjects prev,next today';
        }
    }
});
</script>

<style scoped>
.event-hover-info {
    position: absolute;
    margin-top: 60px;
    overflow: hidden;
    white-space: nowrap;
    width: auto;
    height: 30px;
    z-index: 9;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 150px;
}
</style>
