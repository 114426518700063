<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <router-link class="breadcrumb-item" :to="{name: 'management.roles.index'}">Rollen</router-link>
                    <li class="breadcrumb-item active" aria-current="page">Nieuwe rol</li>
                </ol>
            </nav>

            <div id="buttons" class="ms-auto text-md-right align-self-center">
                <router-link :to="{name: 'management.roles.index'}" class="btn btn-sm btn-danger" :class="{disabled: form.working}" :disabled="form.working">
                    Terug
                    <icon class="ms-1" icon="times"></icon>
                </router-link>
                <button v-if="$gate.allows('create', 'role')" class="btn btn-sm btn-success" type="submit" form="create-form" :disabled="form.working">
                    Opslaan
                    <icon class="ms-1" icon="floppy-disk"></icon>
                </button>
            </div>
        </div>

        <form @submit.prevent="save" id="create-form">
            <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label class="form-label">Naam</label>
                        <input class="form-control" type="text" v-model="form.name" v-validate:[form.errors]="'name'" />
                        <error field="name" :bag="form.errors"></error>
                    </div>
                </div>
            </div>

            <div v-if="form.errors &&  form.errors.hasOwnProperty('permissions')" class="alert alert-danger">
                {{ form.errors.permissions[0] }}
            </div>

            <!-- Permissions -->
            <div v-if="permissionGroups !== null" class="row">
                <div v-for="(permissions, title) of permissionGroups" :key="title" class="col-3">
                    <div class="card">
                        <div class="card-body">
                            <h5>{{ title }}</h5>

                            <div v-for="(permissionData, permission) of permissions" :key="permission" class="form-check">
                                <input class="form-check-input" type="checkbox" :value="permission" :id="permission" v-model="form.permissions">
                                <label class="form-check-label" :for="permission">
                                    {{ permissionData.label }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Role from "../../../store/Models/Role";

export default defineComponent({
    data() {
        return {
            permissionGroups: null,

            form: {
                name: null,

                permissions: [],

                working: false,
                errors: null,
            }
        }
    },
    methods: {
        save() {
            this.form.working = true;
            this.form.errors = null;

            Role.api().post('roles', this.form)
            .then(response => {
                this.$toast('success', 'Je hebt succesvol een rol aangemaakt!');

                this.$router.push({
                    name: 'management.roles.edit',
                    params: {
                        role: response.response.data.data.id,
                    }
                });
            })
            .catch(e => {
                if (e.response.status === 422) {
                    this.form.errors = e.response.data.errors;
                }
            })
            .finally(() => {
                this.form.working = false;
            });
        }
    },
    created() {
        window.api.get('permissions')
        .then(response => {
            this.permissionGroups = response.data;
        })
    }
});
</script>

<style scoped>

</style>
