import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";
import User from "./User";
import Deadline from "./Deadline";

export default class Event extends Model {
    static entity = 'events';

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            deadline_id: this.attr(null),
            start: this.string(null),
            end: this.string(null),
            title: this.string(null),
            project_title: this.string(null),
            customer_title: this.string(null),
            description: this.string(null).nullable(),
            is_private: this.boolean(null),

            allDay: this.boolean(null),
            background_color: this.string(null),
            text_color: this.string(null),

            created_at: this.string(false),
            updated_at: this.string(false),

            user: this.belongsTo(User, 'user_id'),
            deadline: this.belongsTo(Deadline, 'deadline_id'),
        }
    }

    id!: number;
    user_id!: number;
    deadline_id!: number;
    start!: string;
    end!: string;
    title!: string;
    project_title!: string;
    customer_title!: string;
    description!: string | null;
    is_private!: boolean;

    alLDay!: boolean;
    background_color!: string;
    text_color!: string;

    created_at!: string;
    updated_at!: string;

    user!: User;
    deadline!: Deadline;

    get backgroundColor () {
        return this.background_color;
    }

    get textColor () {
        return this.text_color;
    }

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'title',
    }
}
