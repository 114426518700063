import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";
import User from "./User";

export default class Furlough extends Model {
    static entity = 'furloughs';

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            acceptor_id: this.attr(null),
            description: this.string(null),
            start: this.attr(null),
            end: this.attr(null),
            year: this.number(0),
            minutes: this.number(0),
            has_been_accepted: this.boolean(null).nullable(),

            created_at: this.string(false),
            updated_at: this.string(false),

            user: this.belongsTo(User, 'user_id'),
            acceptor: this.belongsTo(User, 'acceptor_id'),
        }
    }

    get status(): string {
        if (this.has_been_accepted === null) {
            return 'pending';
        } else if (this.has_been_accepted) {
            return 'approved';
        } else {
            return 'denied';
        }
    }

    id!: number;
    user_id!: number;
    acceptor_id!: number;
    description!: string;
    start!: string;
    end!: string;
    year!: number;
    minutes!: number;
    has_been_accepted!: boolean;
    created_at!: string;
    updated_at!: string;

    user!: User;
    acceptor!: User;

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'description',
    }
}
