<template>
    <div>
        <div class="card customer-projects">
            <FurloughNavigation />
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <template v-if="$gate.allows('accept', 'furlough')">
                            <div class="form-group">
                                <label class="form-label" for="user_id">Gebruiker</label>
                                <UserSelect
                                    id="user_id"
                                    :value="form.user_id"
                                    @answer="user => form.user_id = user.id"
                                    v-validate:[form.errors]="'user_id'"
                                ></UserSelect>
                                <error field="user_id" :bag="form.errors"></error>
                            </div>

                            <div class="form-group">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch" id="balance" v-model="form.balance"> <label for="balance">Balans mutatie</label>
                                </div>
                            </div>

                            <div class="row mt-0" v-if="form.balance">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="minutes">Minuten</label>
                                        <input type="number" class="form-control" id="minutes" v-model="form.minutes" v-validate:[form.errors]="'minutes'">
                                        <error field="minutes" :bag="form.errors"></error>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>&nbsp;</label>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" id="add" v-model="form.add"> <label for="balance">Toevoegen</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div class="row">
                            <div class="form-group">
                                <label for="description">Omschrijving</label>
                                <textarea class="form-control" id="description" v-model="form.description" v-validate:[form.errors]="'description'"></textarea>
                                <error field="description" :bag="form.errors"></error>
                            </div>

                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="start">Start</label>
                                    <VueCtkDateTimePicker
                                        id="start"
                                        label="Startdatum"
                                        v-model="form.start"
                                        minute-interval="30"
                                        :disabled-hours="this.disabledTimes"
                                        format="YYYY-MM-DD HH:mm"
                                        locale="nl"
                                        :no-button-now="true"
                                    />
                                    <error field="start" :bag="form.errors"></error>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="end">Einde</label>
                                    <VueCtkDateTimePicker
                                        id="end"
                                        label="Einddatum"
                                        v-model="form.end"
                                        minute-interval="30"
                                        :disabled-hours="this.disabledTimes"
                                        format="YYYY-MM-DD HH:mm"
                                        locale="nl"
                                        :no-button-now="true"
                                    />
                                    <error field="end" :bag="form.errors"></error>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex mb-3">
                            <button class="btn btn-success ms-auto" @click="request" :disabled="form.working">
                                Aanvragen
                                <icon icon="paper-plane" class="ms-2"></icon>
                            </button>
                        </div>
                    </div>
                    <div v-if="form.start !== null && form.end !== null" class="col-12 col-md-6">
                        <table class="table">
                            <thead>
                            <tr>
                                <th>Dag</th>
                                <th>Tijd (uur)</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(time, date) of times" :key="date+time">
                                <td>{{ $moment(date).format('DD-MM-YYYY') }}</td>
                                <td>{{ time }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import FurloughNavigation from "../../components/FurloughNavigation.vue";
import Furlough from "../../store/Models/Furlough";
import UserSelect from "../../components/UserSelect.vue";
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

export default defineComponent({
    components: {UserSelect, FurloughNavigation, VueCtkDateTimePicker},
    data() {
        return {
            form: {
                user_id: null,
                description: null,
                start: null,
                end: null,

                balance: false,
                minutes: null,
                add: true,

                working: false,
                errors: null,
            },
            times: [],
            disabledTimes: ['00','01','02','03','04','05','06','07','19','20','21','22','23'],
        }
    },
    watch: {
        form: {
            handler(form) {
                if (form.start !== null && form.end !== null) {
                    window.api.get('furloughs/calculate?start='+form.start+'&end='+form.end)
                        .then(response => {
                            this.times = response.data;
                        });
                }

                if (form.balance) {
                    // set end date to start date
                    this.form.end = this.form.start;
                }
            },
            deep: true
        }
    },
    methods: {
        request() {
            this.form.working = true;
            this.form.errors = null;

            Furlough.api().post('furloughs', this.form)
                .then(() => {
                    this.$toast('success', 'Je verlof is succesvol opgeslagen.');

                    this.form = {
                        user_id: null,
                        description: null,
                        start: null,
                        end: null,

                        balance: false,
                        minutes: null,
                        add: true,

                        working: false,
                        errors: null,
                    }
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.form.errors = e.response.data.errors;
                    }
                })
                .finally(() => {
                    this.form.working = false;
                });
        }
    }
});
</script>

<style scoped>

</style>
