<template>
    <div class="tab-pane fade show active">
        <h4 class="mb-3">File uploads</h4>
        <div class="offer-details card">
            <UseDropzoneDemo :project="project.id"></UseDropzoneDemo>
        </div>
    </div>
    <table class="table table-hover" style="max-width: 800px;">
        <thead>
        <tr>
            <th>Bestandsnaam</th>
            <th>Preview</th>
            <th style="width: 85px"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(file, index) of currentProject.files" :key="index">
            <td>{{ file.name }}</td>
            <td><img :src="file.path" height="30" :alt="file.name"></td>
            <td class="text-nowrap" style="text-align: right">
                <button class="btn btn-sm btn-danger" type="button" @click="deleteFile(file.id, index)"><icon icon="trash"></icon></button>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script lang="ts" setup>
import Project from "../../store/Models/Project";
import {computed} from "vue";
import UseDropzoneDemo from "../../components/Project/DropZone.vue";
import ProjectFile from "../../store/Models/ProjectFile";
import {toast} from "../../plugins/toast/toast";

const props = defineProps<{
    project: Project,
}>();

const currentProject = computed<Project>(() => {
    return Project.query().with('files').find(props.project.id)});

const fetchProject = () => {
    Project.api().get('projects/' + props.project.id, {
        params: {
            'include': 'files',
        }
    });
}

const deleteFile = (id: number, index: number) => {
    ProjectFile.api().delete('/files/' + id, {
        delete: id,
    })
        .then(() => {
            currentProject.value.files.splice(index, 1);

            toast('success', 'Het bestand is succesvol verwijderd.');
        });
}

fetchProject();
</script>

<style scoped>
</style>
