<template>
    <div class="card" :class="{'border-danger': showForcedMessage}">
        <div class="card-header">
            <strong>Two Factor Authenticatie</strong>
            <template v-if="showForcedMessage">
                <br />
                <span class="text-danger">Het is verplicht om `two factor authentication` in te schakelen.</span>
            </template>
        </div>
        <div class="card-body">
            <template v-if="me.two_factor_confirmed_at === null">
                <p>Je hebt nog geen two factor authenticatie aan staan. Dit is een extra beveiliging voor je account. Je kunt deze aan zetten door op de knop hieronder te klikken.</p>
                <button class="btn btn-primary" @click="enableTwoFactorAuthentication(true)">2FA aanzetten</button>

                <template v-if="qrCodeSvg !== null">
                    <hr />
                    <div v-html="qrCodeSvg" class="mt-3"></div>

                    <form class="mt-3" id="confirm-2fa-form" @submit.prevent="confirmTwoFactorAuthentication()">
                        <ol>
                            <li>Scan de QR code met je authenticator app.</li>
                            <li>Je kunt nu als het goed is een code in de authenticator app vinden voor de BE-Planner.</li>
                            <li>Vul die code in het onderstaande veld in.</li>
                        </ol>
                        <label for="confirm-2fa-code">2FA Code</label>
                        <input class="form-control" id="confirm-2fa-code" type="text" v-model="confirmTwoFactorForm.code" v-validate:[confirmTwoFactorForm.errors]="'code'">
                        <error field="code" :bag="confirmTwoFactorForm.errors"></error>

                        <button class="btn btn-primary mt-3" type="submit" form="confirm-2fa-form">Verder</button>
                    </form>
                </template>
            </template>
            <template v-else-if="recoveryCodes === null">
                <p>Je hebt two factor authenticatie aan staan. Je kunt je recovery codes hieronder bekijken. Zorg ervoor dat je deze codes altijd goed bewaard!</p>
                <button class="btn btn-primary" @click="showRecoveryCodes()">Toon recovery codes</button>
            </template>

            <PasswordConfirmation v-if="confirmingPassword" @confirmed="enableTwoFactorAuthentication(false)"></PasswordConfirmation>

            <template v-if="recoveryCodes !== null">
                <ul>
                    <li v-for="(code, index) of recoveryCodes" :key="index">{{ code }}</li>
                </ul>

                <button class="btn btn-primary me-3" @click="regenerateRecoveryCodes()">Genereer Recovery Codes Opnieuw</button>
                <button class="btn btn-primary" @click="recoveryCodes = null">Verberg Recovery codes</button>
            </template>
        </div>
    </div>
</template>

<script lang="ts" setup>
import User from "../../store/Models/User";
import {computed, reactive, ref} from "vue";
import PasswordConfirmation from "./PasswordConfirmation.vue";
import {useRoute, useRouter} from "vue-router";

const route = useRoute();
const router = useRouter();

const me = computed(() => {
    return User.find(window.me.id);
});

const showForcedMessage = computed(() => {
    return route.query.hasOwnProperty('force_two_factor') && route.query.force_two_factor;
});

const confirmingPassword = ref<boolean>(false);
const qrCodeSvg = ref<string | null>(null);
const recoveryCodes = ref<string[] | null>(null);

const confirmTwoFactorForm = reactive({
    code: null,

    errors: null,
    working: false,
});

const enableTwoFactorAuthentication = (confirmPassword: boolean = true) => {
    if (confirmPassword) {
        confirmingPassword.value = true;
        return;
    }

    confirmingPassword.value = false;

    window.axios.post('/user/two-factor-authentication')
        .then(() => {
            window.axios.get('/user/two-factor-qr-code')
                .then(response => {
                    qrCodeSvg.value = response.data.svg;
                });
        });
};

const confirmTwoFactorAuthentication = () => {
    confirmTwoFactorForm.errors = null;
    confirmTwoFactorForm.working = true;

    window.axios.post('/user/confirmed-two-factor-authentication', confirmTwoFactorForm)
        .then(() => {
            window.resetMe().then(() => {
                showRecoveryCodes();

                router.replace({
                    query: null,
                });
            });
        })
        .catch(e => {
            if (e.response.status === 422) {
                confirmTwoFactorForm.errors = e.response.data.errors;
            }
        })
        .finally(() => {
            confirmTwoFactorForm.working = false;
        });
}

const showRecoveryCodes = () => {
    window.axios.get('/user/two-factor-recovery-codes')
        .then(response => {
            recoveryCodes.value = response.data;
        })
        .catch(e => {
            if (e.response.status === 423) {
                confirmingPassword.value = true;
            }
        });
}

const regenerateRecoveryCodes = () => {
    window.axios.post('/user/two-factor-recovery-codes')
        .then(() => {
            showRecoveryCodes();
        });
}

User.api().get('users/' + window.me.id);
</script>

<style scoped>

</style>
