<template>
    <Pie :options="options" :data="data" />
</template>

<script lang="ts" setup>
import { Pie } from 'vue-chartjs';
import {Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale, ArcElement, ChartOptions} from 'chart.js'

import {computed} from "vue";

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale, ArcElement)

const props = defineProps<{
    data: {
        labels: string[],
        datasets: {
            data: number[]
        }[]
    },
    options?: ChartOptions,
}>();

const options = computed(() => {
    const propOptions = props.options ?? {};

    return {
        ...{
            responsive: true,
            plugins: {
                tooltip: {
                    callbacks: {
                        label(context: any) {
                            return window.timeFormatHoursMinutes(context.parsed);
                        }
                    }
                }
            }
        },
        ...propOptions,
    }
});
</script>

<style scoped>

</style>
