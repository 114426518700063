<template>
    <div>
        <div class="card customer-projects">
            <FurloughNavigation />
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Omschrijving</th>
                            <th>Start</th>
                            <th>Einde</th>
                            <th>Tijd</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="request of requests" :key="request.id">
                        <td>
                            {{ request.description }}
                        </td>
                        <td>
                            {{ $moment(request.start).format('DD-MM-Y HH:mm') }}
                        </td>
                        <td>
                            {{ $moment(request.end).format('DD-MM-Y HH:mm') }}
                        </td>
                        <td>
                            {{ $timeFormatHoursMinutes(request.minutes) }}
                        </td>
                        <td>
                            <StatusLabel :status="request.status"></StatusLabel>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FurloughNavigation from "../../components/FurloughNavigation.vue";
import User from "../../store/Models/User";
import Furlough from "../../store/Models/Furlough";
import StatusLabel from "../../components/StatusLabel.vue";

export default defineComponent({
    components: {StatusLabel, FurloughNavigation},
    computed: {
        users(): Array<User> {
            return User.query()
                .get();
        },
        requests(): Array<Furlough> {
            return Furlough.query()
                .where('user_id', this.$me().id)
                .orderBy('start', 'desc')
                .get();
        }
    },
    methods: {
        fetchData() {
            Furlough.deleteAll();

            User.api().get('users');
            Furlough.api().get('furloughs', {
                params: {
                    'filter[user_id]': this.$me().id,
                }
            });
        },
    },
    created() {
        this.fetchData();
    }
});
</script>

<style scoped>

</style>
