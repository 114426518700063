// @ts-ignore
import Gate from './Gate.js';
import User from "../../store/Models/User";

export default {
    install: (app: any) => {
        window.isGateInitialized = false;

        Gate.prototype.after = (user: User) => user && user.hasPermissionTo('admin');

        app.config.globalProperties.$initializeGate = window.initializeGate = (user): void => {
            if (window.isGateInitialized) {
                return;
            }

            User.insertOrUpdate({
                data: user
            }).then(() => {
                const windowUser = User.find(user.id);

                if (windowUser === null) {
                    return;
                }

                windowUser.setPermissions()
                    .then(() => {
                        const gateUser = window.me = User.find(user.id);

                        gateUser?.setPermissions();
                        gateUser?.setRoles();

                        app.config.globalProperties.$gate = window.gate = new Gate(gateUser);
                        window.isGateInitialized = true;
                        window.rerenderVue();
                    });
            });
        }

        // window.reloadPermissions = (router = null) => {
        //     if (window.user !== undefined) {
        //         window.user.setPermissions()
        //             .then(() => {
        //                 let gateUser = window.user = User.find(user.id);
        //                 app.config.globalProperties.$gate = window.gate = new Gate(gateUser);
        //
        //                 window.rerenderVue();
        //             });
        //     }
        // };

        app.config.globalProperties.$gate = new Gate;
    }
}
