import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";

export default class Checkin extends Model {
    static entity = 'checkins';

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            checkout: this.string('').nullable(),
        }
    }

    id!: number;
    user_id!: number;
    checkout!: string;

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: null,
    }
}
