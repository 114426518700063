<template>
    <div>
        <template v-if="project !== null">
            <div class="breadcrumbs d-flex">
                <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                    <ol class="breadcrumb">
                        <router-link class="breadcrumb-item" :to="{name: 'projects.index'}">Projecten</router-link>
                        <li class="breadcrumb-item active" aria-current="page">{{ project?.name }}</li>
                    </ol>
                </nav>
            </div>

            <div class="card">
                <div class="card-header">
                    <ul class="nav nav-tabs card-header-tabs ms-n1">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link active fw-bold" aria-selected="true">Details</a>
                        </li>
                        <template v-if="$gate.allows('update', project)">
                            <li class="nav-item" role="presentation">
                                <router-link :to="{name: 'projects.edit', params: {project: project.id}}" class="nav-link">
                                    Bewerken <icon class="ms-1" icon="pen-to-square"></icon>
                                </router-link>
                            </li>
                        </template>
                        <template v-if="$gate.allows('viewAny', 'budget')">
                            <li class="nav-item" role="presentation">
                                <router-link :to="{name: 'projects.budgets', params: {project: project.id}}" class="nav-link">
                                    Budgetbeheer <icon class="ms-1" icon="file-signature"></icon>
                                </router-link>
                            </li>
                        </template>
                        <div id="buttons" class="ms-auto text-md-right align-self-center me-2">
                            <router-link v-if="$gate.allows('create', 'project')" :to="{name: 'projects.create', query: {customer: project.customer_id}}" class="btn btn-sm btn-secondary">
                                Nieuw project <icon class="ms-1" icon="plus"></icon>
                            </router-link>
                            <router-link v-if="$gate.allows('create', 'invoice')" :to="{name: 'invoices.create'}" class="btn btn-sm btn-success">
                                Nieuwe factuur <icon class="ms-1" icon="plus"></icon>
                            </router-link>
                        </div>
                    </ul>

                </div>
                <div class="card-body">
                    <div class="tab-content">
                        <div class="tab-pane fade show active">
                            <ProjectInformation :project_id="project.id"></ProjectInformation>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Project from "../../store/Models/Project";
import {Item} from "@vuex-orm/core";
import ProjectInformation from "../../components/Project/ProjectInformation.vue";

export default defineComponent({
    components: {ProjectInformation},
    computed: {
        project(): Item<Project> {
            return Project.find(
                parseInt((this.$route.params.project as string))
            );
        },
    },
    created() {
        Project.api().get('projects/' + this.$route.params.project);
    }
});
</script>

<style scoped>

</style>
