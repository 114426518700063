<template>
    <div>
        <div class="breadcrumbs d-flex align-items-center">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">Kanban Bord</li>
                </ol>
            </nav>

            <div class="ms-auto">
                <UserSelect style="min-width: 250px" :selected="userId ?? me.id" @answer="setViewingUser" :canClear="false"></UserSelect>
            </div>
        </div>

        <div v-if="loading" class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
        </div>

        <template v-else-if="scrumData !== null">
            <div class="card">
                <div class="card-body">
                    <div class="scrumboard">
                        <div class="row">
                            <div class="col-md-2-5 px-2">
                                <template v-for="status of scrumData.statuses" :key="status">
                                    <template v-if="['new', 'on_hold'].includes(status)">
                                        <h5>{{ statusMap[status] ?? $ucFirst(status) }}</h5>

                                        <div class="sortable-scrumboard pt-3 mt-3" style="border-top: 2px solid #ff0000;">
                                            <draggable :list="scrumData.deadlines[status]" draggable=".handle" group="deadlines" @change="saveData()" item-key="id" :animation="200">
                                                <template #item="{element: deadline}">
                                                    <KanbanColumn :deadline="deadline" @openDialog="openDialog"></KanbanColumn>
                                                </template>
                                            </draggable>
                                        </div>
                                        <p>&nbsp;</p>
                                    </template>
                                </template>
                            </div>
                            <div class="col-md-2-5 px-2" v-for="status of scrumData.statuses.filter(s => !['new', 'on_hold'].includes(s))" :key="status">
                                <h5>{{ statusMap[status] ?? $ucFirst(status) }}</h5>

                                <div class="sortable-scrumboard pt-3 mt-3" style="border-top: 2px solid #ff0000;">
                                    <draggable :list="scrumData.deadlines[status]" draggable=".handle" group="deadlines" @change="saveData()" item-key="id" :animation="200">
                                        <template #item="{element: deadline}">
                                            <KanbanColumn :deadline="deadline" @openDialog="openDialog"></KanbanColumn>
                                        </template>
                                    </draggable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <KanbanDetailModal v-if="dialogDeadline !== null" :deadline="dialogDeadline" @hide="dialogDeadline = null"></KanbanDetailModal>
    </div>
</template>

<script lang="ts" setup>
import UserSelect from "../../components/UserSelect.vue";
import User from "../../store/Models/User";
import draggable from "vuedraggable";
import KanbanColumn from "../../components/KanbanColumn.vue";
import {ref} from "vue";
import Deadline from "../../store/Models/Deadline";
import KanbanDetailModal from "../../components/Dialogs/KanbanDetailModal.vue";
import {toNumber} from "lodash";

const me = window.me;
const loading = ref<boolean>(false);
const scrumData = ref<any>(null);
const dialogDeadline = ref<Deadline | null>(null);
const viewingUser = ref<User | null>(null);
const userId = ref<number | null>(window.location.search.split('user=')[1] ? toNumber(window.location.search.split('user=')[1]) : null);

const statusMap = {
    'new': 'Nieuw',
    'active': 'Actief',
    'on_hold': 'On Hold',
    'test_internal': 'Test intern',
    'test_external': 'Test extern',
    'finished': 'Klaar',
}

const setViewingUser = (user: User) => {
    viewingUser.value = user;
    fetchForUser();
}

const getUserFromParams = () => {
    window.api.get('users/' + userId.value).
    then(response => {
        viewingUser.value = response.data.data;
        fetchForUser();
    });
}

const fetchForUser = () => {
    loading.value = true;

    if (viewingUser.value === null) {
        if (userId.value === null) {
            viewingUser.value = window.me as User;
        }
    }

    window.api.get('users/' + viewingUser.value.id + '/scrumboard')
        .then(response => {
            scrumData.value = response.data;
        })
        .finally(() => {
            loading.value = false;
        })
}

const openDialog = (deadline: Deadline) => {
    dialogDeadline.value = deadline;
}

const saveData = () => {
    if (viewingUser.value === null) {
        viewingUser.value = window.me as User;
    }

    const deadlines = [] as Array<any>;

    for (const status in scrumData.value.deadlines) {
        for (const deadline of scrumData.value.deadlines[status]) {
            deadlines.push({
                id: deadline.id,
                scrum_status: status,
            });
        }
    }

    window.api.put('users/' + viewingUser.value.id + '/scrumboard', {
        deadlines: deadlines,
    })
        .then(response => {
            scrumData.value = response.data;
        });
}

if (userId.value !== null) {
    getUserFromParams();
} else {
    fetchForUser();
}
</script>

<style scoped>

</style>
