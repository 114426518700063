<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <router-link class="breadcrumb-item" :to="{name: 'management.teams.index'}">Teams</router-link>
                    <li class="breadcrumb-item active" aria-current="page">Nieuw team</li>
                </ol>
            </nav>

            <div id="buttons" class="ms-auto text-md-right align-self-center">
                <router-link :to="{name: 'management.teams.index'}" class="btn btn-sm btn-default" :class="{disabled: form.working}" :disabled="form.working">
                    Terug
                    <icon class="ms-1" icon="times"></icon>
                </router-link>
                <button v-if="$gate.allows('create', 'team')" class="btn btn-sm btn-success" type="submit" form="create-form" :disabled="form.working">
                    Opslaan
                    <icon class="ms-1" icon="floppy-disk"></icon>
                </button>
            </div>
        </div>

        <form @submit.prevent="save" id="create-form">
            <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label class="form-label">Naam</label>
                        <input class="form-control" type="text" v-model="form.name" v-validate:[form.errors]="'name'" />
                        <error field="name" :bag="form.errors"></error>
                    </div>
                </div>
            </div>
        </form>

    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Team from "../../../store/Models/Team";

export default defineComponent({
    data() {
        return {
            form: {
                name: null,

                errors: null,
                working: false,
            },
        }
    },
    methods: {
        save() {
            this.form.working = true;
            this.form.errors = null;

            Team.api().post('teams', this.form)
            .then(() => {
                this.$toast('success', 'Je hebt succesvol een nieuw team gemaakt.');

                this.$router.push({
                    name: 'management.teams.index',
                });
            })
            .catch(e => {
                if (e.response.status === 422) {
                    this.form.errors = e.response.data.errors;
                }
            })
            .finally(() => {
                this.form.working = false;
            });
        },
    },
});
</script>

<style scoped>

</style>
