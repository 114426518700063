<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <router-link class="breadcrumb-item" :to="{name: 'projects.index'}">Projecten</router-link>
                    <li class="breadcrumb-item active" aria-current="page">Nieuw project</li>
                </ol>
            </nav>

            <div id="buttons" class="ms-auto text-md-right align-self-center">
                <router-link :to="{name: 'projects.index'}" class="btn btn-sm btn-default" :class="{disabled: form.working}" :disabled="form.working">
                    Terug
                    <icon class="ms-1" icon="times"></icon>
                </router-link>
                <button v-if="$gate.allows('create', 'project')" class="btn btn-sm btn-success" type="submit" form="create-form" :disabled="form.working">
                    Opslaan
                    <icon class="ms-1" icon="floppy-disk"></icon>
                </button>
            </div>
        </div>

        <form @submit.prevent="save" id="create-form">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <h4>Algemeen</h4>
                            <div class="form-group">
                                <label class="form-label">Titel</label>
                                <input class="form-control" type="text" v-model="form.name" v-validate:[form.errors]="'name'" />
                                <error field="name" :bag="form.errors"></error>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Omschrijving</label>
                                <textarea class="form-control" v-model="form.description" v-validate:[form.errors]="'description'"></textarea>
                                <error field="description" :bag="form.errors"></error>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Afspraak klant notities</label>
                                <textarea class="form-control" v-model="form.appointment_notes" v-validate:[form.errors]="'appointment_notes'"></textarea>
                                <error field="appointment_notes" :bag="form.errors"></error>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Documentatie URL</label>
                                <input class="form-control" type="text" v-model="form.document_url" v-validate:[form.errors]="'document_url'" />
                                <error field="document_url" :bag="form.errors"></error>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Ticket URL</label>
                                <input class="form-control" type="text" v-model="form.ticket_url" v-validate:[form.errors]="'ticket_url'" />
                                <error field="ticket_url" :bag="form.errors"></error>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <h4>Instellingen</h4>

                            <div class="form-group" v-if="loaded">
                                <label class="form-label">Klant</label>
                                <CustomerSelect :selected="customer" @answer="setCustomer" v-validate:[form.errors]="'customer_id'"></CustomerSelect>
                                <error field="customer_id" :bag="form.errors"></error>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Subklant</label>
                                <CustomerSelect :value="form.sub_customer_id" @answer="setSubCustomer" v-validate:[form.errors]="'sub_customer_id'"></CustomerSelect>
                                <error field="sub_customer_id" :bag="form.errors"></error>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Project manager</label>
                                <UserSelect :selected="form.project_manager_id" @answer="setProjectManager" v-validate:[form.errors]="'project_manager_id'"></UserSelect>
                                <error field="project_manager_id" :bag="form.errors"></error>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Account manager</label>
                                <UserSelect :selected="form.account_manager_id" @answer="setAccountManager" v-validate:[form.errors]="'account_manager_id'"></UserSelect>
                                <error field="account_manager_id" :bag="form.errors"></error>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Status</label>
                                <select class="form-select" v-model="form.status" v-validate:[form.errors]="'status'">
                                    <option value="active">Actief</option>
                                    <option value="done">Afgerond</option>
                                    <option value="invoiced">Gefactureerd</option>
                                    <option value="on_hold">On hold</option>
                                    <option value="inactive">Inactief</option>
                                    <option value="archived">Gearchiveerd</option>
                                </select>
                                <error field="status" :bag="form.errors"></error>
                            </div>

                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="is_billable" v-model="form.is_billable" v-validate:[form.errors]="'is_billable'">
                                <label class="form-check-label" for="is_billable">Facturabel</label>
                            </div>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="is_continuous" v-model="form.is_continuous" v-validate:[form.errors]="'is_continuous'">
                                <label class="form-check-label" for="is_continuous">Doorlopend</label>
                            </div>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="is_active" v-model="form.is_active" v-validate:[form.errors]="'is_active'">
                                <label class="form-check-label" for="is_active">Actief</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import CustomerSelect from "../../components/CustomerSelect.vue";
import UserSelect from "../../components/UserSelect.vue";
import Project from "../../store/Models/Project";
import Customer from "../../store/Models/Customer";
import User from "../../store/Models/User";
import {isNil} from "lodash";
import {Item} from "@vuex-orm/core";

export default defineComponent({
    components: {UserSelect, CustomerSelect},
    data() {
        return {
            form: {
                name: null,
                description: null,
                appointment_notes: null,
                customer_id: null,
                sub_customer_id: null,
                project_manager_id: null,
                account_manager_id: null,
                is_billable: true,
                is_continuous: false,
                is_active: true,
                status: 'active',
                document_url: null,
                ticket_url: null,
                website_url: null,
                repo_url: null,

                working: false,
                errors: null,
            } as any,
            loaded: false,
            customer: null,
        }
    },
    watch: {
        // trim on change
        'form.name'(val: string) {
            this.form.name = val.trimStart();
        },
        // trim whitespace from te beginning
        'form.description'(val: string) {
            this.form.description = val.trimStart();
        },
        // trim whitespace from te beginning
        'form.appointment_notes'(val: string) {
            this.form.appointment_notes = val.trimStart();
        },
    },
    methods: {
        setCustomer(val: Customer) {
            this.form.customer_id = val?.id;
        },
        setSubCustomer(val: Customer) {
            this.form.sub_customer_id = val?.id;
        },
        setProjectManager(val: User) {
            this.form.project_manager_id = val?.id;
        },
        setAccountManager(val: User) {
            this.form.account_manager_id = val?.id;
        },
        save() {
            this.form.working = true;
            this.form.errors = null;

            Project.api().post('projects', this.form)
            .then(response => {
                this.$toast('success', 'Je hebt succesvol een project gemaakt!');

                this.$router.push({
                    name: 'projects.budgets',
                    params: {
                        project: response.response.data.data.id,
                    }
                });
            })
            .catch(e => {
                if (e.response.status === 422) {
                    this.form.errors = e.response.data.errors;
                }
            })
            .finally(() => {
                this.form.working = false;
            })
        }
    },
    created() {
        if (!isNil(this.$route.query.customer)) {
            this.form.customer_id = this.$route.query.customer;
            Customer.api().get('customers/' + this.form.customer_id).then((response) => {
                this.customer = response.response.data.data;
                this.loaded = true;
            });
        } else {
            this.loaded = true;
        }
    }
});
</script>

<style scoped>

</style>
