import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";
import InvoiceRow from "./InvoiceRow";

export default class Invoice extends Model {
    static entity = 'invoices';

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'description',
    }

    static fields() {
        return {
            id: this.attr(null),
            project_id: this.attr(null),
            user_id: this.attr(null),
            description: this.string(''),
            date: this.string(''),

            created_at: this.string(false),
            updated_at: this.string(false),

            rows: this.hasMany(InvoiceRow, 'invoice_id'),
        }
    }

    declare id: number;
    declare project_id: number;
    declare user_id: number;
    declare description: string;
    declare date: string;
    declare created_at: string;
    declare updated_at: string;
    declare rows: InvoiceRow[];
}
