<template>
    <input onfocus="this.select()" class="form-control" :value="value" @change="setModelValue($event)" />
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    props: {
        modelValue: {
            required: true,
        },
        additional: {
            required: false,
        },
        nullable: {
            required: false,
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            value: null as any,
        }
    },
    emits: ['answer'],
    watch: {
        modelValue: {
            handler(minutes: any) {
                if ((minutes === null || minutes === '' || minutes === undefined) && this.nullable) {
                    this.value = null;
                } else {
                    this.value = this.$timeFormatHoursMinutes(minutes);
                }
            },
            immediate: true,
        }
    },
    methods: {
        setModelValue(event: any) {
            const hoursMinutes = event.target.value;

            if (this.nullable && (hoursMinutes === null || hoursMinutes === '')) {
                this.$emit('answer', null);
                return;
            }

            const positionColon = hoursMinutes.indexOf(':');

            if (positionColon >= 0) {
                const hours = Number(hoursMinutes.substring(0, positionColon));
                const minutes = Number(hoursMinutes.substring(positionColon + 1));
                const total = minutes + (hours * 60);

                if (this.additional !== undefined) {
                    this.$emit('answer', this.additional, total);
                } else {
                    this.$emit('answer', total);
                }
            } else if (hoursMinutes <= 9) {
                const total = Number(hoursMinutes) * 60;

                if (this.additional !== undefined) {
                    this.$emit('answer', this.additional, total);
                } else {
                    this.$emit('answer', total);
                }
            } else {
                // @ts-ignore
                const total = parseInt(Math.round(parseInt(hoursMinutes) / 5) * 5);

                if (this.additional !== undefined) {
                    this.$emit('answer', this.additional, total);
                } else {
                    this.$emit('answer', total);
                }
            }
        }
    },
});
</script>

<style scoped>

</style>
