<template>
    <div>
        <div class="breadcrumbs d-flex">
            <nav aria-label="breadcrumb" class="flex-shrink-1 align-self-center">
                <ol class="breadcrumb">
                    <router-link class="breadcrumb-item" :to="{name: 'customers.index'}">Klanten</router-link>
                    <li class="breadcrumb-item active" aria-current="page">Nieuwe klant</li>
                </ol>
            </nav>

            <div id="buttons" class="ms-auto text-md-right align-self-center">
                <router-link :to="{name: 'customers.index'}" class="btn btn-sm btn-default" :class="{disabled: form.working}" :disabled="form.working">
                    Terug
                    <icon class="ms-1" icon="times"></icon>
                </router-link>
                <button v-if="$gate.allows('create', 'customer')" class="btn btn-sm btn-success" type="submit" form="create-form" :disabled="form.working">
                    Opslaan
                    <icon class="ms-1" icon="floppy-disk"></icon>
                </button>
            </div>
        </div>

        <form @submit.prevent="save" id="create-form">
            <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label class="form-label">Naam</label>
                        <input class="form-control" type="text" v-model="form.name" v-validate:[form.errors]="'name'" />
                        <error field="name" :bag="form.errors"></error>
                    </div>

                    <div class="form-group">
                        <label class="form-label">Code</label>
                        <input class="form-control" type="text" v-model="form.code" v-validate:[form.errors]="'code'" />
                        <error field="code" :bag="form.errors"></error>
                    </div>

                    <div class="form-group">
                        <label class="form-label">Contactpersoon</label>
                        <input class="form-control" type="text" v-model="form.contact" v-validate:[form.errors]="'contact'" />
                        <error field="contact" :bag="form.errors"></error>
                    </div>

                    <div class="form-group">
                        <label class="form-label">Email</label>
                        <input class="form-control" type="text" v-model="form.email" v-validate:[form.errors]="'email'" />
                        <error field="email" :bag="form.errors"></error>
                    </div>

                    <div class="form-group">
                        <label class="form-label">Telefoonnummer</label>
                        <input class="form-control" type="text" v-model="form.phone" v-validate:[form.errors]="'phone'" />
                        <error field="phone" :bag="form.errors"></error>
                    </div>

                    <div class="form-group">
                        <label class="form-label">Kleur</label>
                        <input class="form-control" type="color" style="max-width: 50px" v-model="form.color" v-validate:[form.errors]="'color'" />
                        <error field="color" :bag="form.errors"></error>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Customer from "../../store/Models/Customer";

export default defineComponent({
    data() {
        return {
            form: {
                name: null,
                code: null,
                contact: null,
                email: null,
                phone: null,
                color: null,

                working: false,
                errors: null,
            },
        }
    },
    methods: {
        save() {
            this.form.working = true;
            this.form.errors = null;

            Customer.api().post('customers', this.form)
                .then(response => {
                    this.$toast('success', 'Je hebt succesvol een nieuwe klant gemaakt.');

                    this.$router.push({
                        name: 'customers.show',
                        params: {
                            customer: response.response.data.data.id,
                        },
                    });
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.form.errors = e.response.data.errors;
                    }
                })
                .finally(() => {
                    this.form.working = false;
                });
        }
    }
});
</script>

<style scoped>

</style>
