<template>
    <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs" role="tablist">
            <li v-if="$gate.allows('create', 'furlough')" class="nav-item">
                <router-link :to="{name: 'furlough.create'}" class="nav-link cursor-pointer" data-toggle="tab" role="tab" :class="{active: $route.name === 'furlough.create'}">
                    Aanvragen
                </router-link>
            </li>
            <li v-if="$gate.allows('balance', 'furlough')" class="nav-item">
                <router-link :to="{name: 'furlough.balance'}" class="nav-link cursor-pointer" data-toggle="tab" role="tab" :class="{active: $route.name === 'furlough.balance'}">
                    Balans
                </router-link>
            </li>
            <li v-if="$gate.allows('accept', 'furlough')" class="nav-item">
                <router-link :to="{name: 'furlough.requests'}"
                             class="nav-link cursor-pointer"
                             data-toggle="tab"
                             role="tab"
                             :class="{active: $route.name === 'furlough.requests'}"
                >
                    Goed te keuren
                    <span v-if="requests.length > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
                        {{ requests.length }}
                        <span class="visually-hidden">Goed te keuren</span>
                  </span>
                </router-link>
            </li>
            <li v-if="$gate.allows('history', 'furlough')" class="nav-item">
                <router-link :to="{name: 'furlough.history'}" class="nav-link cursor-pointer" data-toggle="tab" role="tab" :class="{active: $route.name === 'furlough.history'}">
                    Geschiedenis
                </router-link>
            </li>
            <li v-if="$gate.allows('historyOverview', 'furlough')" class="nav-item">
                <router-link :to="{name: 'furlough.history_overview'}" class="nav-link cursor-pointer" data-toggle="tab" role="tab" :class="{active: $route.name === 'furlough.history_overview'}">
                    Totaal overzicht
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Furlough from "../store/Models/Furlough";

export default defineComponent({
    computed: {
        requests(): Array<Furlough> {
            return Furlough.query()
                .where('has_been_accepted', null)
                .get();
        }
    },
    created() {
        Furlough.api().get('furloughs', {
            params: {
                accepted: false,
            }
        });
    }
});
</script>

<style scoped>

</style>
