import Model from '../Model';
// @ts-ignore
import { Config } from "../../typings/vuex";
import moment from "moment";
import User from "./User";

export default class Absence extends Model {
    static entity = 'absences';

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            description: this.string(null).nullable(),
            start: this.string(null).nullable(),
            end: this.string(null).nullable(),

            created_at: this.string(false),
            updated_at: this.string(false),

            user: this.belongsTo(User, 'user_id'),
        }
    }

    id!: number;
    user_id!: number;
    description!: string;
    start!: string;
    end!: string;
    created_at!: string;
    updated_at!: string;

    user!: User;

    static mutators () {
        return {
            start (date: string) {
                return moment(date).format('Y-MM-DDTHH:mm')
            },
            end (date: string | null) {
                if (date === null) {
                    return null;
                }

                return moment(date).format('Y-MM-DDTHH:mm')
            }
        }
    }

    static apiConfig: Config = {
        dataKey: 'data',
        model: this.entity,
        name_prop: 'description',
    }
}
